import React, { useEffect, useState } from "react"
import {
	IMClient,
	Message,
	TextMessage,
	Event,
	Conversation,
	PresistentConversation,
} from "leancloud-realtime"
import AV from "leancloud-storage"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
import {
	Button,
	List,
	Avatar,
	Row,
	Col,
	Badge,
	Tag,
	Checkbox,
	Drawer,
} from "antd"
import { qiniuImageView } from "../../utils/helpers"
import { useChat, realtime } from "../../models/chat_client"
import globalSlice from "../../redux/slices/global"
import ChatWindow from "./ChatWindow"
import "./ChatList.less"
// @ts-expect-error
import popding from "../../assets/sound/pop_ding.mp3"
import moment from "moment"
import Modal from "antd/lib/modal/Modal"

const ChatList = () => {
	const dispatch = useDispatch()
	const [onlySeeMine, setOnlySeeMine] = useState(false)

	const [currentConversation, setCurrentConversation]: [
		PresistentConversation,
		any
	] = useState()

	const [chatClient, unreadMessagesCount, conversations, isLoading] = useChat()

	const agentName: string = useSelector((state) =>
		_.get(state, "global.user.username")
	)

	return (
		<>
			<div className="chatlist-container">
				<div className="chatlist-frame">
					<div className="tools">
						<Checkbox
							checked={onlySeeMine}
							onChange={(e) => setOnlySeeMine(e.target.checked)}
						>
							只看我的
						</Checkbox>
					</div>
					<List
						loading={isLoading}
						className="chatlist"
						dataSource={conversations}
						renderItem={(item: Conversation, idx) => {
							const attributes = item.get("attr")
							const lastMessage = item.lastMessage

							const className = attributes["className"]
							var tagColor
							var purpose
							switch (className) {
								case "AuthOrders":
									tagColor = "pink"
									purpose = "鉴定单"
									break
								case "ShopItem":
									tagColor = "blue"
									purpose = "商品"
									break
								case "ShopOrder":
									tagColor = "orange"
									purpose = "购物单"
									break
								case "ShopSellItem":
									tagColor = "red"
									purpose = "回收"
									break
								default:
									purpose = "普通"
									break
							}

							let lastMessageText = ""
							if (lastMessage instanceof TextMessage) {
								lastMessageText = lastMessage.text
							} else if (!!_.get(lastMessage, "content._lcfile.url")) {
								const url = _.get(lastMessage, "content._lcfile.url")
								lastMessageText = "[图片]"
							}

							const creator = item.get("user")

							let creatorAvatarUrl =
								"https://file.ilux.ai/y6GEj2sr8GWMuXRqrsV5xdnQq4KTD2vp/default-avatar.png"
							try {
								creatorAvatarUrl = creator.get("avatar").url()
							} catch (err) {}

							let creatorNickName = ""
							if (
								item.members.filter((m) => !m.startsWith("service_")).length <=
								0
							) {
								creatorNickName = "[用户已离开对话]"
							} else if (item.creator.startsWith("guest_")) {
								creatorNickName = "未登录客户"
							} else {
								try {
									creatorNickName = creator.get("nickName") || "无名用户"
								} catch (err) {}
							}

							return (
								<List.Item
									className={
										_.get(currentConversation, "id") == item.id
											? "active list-item"
											: "list-item"
									}
									onClick={(v) => {
										let currentConv = conversations[idx]
										setCurrentConversation(currentConv)
										item.read()
									}}
								>
									<Badge count={item.unreadMessagesCount}>
										{/* <Avatar src={qiniuImageView(attributes.imageUrl, 200)} /> */}
										<Avatar
											style={{ marginRight: 8 }}
											src={qiniuImageView(creatorAvatarUrl, 200)}
											size="large"
										/>
									</Badge>
									<div>
										<div className="nickName">{creatorNickName}</div>
										<div>
											<div className="chat-title">
												[{purpose}] {attributes.title}
											</div>
											<div>{lastMessageText}</div>
											<div className="float-div">
												{!item.get("attr.agent") ? (
													<Tag color="red">待接</Tag>
												) : item.get("attr.agent") == agentName ? (
													<Tag color="volcano">我的客户</Tag>
												) : (
													""
												)}
											</div>
											<div className="last-msg-at">
												{moment(item.lastMessageAt).fromNow()}
											</div>
										</div>
									</div>
								</List.Item>
							)
						}}
					></List>
				</div>
			</div>
			<Modal
				className="chat-window-modal"
				centered
				visible={!!currentConversation}
				title="客服对话"
				onCancel={() => {
					setCurrentConversation()
				}}
				footer={false}
			>
				<ChatWindow
					conversation={currentConversation}
					refreshConversation={(conv) => {
						setCurrentConversation(null)
						setCurrentConversation(conv)
					}}
				/>
			</Modal>
		</>
	)
}

export default ChatList
