import React, { useEffect, useState } from "react"
import "./Orders.less"
import { navbar, qiniuImageView, DATE_RANGES } from "../../utils/helpers"
import {
	Button,
	Table,
	Avatar,
	Form,
	Input,
	Calendar,
	DatePicker,
	Tooltip,
	Checkbox,
	Radio,
} from "antd"
import IconFont from "../../components/IconFont/IconFont"
import API from "../../api/api"
import _ from "lodash"
import moment, { Moment } from "moment"
import { isMobile } from "react-device-detect"
import { Link, useHistory } from "react-router-dom"
import { ColumnsType } from "antd/lib/table"

const orderColumnsMobile: ColumnsType = [
	{
		key: "cover",
		title: "封面",
		width: 48,
		render: (r, i) => {
			return (
				<span>
					<img
						src={qiniuImageView(_.get(r, "shopItem.cover_url"), 100)}
						className="mobile cover"
					/>
				</span>
			)
		},
	},
	{
		key: "shopItem",
		title: "订单",
		render: (r, i) => {
			return (
				<span>
					<div className="mobile order-id">
						#{_.get(r, "id")}{" "}
						<span className="status">[{_.get(r, "status.name")}]</span>
					</div>
					<div className="mobile shop-item">
						{/* <div className="id">商品ID: {_.get(r, "shopItem.id")}</div> */}
						<div className="brand">
							{_.get(r, "shopItem.brand.name")}
							{_.get(r, "shopItem.category.name")}
						</div>
						<div>{_.get(r, "shopItem.name")}</div>
					</div>
				</span>
			)
		},
	},
	{
		key: "buyer",
		title: "买家",
		align: "left",
		render: (r, i) => {
			return (
				<span>
					<div>
						<Avatar
							size="small"
							src={qiniuImageView(_.get(r, "buyer.avatar"), 100)}
							style={{ marginRight: 4 }}
						/>
						{_.get(r, "buyer.nickName")}
					</div>
					<div>
						下单: {moment.unix(_.get(r, "createdAt")).format("M/D H:mm")}
					</div>
					<div>
						更新: {moment.unix(_.get(r, "updatedAt")).format("M/D H:mm")}
					</div>
				</span>
			)
		},
	},
]

export const orderColumns: ColumnsType = [
	{
		key: "cover",
		title: "封面",
		width: 88,
		align: "center",
		render: (r, i) => {
			return (
				<div className="cover-wrapper">
					<Tooltip
						title={
							<a href={_.get(r, "shopItem.cover_url")} target="_blank">
								<img
									src={qiniuImageView(_.get(r, "shopItem.cover_url"), 400)}
									className="pc cover-large"
								/>
							</a>
						}
						placement="right"
					>
						<img
							src={qiniuImageView(_.get(r, "shopItem.cover_url"), 100)}
							className="pc cover"
						/>
					</Tooltip>
				</div>
			)
		},
	},
	{
		key: "order_id",
		title: "订单号",
		render: (r, i) => {
			return (
				<span>
					<div className="pc id">订单ID: {_.get(r, "id")}</div>
					<div className="pc id">OID: {_.get(r, "objectId")}</div>
				</span>
			)
		},
	},
	{
		key: "shopItem",
		title: "商品",
		render: (r, i) => {
			return (
				<span>
					<div className="pc id">商品ID: {_.get(r, "shopItem.id")}</div>
					<div className="pc brand">
						{_.get(r, "shopItem.brand.name")}
						{_.get(r, "shopItem.category.name")}
					</div>
					<div>{_.get(r, "shopItem.name")}</div>
				</span>
			)
		},
	},

	{
		key: "buyer",
		title: "买家",
		align: "left",
		render: (r, i) => {
			return (
				<span>
					<span className="user-wrapper">
						<Tooltip
							title={
								<a href={_.get(r, "buyer.avatar")} target="_blank">
									<img
										src={qiniuImageView(_.get(r, "buyer.avatar"), 200)}
										className="avatar-large"
									/>
								</a>
							}
							placement="left"
						>
							<Avatar
								src={qiniuImageView(_.get(r, "buyer.avatar"), 100)}
								style={{ marginRight: 4 }}
							/>
						</Tooltip>
					</span>
					<Link
						to={`/user/${_.get(r, "buyer.objectId", "")}`}
						onClick={(e) => e.stopPropagation()}
					>
						{_.get(r, "buyer.nickName")}
					</Link>
				</span>
			)
		},
	},
	{
		key: "date",
		title: "时间",
		render: (r, i) => {
			return (
				<span>
					<div>
						下单: {moment.unix(_.get(r, "createdAt")).format("M/D H:mm")}
					</div>
					<div>
						更新: {moment.unix(_.get(r, "updatedAt")).format("M/D H:mm")}
					</div>
				</span>
			)
		},
	},
	{
		key: "status",
		title: "状态",
		render: (r, i) => {
			return <span>
				<div>¥{_.get(r, "finalPrice")}</div>
				<div>{_.get(r, "status.name")}</div>
			</span>
		},
	},
]

const Orders = () => {
	const history = useHistory()
	const [loading, setLoading] = useState(false)
	const [dataSource, setDataSource] = useState()
	const [pageSize, setPageSize] = useState(100)
	const [currentPage, setCurrentPage] = useState(0)
	const [total, setTotal] = useState(0)
	const [dateRange, setDateRnage]: [[Moment, Moment], Function] = useState(
		DATE_RANGES.all
	)
	const [statusFilter, setStatusFilter]: [string, Function] = useState(
		"in_progress"
	)
	const [form] = Form.useForm()

	useEffect(() => {
		navbar.setTitle("闪拍订单")
		// navbar.setRightItem(
		// 	<Button
		// 		type="primary"
		// 		icon={<IconFont name="icon-add-select" />}
		// 		onClick={() => {}}
		// 	>
		// 		创建订单
		// 	</Button>
		// )
		return () => {
			navbar.reset()
		}
	}, [])

	useEffect(() => {
		fetchData()
	}, [currentPage, pageSize])

	useEffect(() => {
		fetchData()
	}, [dateRange, statusFilter])

	const fetchData = async () => {
		let reqFilters = {
			date_range: dateRange
				? dateRange.map((d: Moment) => {
						console.log(d)
						console.log("d.toDate()", d.toDate())
						return parseInt(d.format("X"))
				  })
				: null,
			status: statusFilter,
		}
		console.log("reqFilters", reqFilters)
		try {
			setLoading(true)
			const res = await API.Shop.Order.get_orders({
				page: currentPage,
				limit: pageSize,
				filters: reqFilters,
			})
			const { orders, count, page } = res
			setDataSource(orders)
			setTotal(count)
			setCurrentPage(page)
		} catch (err) {
			console.log("err", err)
		}
		setLoading(false)
	}

	const filter = (values) => {
		console.log("filter", values)
		if (_.get(values, "date_range")) {
			setDateRnage(_.get(values, "date_range"))
		}
		if (_.get(values, "status")) {
			setStatusFilter(_.get(values, "status"))
		}
	}

	return (
		<>
			<div className="tool-bar">
				<Form
					form={form}
					initialValues={{
						date_range: dateRange,
						status: statusFilter,
					}}
					onFinish={filter}
					onValuesChange={filter}
				>
					<Form.Item name="date_range" label="日期">
						<DatePicker.RangePicker
							allowClear
							ranges={{
								全部: DATE_RANGES.all,
								今天: DATE_RANGES.today,
								本月: DATE_RANGES.month,
								上个月: DATE_RANGES.lastMonth,
							}}
						/>
					</Form.Item>

					<Form.Item label="过滤" name="status">
						<Radio.Group>
							<Radio value="in_progress">进行中</Radio>
							<Radio value="pending_payment">待支付</Radio>
							<Radio value="cancelled">已取消</Radio>
							<Radio value="refund_received">已退款</Radio>
							<Radio value="received">已签收</Radio>
						</Radio.Group>
					</Form.Item>
				</Form>
			</div>
			<Table
				loading={loading}
				className={isMobile ? "mobile-table" : "pc-table"}
				size="small"
				dataSource={dataSource}
				pagination={{
					size: "default",
					pageSize: pageSize,
					current: currentPage + 1,
					total: total,
					onChange: (page, pageSize) => {
						setCurrentPage(page - 1)
						setPageSize(pageSize)
					},
				}}
				rowKey={(r) => _.get(r, "id")}
				columns={isMobile ? orderColumnsMobile : orderColumns}
				onRow={(order, index) => ({
					onClick: () => {
						history.push(`/shop/order/${_.get(order, "id")}`)
					},
				})}
			/>
		</>
	)
}

export default Orders
