import { Button, Empty, Pagination, Input } from "antd"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { isBrowser } from "react-device-detect"
import { useDispatch, useSelector } from "react-redux"
import IconFont from "../../components/IconFont/IconFont"
import ShopItemCard from "../../components/ShopItemCard/ShopItemCard"
import ShopItemEditor from "../../components/ShopItemEditor/ShopItemEditor"
import shopAction from "../../redux/slices/shop/actions"
import shopSlice from "../../redux/slices/shop/shop"
import "./ShopItems.less"
import globalSlice from "../../redux/slices/global"
import { navbar } from "../../utils/helpers"
import FilterTools, {
	ProductFilters,
} from "../../components/FilterTools/FilterTools"

interface ShopItemsProps {
	archived?: boolean
}

const ShopItems = ({ archived = false }: ShopItemsProps) => {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const [pageSize, setPageSize] = useState(24)
	const [editorVisible, setEditorVisible] = useState(false)
	const displayData = useSelector((state) =>
		_.get(state, "shop.shopItemsDisplayData")
	)
	const { currentPage, totalCount, pageItems } = displayData

	const [filters, setFilters]: [ProductFilters, Function] = useState()

	const setCurrentPage = (page: number) => {
		dispatch(shopSlice.actions.setCurrentPage(page))
	}

	const setEditorItem = (item) => {
		dispatch(shopSlice.actions.setEditorItem(item))
	}

	useEffect(() => {
		navbar.setTitle("商品仓库")
		if (!archived) {
			navbar.setRightItem(
				<Button
					type="primary"
					icon={<IconFont name="icon-add-select" />}
					onClick={() => {
						setEditorItem(null)
						setEditorVisible(true)
					}}
				>
					创建商品
				</Button>
			)
		}
		return () => {
			navbar.reset()
		}
	}, [])

	useEffect(() => {
		console.log("fetch")
		fetchData()
	}, [currentPage, pageSize, filters])

	const fetchData = async () => {
		try {
			setLoading(true)
			await shopAction.fetchShopItems({
				pageSize: pageSize,
				archived: archived,
				filters: filters,
			})
		} catch (err) {}
		setLoading(false)
	}

	const filter = (filters: ProductFilters) => {
		console.log("filterParams", filters)
		setFilters(filters)
	}

	return (
		<>
			<div className="tool-bar">
				<div className="left">
					<FilterTools onFilter={filter} />
				</div>
				<div className="right"></div>
			</div>

			{loading ? (
				<div className="item-grid">
					{new Array(pageSize).fill("").map((empty, i) => (
						<ShopItemCard key={i} index={i} isSkeleton={true} />
					))}
				</div>
			) : pageItems.length > 0 ? (
				<div className="item-grid">
					{pageItems.map((r, i) => (
						<ShopItemCard item={r} key={i} index={i} showActionBtns />
					))}
				</div>
			) : (
				<Empty className="empty" />
			)}

			<Pagination
				pageSize={pageSize}
				current={currentPage + 1}
				onChange={(page, pageSize) => {
					setCurrentPage(page - 1)
				}}
				total={totalCount}
				className="pagination"
				showQuickJumper={isBrowser}
				showSizeChanger={isBrowser}
				pageSizeOptions={["8", "24", "64", "128"]}
				onShowSizeChange={(current, size) => {
					let newCurrentPage = Math.floor((currentPage * pageSize) / size)
					setCurrentPage(newCurrentPage)
					setPageSize(size)
				}}
			/>

			<ShopItemEditor
				visible={editorVisible}
				onClose={() => {
					setEditorVisible(false)
					fetchData()
				}}
				onItemCreated={(item) => {
					setCurrentPage(0)
					setTimeout(() => {
						shopAction
							.fetchShopItems({ pageSize: pageSize })
							.then((reloadedItems) => {
								let newlyCreatedItem = reloadedItems.find(
									(i) => i.objectId == _.get(item, "objectId")
								)
								setEditorItem(newlyCreatedItem)
							})
					}, 1000)
				}}
			/>
		</>
	)
}

export default ShopItems
