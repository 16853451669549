import React, { useEffect, useState } from "react"
import "./BrandsManagement.less"
import { useSelector, useDispatch } from "react-redux"
import _ from "lodash"
import { Card, Button, Input } from "antd"
import AspectImage from "../../components/AspectImage/AspectImage"
import { qiniuImageView, navbar } from "../../utils/helpers"
import BrandEditor from "./BrandEditor"
import API from "../../api/api"
import globalSlice from "../../redux/slices/global"
import IconFont from "../../components/IconFont/IconFont"

const BrandsManagement = () => {
	const dispatch = useDispatch()
	const [editBrand, setEditBrand]: [object, Function] = useState(null)
	const [showBrandEditor, setShowBrandEditor] = useState(false)
	const [searchKeyword, setSearchKeyword]: [string, Function] = useState()
	const commonData = useSelector((state) => _.get(state, "global.commonData"))
	let brands: object[] = _.get(commonData, "brands") || []
	let sortedBrands = _.clone(brands)
		.sort((a, b) => {
			if (_.get(a, "name") > _.get(b, "name")) {
				return 1
			} else if (_.get(a, "name") < _.get(b, "name")) {
				return -1
			} else {
				return 0
			}
		})
		.filter((b) => {
            let kw = (searchKeyword || "").toLowerCase()
			return [
				_.get(b, "name", ""),
				_.get(b, "cnName", ""),
				_.get(b, "abv", ""),
				_.get(b, "alias", []).flat(),
			]
				.join("")
				.toLowerCase()
				.includes(kw)
		})

	useEffect(() => {
		navbar.setTitle("品牌库管理")
		navbar.setRightItem(
			<Button
				onClick={() => {
					setShowBrandEditor(true)
				}}
			>
				+ 新建
			</Button>
		)
		return () => {
			navbar.reset()
		}
	}, [])

	const closeEditor = () => {
		setShowBrandEditor(false)
		setEditBrand(null)
	}

	const refresh = () => {
		API.Admin.Common.get_common_data().then((res) =>
			dispatch(globalSlice.actions.setCommonData(res))
		)
	}

	return (
		<>
			<Input
				prefix={<IconFont name="search" />}
				placeholder="快速搜索"
				onChange={(e) => {
					setSearchKeyword(e.currentTarget.value)
                }}
                size="large"
                allowClear
                style={{marginBottom: "1rem"}}
			/>

			<div className="brand-grid">
				{sortedBrands.map((brand) => {
					return (
						<Card
							style={_.get(brand, "isHidden") ? { opacity: 0.5 } : {}}
							hoverable
							key={_.get(brand, "objectId")}
							title={
								<>
									{_.get(brand, "name")} {_.get(brand, "cnName")}
								</>
							}
							size="small"
							bodyStyle={{ padding: "2rem" }}
							onClick={() => {
								setEditBrand(brand)
							}}
						>
							<AspectImage
								src={qiniuImageView(_.get(brand, "logo.url"))}
								fillMode="contain"
								wrapperStyle={{ backgroundColor: "transparent" }}
							/>
						</Card>
					)
				})}
				{!!editBrand || !!showBrandEditor ? (
					<BrandEditor
						brand={editBrand}
						visible={showBrandEditor}
						onCancel={closeEditor}
						success={refresh}
					/>
				) : (
					""
				)}
			</div>
		</>
	)
}

export default BrandsManagement
