import {
	Button,
	Dropdown,
	Menu,
	message,
	Modal,
	Popconfirm,
	Skeleton,
	Tag,
	Upload,
} from "antd"
import { RcFile } from "antd/lib/upload"
import AV from "leancloud-storage/live-query"
import _ from "lodash"
import array_move from "lodash-move"
import React, { useEffect, useState } from "react"
import { DndProvider, useDrag, useDrop } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useDispatch, useSelector } from "react-redux"
import API from "../../api/api"
import shopSlice from "../../redux/slices/shop/shop"
import { AVDeepJsonify, qiniuImageView } from "../../utils/helpers"
import AspectImage from "../AspectImage/AspectImage"
import IconFont from "../IconFont/IconFont"
import "./ImageUploader.less"

const { Dragger } = Upload

const ItemTypes = {
	UploadedPhotoCard: "UploadedPhotoCard",
}

function moveItemPhoto(toX, toY) {
	console.log("toX", toX)
	console.log("toY", toY)
}

const ImageUploader = ({ shopItem }: { shopItem: Object }) => {
	const [loading, setLoading] = useState(false)
	const shopItemId = _.get(shopItem, "objectId")
	const [shopItemPhotos, setShopItemPhotos] = useState([])
	const [fileList, setFileList]: [RcFile[], Function] = useState([])
	const [isRearranged, setIsRearranged] = useState(false)

	const refershShopItemPhotos = async (showLoading?: boolean) => {
		if (showLoading) {
			setLoading(true)
		}

		if (shopItemId) {
			try {
				const res = await API.Shop.Item.get_shop_item_photos({
					shop_item_id: shopItemId,
				})
				setShopItemPhotos(res.map(AVDeepJsonify))
			} catch (err) {
				console.error(err)
			}
		}
		setLoading(false)
	}

	useEffect(() => {
		refershShopItemPhotos()
	}, [shopItemId])

	const saveItemPhotosOrder = async () => {
		setLoading(true)
		let params = shopItemPhotos.map((p, i) => {
			return { object_id: _.get(p, "objectId"), sort: i }
		})
		try {
			const res = await API.Shop.Item.reorder_shop_item_photos(params)
			Modal.success({
				title: "保存成功",
				content: "排序已更新",
				okCancel: false,
			})
			setIsRearranged(false)
		} catch (err) {}
		setLoading(false)
	}

	function reorder(sourceIndex, targetIndex) {
		setShopItemPhotos(array_move(shopItemPhotos, sourceIndex, targetIndex))
		setIsRearranged(true)
	}

	const UploadedPhotoCard = ({
		index,
		shopItemPhoto,
		placeholder,
	}: {
		index: number
		shopItemPhoto: object
		placeholder?: boolean
	}) => {
		const [{ isDragging }, drag] = useDrag({
			item: { type: ItemTypes.UploadedPhotoCard, index: index },
			collect: (monitor) => {
				return { isDragging: !!monitor.isDragging(), item: shopItemPhoto }
			},
		})

		const dispatch = useDispatch()
		const shop_item_photo_tags =
			useSelector((state) =>
				_.get(state, "global.commonData.shop_item_photo_tags")
			) || []
		const shopItemId = _.get(shopItem, "objectId")
		const shopItemPhotoId = _.get(shopItemPhoto, "objectId")
		const isCover =
			_.get(shopItem, "cover_url") == _.get(shopItemPhoto, "file.url")

		const setCoverImage = async (shopItemPhotoId: string) => {
			try {
				const editedItem = await API.Shop.Item.edit_shop_item({
					object_id: shopItemId,
					cover: AV.Object.createWithoutData("ShopItemPhoto", shopItemPhotoId),
				})
				dispatch(shopSlice.actions.setEditorItem(editedItem))
				dispatch(shopSlice.actions.replaceItemInPage(editedItem))
				message.success({ content: "成功设置封面" })
			} catch (err) {}
		}

		const refershShopItemPhotos = async () => {
			if (shopItemId) {
				try {
					const res = await new AV.Query("ShopItemPhoto")
						.equalTo(
							"belongToShopItem",
							AV.Object.createWithoutData("ShopItem", shopItemId)
						)
						.descending("sort")
						.addDescending("createdAt")
						.find()
					setShopItemPhotos(res.map(AVDeepJsonify))
				} catch (err) {
					console.error(err)
				}
			}
		}

		return (
			<div
				className="card"
				key={shopItemPhotoId}
				ref={drag}
				style={{
					opacity: isDragging ? 0.5 : 1,
					cursor: isDragging ? "move" : "pointer",
				}}
			>
				{placeholder ? (
					<>
						<AspectImage heightRatio={(3 / 4) * 100 + "%"} isSkeleton={true} />
						<div style={{ padding: 8 }}>
							<Skeleton title={false} round active paragraph={{ rows: 1 }} />
						</div>
					</>
				) : (
					<>
						<div className="badge">
							{isCover ? <Tag color="#d69f9f">封面</Tag> : ""}
							{_.get(shopItemPhoto, "tag") ? (
								<Tag color="#333" icon={<IconFont name="discount" />}>
									{_.get(shopItemPhoto, "tag")}
								</Tag>
							) : (
								""
							)}
						</div>
						<AspectImage
							src={qiniuImageView(_.get(shopItemPhoto, "file.url"), 375)}
							key={_.get(shopItemPhoto, "objectId")}
							heightRatio={(3 / 4) * 100 + "%"}
						/>
						<div className="tool-btn-wrapper">
							<Popconfirm
								title="设置为封面?"
								cancelText="取消"
								okText="确定"
								onConfirm={() => {
									setCoverImage(shopItemPhotoId)
								}}
							>
								<Button type="text">
									<IconFont name="icon-pic" size="1.4em" />
								</Button>
							</Popconfirm>

							<Dropdown
								overlay={
									<Menu>
										<Menu.ItemGroup title="设置标签">
											{shop_item_photo_tags.map((tag: string) => (
												<Menu.Item
													key={tag}
													onClick={async () => {
														try {
															await API.Shop.Item.edit_shop_item_photo({
																object_id: _.get(shopItemPhoto, "objectId"),
																tag: tag,
															})
															refershShopItemPhotos()
															message.success({
																content: `设置标签为"${tag}"`,
															})
														} catch (err) {}
													}}
												>
													{tag}
												</Menu.Item>
											))}
										</Menu.ItemGroup>
									</Menu>
								}
							>
								<Button type="text">
									<IconFont name="icon-discount" size="1.4em" />
								</Button>
							</Dropdown>

							<Button
								type="text"
								onClick={() => {
									Modal.confirm({
										centered: true,
										title: "删除?",
										okText: "删除",
										cancelText: "取消",
										onOk: async () => {
											try {
												await API.Shop.Item.delete_shop_item_photo({
													object_id: _.get(shopItemPhoto, "objectId"),
												})
												refershShopItemPhotos()
											} catch (err) {}
										},
									})
								}}
							>
								<IconFont name="icon-reeor" size="1.4em" color="#ff0000" />
							</Button>
						</div>
					</>
				)}
			</div>
		)
	}

	const DropGridCell = ({ index, children }) => {
		const [{ isOver }, drop] = useDrop({
			accept: ItemTypes.UploadedPhotoCard,
			drop: (item, monitor) => {
				console.log("item", item)
				const sourceIndex = monitor.getItem().index
				const targetIndex = index

				console.log(sourceIndex, targetIndex)
				reorder(sourceIndex, targetIndex)
			},
			collect: (monitor) => ({
				isOver: !!monitor.isOver(),
			}),
		})

		return (
			<div
				ref={drop}
				style={{
					width: "100%",
					height: "100%",
					border: isOver ? "2px dashed #d69f9f" : "",
				}}
			>
				{children}
			</div>
		)
	}

	return loading ? (
		<DndProvider backend={HTML5Backend}>
			<div className="shop-item-photos-grid">
				{new Array(10).fill("").map((item, i) => (
					<UploadedPhotoCard
						shopItemPhoto={null}
						index={i}
						placeholder={true}
					/>
				))}
			</div>
		</DndProvider>
	) : (
		<>
			<Dragger
				name="file"
				multiple
				accept="image/*"
				defaultFileList={fileList}
				fileList={fileList}
				listType="text"
				onChange={(info) => {
					setFileList(info.fileList.filter((f) => f.status != "done"))
				}}
				customRequest={async (options) => {
					const { onSuccess, onError, file, action, onProgress } = options
					let avfile = new AV.File("shop_item_image.png", file)
					let shopItem = AV.Object.createWithoutData("ShopItem", shopItemId)
					let shopItemPhoto = new AV.Object("ShopItemPhoto")
					try {
						const res = await shopItemPhoto
							.set("belongToShopItem", shopItem)
							.set("file", avfile)
							.save()
						onSuccess(null, file)
						refershShopItemPhotos()
					} catch (err) {
						onError(err)
					}
				}}
			>
				<p>
					<IconFont name="icon-camera" size="2em" />
				</p>
				<p>点击或把要上传的图片拖到这里</p>
			</Dragger>
			<DndProvider backend={HTML5Backend}>
				<div className="shop-item-photos-grid">
					{shopItemPhotos.map((shopItemPhoto, i) => {
						return (
							<DropGridCell index={i} key={i}>
								<UploadedPhotoCard shopItemPhoto={shopItemPhoto} index={i} />
							</DropGridCell>
						)
					})}
				</div>
			</DndProvider>
			{isRearranged ? (
				<Button
					className="save-rearrange"
					onClick={saveItemPhotosOrder}
					loading={loading}
				>
					保存排序
				</Button>
			) : (
				""
			)}
		</>
	)
}

export default ImageUploader
