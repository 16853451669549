import AV from "leancloud-storage/live-query"
import _ from "lodash"
import store from "../redux/store"
import globalSlice from "../redux/slices/global"
import moment, { Moment } from "moment"

export const qiniuImageView = (
	url: string = "",
	width: number = 750,
	height: number = null,
	mode: number = 2
) =>
	!!url
		? `${url}?imageView2/${mode}/w/${width}${
				height != null ? "/h/" + height : ""
		  }`
		: ""

export const AVDeepJsonify = (avobject: AV.Object) => {
	try {
		let obj: AV.Object = _.get(avobject, "toJSON")
			? avobject.toJSON()
			: avobject

		for (const [key, value] of Object.entries(obj)) {
			if (_.get(value, "toJSON")) {
				obj[key] = value.toJSON()
			}
		}
		return obj
	} catch (err) {
		return avobject
	}
}

export const Logout = async () => {
	try {
		await AV.User.logOut()
	} catch (err) {
		console.error(err)
	}
	store.dispatch(globalSlice.actions.setUser(null))
}

export const conditionParse = (condition: number | null) => {
	if (!condition) {
		return "无成色"
	}

	if (condition == 100) {
		return "全新"
	} else if (condition % 10 == 0) {
		return `${condition / 10}成新`
	} else {
		return `${condition}新`
	}
}

export const navbar = {
	setTitle: (title: string) => {
		store.dispatch(globalSlice.actions.setNavbarTitle(title))
	},
	setRightItem: (item) => {
		store.dispatch(globalSlice.actions.setNavbarRightItem(item))
	},
	reset: () => {
		navbar.setRightItem(null)
		navbar.setTitle(null)
	},
}

export const DATE_RANGES: {
	[key: string]: [Moment, Moment]
} = {
	all: [moment().subtract(10, "year"), moment()],
	today: [moment().set("hour", 0).set("minute", 0).set("second", 0), moment()],
	month: [moment().startOf("month"), moment().endOf("month")],
	lastMonth: [
		moment().subtract(1, "month").startOf("month"),
		moment().subtract(1, "month").endOf("month"),
	],
}

export const parseReadableId = (id: string) => {
	if (!id || !id.includes("-")) return { orderId: id }
	const splitOrderId = id.split("-")
	const prefix = splitOrderId[0].toUpperCase()
	const suffix: string = splitOrderId[1]

	let itemType: string

	switch (prefix) {
		case "SSN":
			itemType = "回收订单"
			break
		case "SON":
			itemType = "购物订单"
			break
		case "SIN":
			itemType = "商品号"
			break
		case "AUN":
			itemType = "鉴定号"
			break
		default:
			break
	}

	return {
		idString: id,
		itemType: itemType,
		id: suffix,
	}
}

export const tryReturn = (returnValue: () => any, defaultValue = "") => {
	try {
		return returnValue()
	} catch (err) {
		return defaultValue
	}
}
