import { createSlice } from "@reduxjs/toolkit"
import { isMobile } from "react-device-detect"
import realtime from "leancloud-realtime"

interface GlobalSliceInitialState {
	user?: object
	navbarTitle?: string
	navbarRightItem?: any
	commonData?: {
		brands?: object[] | null
		appraise_auth_types?: object[]
		shop_categories?: object[] | null
		shop_item_photo_tags?: string[]
	}
	chatClient?: realtime.IMClient
}

const initialState: GlobalSliceInitialState = {
	user: null,
	navbarTitle: "",
	navbarRightItem: null,
	commonData: null,
	chatClient: null,
}

const globalSlice = createSlice({
	name: "global",
	initialState: initialState,
	reducers: {
		setUser: (state, action) => ({ ...state, user: action.payload }),
		setNavbarTitle: (state, action) => ({
			...state,
			navbarTitle: action.payload,
		}),
		setNavbarRightItem: (state, action) => ({
			...state,
			navbarRightItem: action.payload,
		}),
		setCommonData: (state, action) => ({
			...state,
			commonData: action.payload,
		}),
		setChatClient: (state, action) => ({
			...state,
			chatClient: action.payload,
		}),
	},
})

export default globalSlice
