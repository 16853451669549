import React, { useState } from "react"
import "./Settings.less"
import { useEffect } from "react"
import API from "../../api/api"
import {
	Alert,
	Button,
	Card,
	Divider,
	Form,
	Input,
	message,
	Switch,
	Typography,
} from "antd"
import _ from "lodash"

const Settings = () => {
	const [homeSplashId, setHomeSplashId] = useState()
	const [homeSplash, setHomeSplash]: [object, any] = useState()
	const [homeSplashForm] = Form.useForm()
	useEffect(() => {
		fetchSettings()
	}, [])

	const fetchSettings = async () => {
		try {
			const res = await API.Breal.Settings.getSettings()
			const homeSplashSetting = res.find((s) => s.name == "home_splash")
			setHomeSplashId(_.get(homeSplashSetting, "objectId"))
			setHomeSplash(homeSplashSetting.dict)
		} catch (err) {
			message.error(JSON.stringify(err))
		}
	}

	useEffect(() => {
		homeSplashForm.setFieldsValue(homeSplash)
	}, [homeSplash])

	return (
		<div>
			<Card
				title="首页头图"
				extra={
					<Button
						type="primary"
						onClick={() => {
							homeSplashForm.submit()
						}}
					>
						保存
					</Button>
				}
			>
				<img
					src={_.get(homeSplash, "imageUrl")}
					style={{
						height: 200,
						aspectRatio: _.get(homeSplash, "aspectRatio", 2),
						marginBottom: 24,
						objectFit: "cover",
					}}
				/>
				<Form
					form={homeSplashForm}
					layout="vertical"
					onFinish={async (values) => {
						try {
							const res = await API.Breal.Settings.changeSetting({
								object_id: homeSplashId,
								value: values,
							})
							fetchSettings()
							message.success("修改成功")
						} catch (err) {
							message.error(JSON.stringify(err))
						}
					}}
				>
					<Form.Item name="imageUrl" label="图片链接">
						<Input></Input>
					</Form.Item>
					<Form.Item
						name="aspectRatio"
						label="图片比例 (宽:高)，尽可能使用 2:1比例的图片"
					>
						<Input value={_.get(homeSplash, "aspectRatio", "10").toString()} />
					</Form.Item>

					<Alert
						message={
							<div>
								<div>抖音跳转链接实例:</div>
								<div>
									个人主页{" "}
									<Typography.Text code copyable>
										snssdk1128://user/profile/账户UID
									</Typography.Text>
								</div>
								<div>
									直播间{" "}
									<Typography.Text code copyable>
										snssdk1128://live?room_id=直播间ID
									</Typography.Text>
								</div>

								<div>
									塔塔个人主页{" "}
									<Typography.Text code copyable>
										snssdk1128://user/profile/2532873513217175
									</Typography.Text>
								</div>
							</div>
						}
					></Alert>
					<Form.Item name="iosUrl" label="iOS跳转链接">
						<Input />
					</Form.Item>
					<Form.Item name="androidUrl" label="Android跳转链接">
						<Input></Input>
					</Form.Item>
					<Form.Item name="visible" label="是否显示">
						<Switch
							checked={_.get(homeSplash, "visible") || false}
							onChange={(checked) => {
								setHomeSplash({ ...homeSplash, visible: checked })
							}}
						/>
					</Form.Item>
				</Form>
			</Card>
		</div>
	)
}

export default Settings
