import {
	Alert,
	Button,
	Collapse,
	Divider,
	Drawer,
	Form,
	Input,
	message,
	Modal,
	Select,
	Slider,
	Spin,
	Card,
	notification,
	Radio,
} from "antd"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import { useDispatch, useSelector } from "react-redux"
import API from "../../api/api"
import shopSlice from "../../redux/slices/shop/shop"
import {
	AVDeepJsonify,
	qiniuImageView,
	conditionParse,
} from "../../utils/helpers"
import IconFont from "../IconFont/IconFont"
import ImageUploader from "./ImageUploader"
import "./ShopItemEditor.less"
import globalSlice from "../../redux/slices/global"

interface ShopItemEditorProps {
	visible?: boolean
	onClose?: (shouldReload?: boolean) => void
	onItemCreated?: (item: object) => void
}

const ShopItemEditor = (props: ShopItemEditorProps) => {
	const { visible, onClose, onItemCreated } = props
	const dispatch = useDispatch()
	const editItem = useSelector((state) => _.get(state, "shop.editorItem"))
	const itemIsSold = !!_.get(editItem, "sold")
	const published = _.get(editItem, "published", false)
	const [publishing, setPublishing] = useState(false)
	const [loading, setLoading] = useState(false)

	// render after drawer animation finish, improves performance
	const [shouldRender, setShouldRender] = useState(false)

	const [form] = Form.useForm()

	useEffect(() => {
		let fieldValues = {
			object_id: _.get(editItem, "objectId"),
			name: _.get(editItem, "name"),
			brand_id: _.get(editItem, "brand.objectId"),
			cat_id: _.get(editItem, "category.objectId"),
			seller_id: _.get(editItem, "seller.objectId"),
			description: _.get(editItem, "description"),
			price: _.get(editItem, "price"),
			condition: _.get(editItem, "condition") || 100,
			appraisal_id: _.get(editItem, "appraisal.id"),
		}

		form.setFieldsValue(fieldValues)
	}, [editItem, visible])

	const [brandSearchKeyword, setBrandSearchKeyword]: [
		string,
		Function
	] = useState()

	const [catSearchKeyword, setCatSearchKeyword]: [string, Function] = useState()

	const commonData = useSelector((state) => _.get(state, "global.commonData"))
	const brands = (_.get(commonData, "brands") || []).filter((b) => {
		let name: string = _.get(b, "name") || ""
		let cnName: string = _.get(b, "cnName") || ""
		let fullname = (name + cnName).toLowerCase()
		let kw = (brandSearchKeyword || "").toLowerCase()
		return fullname.includes(kw)
	})

	const categories = (_.get(commonData, "shop_categories") || []).filter(
		(c) => {
			let name: string = _.get(c, "name") || ""
			let code: string = _.get(c, "code") || ""
			let fullname = (name + code).toLowerCase()
			let kw = (catSearchKeyword || "").toLowerCase()
			return fullname.includes(kw)
		}
	)

	useEffect(() => {
		if (!commonData) {
			API.Admin.Common.get_common_data().then((res) =>
				dispatch(globalSlice.actions.setCommonData(res))
			)
		}
	}, [visible])

	const close = () => {
		dispatch(shopSlice.actions.setEditorItem(null))
		setBrandSearchKeyword()
		setCatSearchKeyword()
		onClose()
	}

	const submit = async (values) => {
		// Object.keys(values).forEach((k) => {
		// 	if (!values[k]) {
		// 		delete values[k]
		// 	}
		// })

		// convert price to cents
		values.price *= 100
		values.price = parseInt(values.price)

		setLoading(true)
		try {
			let res
			if (editItem) {
				res = await API.Shop.Item.edit_shop_item(values)
				dispatch(shopSlice.actions.replaceItemInPage(res))
				close()
			} else {
				res = await API.Shop.Item.create_shop_item(values)
				onItemCreated(AVDeepJsonify(res))
			}

			notification.success({
				message: "保存成功",
				description: _.get(editItem, "name"),
				placement: "bottomRight",
			})
		} catch (err) {}
		setLoading(false)
	}

	const BasicInfoForm = (
		<>
			<Form
				form={form}
				id="editorForm"
				onFinish={submit}
				layout="vertical"
				onValuesChange={(values) => {}}
			>
				{_.get(editItem, "objectId") ? (
					<Form.Item name="object_id" required>
						<Input size="large" disabled hidden />
						<Alert
							showIcon
							message={
								<div>
									{_.get(editItem, "objectId")} {_.get(editItem, "id")}
								</div>
							}
							icon={
								<IconFont
									name="icon-product"
									size="1.2em"
									style={{ marginRight: 4 }}
								/>
							}
							type="success"
							style={{ padding: "0.4rem 0.6rem", fontSize: "0.9em" }}
						></Alert>
					</Form.Item>
				) : (
					""
				)}

				<Form.Item label="品牌" name="brand_id" required>
					<Select
						size="large"
						allowClear
						showSearch
						placeholder="选择品牌"
						onSearch={(kw) => setBrandSearchKeyword(kw)}
						filterOption={false}
					>
						{brands.map((brand) => (
							<Select.Option value={brand.objectId} key={brand.objectId}>
								{brand.name} {brand.cnName}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item label="名称" name="name" required>
					<Input size="large" placeholder="输入商品名称" />
				</Form.Item>

				<Form.Item label="品类" name="cat_id" required>
					<Select
						size="large"
						allowClear
						showSearch
						placeholder="选择品类"
						filterOption={false}
						onSearch={(kw) => setCatSearchKeyword(kw)}
					>
						{categories.map((cat) => (
							<Select.Option value={cat.objectId} key={cat.code}>
								{cat.name} {cat.code}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item
					label="价格"
					name="price"
					required
					normalize={(value, preValue, allValues) => {
						if (value) {
							return parseFloat(value)
						} else {
							return null
						}
					}}
				>
					<Input
						size="large"
						placeholder="输入商品价格"
						type="number"
						disabled={itemIsSold}
					/>
				</Form.Item>

				<Form.Item
					label="成色"
					name="condition"
					required
					normalize={(value) => {
						if (value) {
							return parseInt(value)
						} else {
							return null
						}
					}}
				>
					<Radio.Group>
						{[80, 85, 90, 95, 99, 100].map((n) => (
							<Radio.Button key={n} value={n}>
								{conditionParse(n)}
							</Radio.Button>
						))}
					</Radio.Group>
				</Form.Item>

				<Form.Item label="描述" name="description">
					<Input.TextArea placeholder="输入描述" rows={4}></Input.TextArea>
				</Form.Item>

				<Form.Item
					label="关联的鉴定订单号 (数字号)"
					name="appraisal_id"
					normalize={(value, preValue, allValues) => {
						if (value) {
							return parseInt(value)
						} else {
							return null
						}
					}}
				>
					<Input size="large" placeholder="输入鉴定号" type="number" />
				</Form.Item>

				<Button
					size="large"
					type="primary"
					className="save-btn"
					form="editorForm"
					htmlType="submit"
					icon={<IconFont name="icon-product" style={{ marginRight: 4 }} />}
				>
					{editItem ? "保存基本信息" : "创建商品"}
				</Button>
			</Form>
		</>
	)

	const FooterContent = () => {
		return shouldRender ? (
			<div className="form-btns-wrapper">
				{editItem ? (
					<Button
						className="delete-btn"
						onClick={() => close()}
						size="large"
						icon={
							<IconFont
								name={isMobile ? "icon-arrow-down" : "icon-arrow-right"}
								style={{ marginRight: 4 }}
							/>
						}
					>
						关闭
					</Button>
				) : (
					""
				)}
			</div>
		) : (
			<></>
		)
	}

	return (
		<Drawer
			className="drawer"
			getContainer={false}
			destroyOnClose={true}
			placement="right"
			visible={visible || !!editItem}
			width={"100%"}
			height={isMobile ? "calc(100% - 44px)" : "100%"}
			onClose={() => close()}
			title={editItem ? "编辑商品" : "创建商品"}
			// footer={<FooterContent />}
			afterVisibleChange={(visible) => {
				setShouldRender(visible)
			}}
		>
			<div className="loading-spinner-wrapper" hidden={!loading}>
				<Spin spinning={loading} tip="正在和服务器沟通" />
			</div>

			{shouldRender ? (
				<div
					className={
						!!editItem && !isMobile
							? "drawer-body-container"
							: "drawer-body-container-mobile"
					}
				>
					<div className="left-col">
						{editItem ? (
							<Card title="上传图片" key="photos" size="small">
								<ImageUploader shopItem={editItem} />
							</Card>
						) : (
							""
						)}
					</div>

					<div className="right-col">
						{itemIsSold ? (
							<Card key="order" title="商品已售出" size="small">
								<Button size="large" className="action-btn">
									查看订单
								</Button>
							</Card>
						) : (
							""
						)}

						<Card title="基本资料" key="basicInfo" size="small">
							{BasicInfoForm}
						</Card>
					</div>
				</div>
			) : (
				""
			)}
		</Drawer>
	)
}

export default ShopItemEditor
