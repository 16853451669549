import {
	Input,
	Form,
	Button,
	Statistic,
	Card,
	Row,
	Col,
	Avatar,
	Table,
	Tag,
	Popconfirm,
	Modal,
} from "antd"
import _ from "lodash"
import { Divider } from "rc-menu"
import React, { useEffect, useState } from "react"
import API from "../../api/api"
import MaterialIcon from "../../components/MaterialIcon/MaterialIcon"
import { parseReadableId } from "../../utils/helpers"

const Wallet = () => {
	const [transactionLimit, setTransactionLimit] = useState(10)
	const [loadingTransactions, setLoadingTransactions] = useState(false)
	const [gettingWallet, setGettingWallet] = useState(false)
	const [currentWallet, setCurrentWallet]: [object, any] = useState()
	const [transactions, setTransactions] = useState([])

	const currentWalletId = _.get(currentWallet, "object_id")
	const currentWalletBalance = _.get(currentWallet, "balance", 0) / 100
	const currentWalletUserNickname = _.get(currentWallet, "user.nick_name", "")
	const currentWalletUserAvatar = _.get(currentWallet, "user.avatar.url", "")
	const currentWalletUserId = _.get(currentWallet, "user.object_id", "")

	const [addBalanceForm] = Form.useForm()

	useEffect(() => {
		getUserWallet("5a032bdf1579a300451d5e91")
		return () => {}
	}, [])

	const getUserWallet = async (userId: string) => {
		setGettingWallet(true)
		const wallet = await API.Breal.Wallet.getWalletByUserId({ user_id: userId })
		const ts = await API.Breal.Wallet.getTransactions({
			wallet_id: wallet.object_id,
			skip: 0,
			limit: transactionLimit,
		})
		console.log(`ts`, ts)
		setCurrentWallet(wallet)
		setTransactions(ts)
		setGettingWallet(false)
	}

	const loadTransactions = async ({ refresh }: { refresh?: boolean }) => {
		setLoadingTransactions(true)
		if (refresh) {
			setTransactions([])
		}
		if (currentWallet) {
			const ts = await API.Breal.Wallet.getTransactions({
				wallet_id: currentWalletId,
				skip: refresh ? 0 : transactions.length,
				limit: transactionLimit,
			})
			if (refresh) {
				setTransactions(ts)
			} else {
				setTransactions([...transactions, ...ts])
			}
		}
		setLoadingTransactions(false)
	}

	const columns = [
		{
			title: "明细",
			render: (t) => {
				let rid = parseReadableId(t.order)
				return (
					<div>
						<div>{t.title}</div>
						<div style={{ fontSize: "0.8em" }}>{t.object_id}</div>
						{t.order ? (
							<>
								<div>{t.order}</div>
								<div>
									<Tag color="blue">{rid.itemType}</Tag>
								</div>
							</>
						) : (
							""
						)}
					</div>
				)
			},
		},
		{
			title: "数额",
			render: (t) => {
				return (
					<div>
						<div
							style={{
								fontSize: "1.1em",
								fontWeight: "bold",
								color: t.amount < 0 ? "red" : "green",
							}}
						>
							{t.amount > 0 ? "+" : ""}
							{t.amount / 100}元
						</div>
					</div>
				)
			},
		},

		{
			title: "完成",
			render: (t) => {
				return (
					<div style={{ color: t.complete ? "" : "red" }}>
						{t.complete ? (
							<MaterialIcon name="check_circle" text="完成" />
						) : (
							<MaterialIcon name="pending" text="处理中" />
						)}
					</div>
				)
			},
		},
		{
			title: "操作",
			render: (t) => {
				return (
					<div>
						{!!t.destination_data && !t.complete ? (
							<Button
								type="link"
								onClick={() => {
									Modal.confirm({
										title: "批准提现?",
										content: `是否确定批准提现 ${Math.abs(t.amount / 100)}元`,
										onOk: async () => {
											await API.Breal.Wallet.approveWithdraw({
												transaction_id: t.object_id,
											})
											loadTransactions({ refresh: true })
										},
									})
								}}
							>
								<MaterialIcon name="money" text="批准提现" />
							</Button>
						) : (
							""
						)}
						{!t.revert_to && !t.destination_data ? (
							<Button
								type="link"
								onClick={() => {
									Modal.confirm({
										title: "撤销交易?",
										content: (
											<div>
												<div>
													此操作将会增加一条反向交易，不会删除已有交易。
												</div>
												<div>
													如：撤销扣费交易，将会添加一条等额的充值交易。
												</div>
											</div>
										),
										onOk: async () => {
											await API.Breal.Wallet.revertTransaction({
												transaction_id: t.object_id,
											})
                                            getUserWallet(currentWalletUserId)
											// loadTransactions({ refresh: true })
										},
									})
								}}
							>
								<MaterialIcon name="undo" text="撤销交易" />
							</Button>
						) : (
							""
						)}
					</div>
				)
			},
		},
	]

	return (
		<div>
			<Form
				initialValues={{
					userId: "5a032bdf1579a300451d5e91",
				}}
				onFinish={(values) => {
					console.log(`values`, values)
					getUserWallet(values.userId)
				}}
			>
				<Form.Item label="获取用户钱包" name="userId">
					<Input.Search
						placeholder="输入用户ID"
						enterButton={
							<Button type="primary" loading={gettingWallet} htmlType="submit">
								获取钱包
							</Button>
						}
					/>
				</Form.Item>
			</Form>

			<Row gutter={8}>
				<Col span={8}>
					<Card
						title="钱包"
						extra={
							<div>
								<Button
									type="primary"
									ghost
									onClick={() => {
										Modal.info({
											title: "手动充值到钱包",
											content: (
												<Form
													form={addBalanceForm}
													onFinish={(values) => {
														let amount = parseFloat(values.amount)
														if (!isNaN(amount) && amount > 0) {
															let amountInCents = Math.round(amount * 100)
															Modal.confirm({
																title: "确认充值?",
																content: `是否充值 ¥${amountInCents / 100} 元?`,
																onOk: async () => {
																	await API.Breal.Wallet.addBalance({
																		wallet_id: currentWalletId,
																		amount_in_cents: amountInCents,
																	})
																	getUserWallet(currentWalletUserId)
																},
															})
														} else {
															Modal.error({
																title: "必须输入大于0的数字",
															})
														}
													}}
												>
													<Form.Item label="输入充值金额" name="amount">
														<Input placeholder="输入金额"></Input>
													</Form.Item>
												</Form>
											),
											maskClosable: true,
											okCancel: true,
											onOk: () => {
												addBalanceForm.submit()
												setTimeout(() => {
													addBalanceForm.resetFields()
												}, 1000)
											},
											onCancel: () => {
												addBalanceForm.resetFields()
											},
										})
									}}
								>
									充值
								</Button>
								<Button
									danger
									style={{ marginLeft: 8 }}
									onClick={() => {
										Modal.confirm({
											title: "是否清零钱包?",
											content:
												"将会清零钱包余额，并删除所有交易记录，不要乱操作。",
											onOk: async () => {
												await API.Breal.Wallet.reset({
													wallet_id: currentWalletId,
												})
												getUserWallet(currentWalletUserId)
											},
										})
									}}
								>
									清零
								</Button>
							</div>
						}
					>
						<div style={{ clear: "both" }}>
							<Statistic
								title="账户余额"
								prefix="¥"
								value={currentWalletBalance}
							></Statistic>
							<Card.Meta
								style={{ width: "100%", marginTop: 24 }}
								title={currentWalletUserNickname}
								description={currentWalletUserId}
								avatar={<Avatar src={currentWalletUserAvatar} />}
							></Card.Meta>
						</div>
					</Card>
				</Col>
				<Col span={16}>
					<Card title="明细">
						<Table
							dataSource={transactions}
							columns={columns}
							rowKey={(r) => r.object_id}
						></Table>
					</Card>
				</Col>
			</Row>
		</div>
	)
}

export default Wallet
