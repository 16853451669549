import {
	Card,
	DatePicker,
	List,
	Modal,
	Popconfirm,
	Select,
	Tag,
	Skeleton,
	Avatar,
	Button,
	Dropdown,
	Menu,
	message,
} from "antd"
import _ from "lodash"
import moment from "moment"
import React, { useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import "./OrderExpandedRow.less"
import IconFont from "../../components/IconFont/IconFont"
import { useSelector } from "react-redux"
import API from "../../api/api"
import { navbar } from "../../utils/helpers"
import AdminPaygrade from "../../components/AdminPaygrade/AdminPaygrade"

const { Option } = Select
const { MonthPicker } = DatePicker

interface OrderExpandedRowProps {
	orderId?: string
	reload?: Function
}

const OrderExpandedRow = ({
	orderId,
	reload = () => {},
}: OrderExpandedRowProps) => {
	const [authOrder, setAuthOrder] = useState(null)
	const [loading, setLoading] = useState(false)
	const commonData = useSelector((state) => _.get(state, "global.commonData"))

	const params = useParams()
	const authOrderId = orderId || _.get(params, "id")

	const brands = _.get(commonData, "brands", [])
	const types = _.get(commonData, "appraise_auth_types", [])
	const plans = _.get(commonData, "appraise_auth_plans", [])

	useEffect(() => {
		if (authOrderId) {
			fetchOrder(authOrderId)
		}

		if (_.get(params, "id")) {
			navbar.setTitle("鉴定订单详情")
		}

		return () => {
			navbar.reset()
		}
	}, [authOrderId])

	const fetchOrder = async (orderId) => {
		try {
			setLoading(true)
			const res = await API.Appraise.AdminCP.get_order({
				order_id: orderId,
				include_payloads: true,
			})
			setAuthOrder(res)
			console.log(res)
		} catch (error) {}
		setLoading(false)
	}

	const PlansMenu = () => {
		const handleSelect = (plan) => {
			Modal.confirm({
				title: `是否修改套餐为${plan.name}? `,
				centered: true,
				onOk: async () => {
					try {
						const res = await API.Appraise.AdminCP.set_auth_plan({
							order_id: _.get(authOrder, "objectId"),
							plan_id: _.get(plan, "objectId"),
						})
						message.success("修改成功")
						reload()
					} catch (err) {}
				},
			})
		}
		return (
			<Menu selectable={false}>
				{plans.map((p) => (
					<Menu.Item key={p.objectId}>
						<a
							onClick={() => {
								handleSelect(p)
							}}
						>
							{p.name}
						</a>
					</Menu.Item>
				))}
			</Menu>
		)
	}

	const BrandsMenu = () => {
		const handleSelect = (brand) => {
			Modal.confirm({
				title: `是否修改品牌为${brand.name}? `,
				centered: true,
				onOk: async () => {
					try {
						const res = await API.Appraise.AdminCP.set_auth_brand({
							order_id: _.get(authOrder, "objectId"),
							brand_id: _.get(brand, "objectId"),
						})
						message.success("修改成功")
						reload()
					} catch (err) {}
				},
			})
		}

		return (
			<Menu
				style={{
					maxHeight: 300,
					overflow: "auto",
					border: "1px solid #ccc",
					borderRadius: 4,
				}}
				selectable={false}
			>
				{brands.map((p) => (
					<Menu.Item key={p.objectId}>
						<a
							onClick={() => {
								handleSelect(p)
							}}
						>
							{p.name}
						</a>
					</Menu.Item>
				))}
			</Menu>
		)
	}

	const TypesMenu = () => {
		const handleSelect = (auth_type) => {
			Modal.confirm({
				title: `是否修改品牌为${auth_type.cnName}? `,
				centered: true,
				onOk: async () => {
					try {
						const res = await API.Appraise.AdminCP.set_auth_type({
							order_id: _.get(authOrder, "objectId"),
							type_id: _.get(auth_type, "objectId"),
						})
						message.success("修改成功")
						reload()
					} catch (err) {}
				},
			})
		}

		return (
			<Menu selectable={false}>
				{types.map((p) => (
					<Menu.Item key={p.objectId}>
						<a
							onClick={() => {
								handleSelect(p)
							}}
						>
							{p.cnName}
						</a>
					</Menu.Item>
				))}
			</Menu>
		)
	}

	return (
		<div className="expanded-row-container">
			<div className="progress-grid">
				{!_.get(authOrder, "payloads") ? <Card loading={loading} /> : ""}
				{_.get(authOrder, "payloads", []).map((p) => {
					const nickname = _.get(p, "isAppraiser")
						? `鉴定师: ${_.get(authOrder, "appraiser.name", "无")}`
						: `客户: ${_.get(authOrder, "user.name", "")}`
					const clientNickName = `客户: ${_.get(authOrder, "user.name", "")}`
					const user_url = _.get(p, "isAppraiser")
						? `/User/${_.get(authOrder, "appraiser.id")}`
						: `/User/${_.get(authOrder, "user.id")}`
					const voice_message = _.get(p, "payload.voice_message")
					const text_message = _.get(p, "payload.text_message")
					const reply_voice_message = _.get(p, "reply.payload.voice_message")
					const reply_text_message = _.get(p, "reply.payload.text_message")
					const rule_photos = _.get(p, "payload.rule_photos", [])
					const reply_rule_photos = _.get(p, "reply.payload.rule_photos", [])
					return (
						<Card>
							<Skeleton avatar title={false} active loading={false}>
								<List.Item.Meta
									avatar={
										<Avatar
											src={
												_.get(p, "isAppraiser")
													? _.get(authOrder, "appraiser.avatar")
													: _.get(authOrder, "user.avatar")
											}
										/>
									}
									title={<a href={user_url}>{nickname}</a>}
									description={moment(_.get(p, "createdAt")).format(
										"YYYY.MM.DD HH:mm"
									)}
									style={{ marginBottom: 8 }}
								/>
								<div
									key={_.get(p, "objectId")}
									className="auth-detail-container"
								>
									{rule_photos.map((r) => {
										const image_url =
											r.appraiser_photo_url || r.photo_url || r.rule_cover
										return (
											<a key={image_url} href={image_url} target="_blank">
												<div className="auth-image-container">
													<img
														src={`${image_url}?imageView2/2/w/100`}
														className="auth-image"
													/>
													<div className="rule-name">{r.rule_name}</div>
												</div>
											</a>
										)
									})}
								</div>
							</Skeleton>

							{voice_message ? (
								<div
									style={{
										padding: 8,
										borderRadius: 8,
										border: "1px solid #ddd",
										backgroundColor: "#eee",
									}}
								>
									语音: <audio src={voice_message}></audio>
								</div>
							) : (
								""
							)}

							{text_message ? (
								<div className="text-msg">{text_message}</div>
							) : (
								""
							)}

							{_.get(p, "reply") ? (
								<div style={{ marginTop: 16 }}>
									<List.Item.Meta
										avatar={<Avatar src={_.get(authOrder, "user.avatar")} />}
										title={<a href={user_url}>{clientNickName} 回复:</a>}
										description={moment(_.get(p, "reply.createdAt")).format(
											"YYYY.MM.DD HH:mm"
										)}
										style={{ marginBottom: 8 }}
									/>
									<div
										key={_.get(p, "reply.objectId")}
										className="auth-detail-container"
									>
										{reply_rule_photos.map((r) => {
											const image_url =
												r.appraiser_photo_url || r.photo_url || r.rule_cover
											return (
												<a key={image_url} href={image_url} target="_blank">
													<div className="auth-image-container">
														<img
															src={`${image_url}?imageView2/2/w/100`}
															className="auth-image"
														/>
														<div className="rule-name">{r.rule_name}</div>
													</div>
												</a>
											)
										})}
									</div>

									{reply_voice_message ? (
										<div
											style={{
												padding: 8,
												borderRadius: 8,
												border: "1px solid #ddd",
												backgroundColor: "#eee",
											}}
										>
											语音: <audio src={reply_voice_message}></audio>
										</div>
									) : (
										""
									)}

									{reply_text_message ? (
										<div className="text-msg">{reply_text_message}</div>
									) : (
										""
									)}
								</div>
							) : (
								""
							)}
						</Card>
					)
				})}
			</div>

			<Card
				loading={loading}
				title="鉴定详情"
				extra={
					<div className="edit-btns-container">
						<Dropdown overlay={<BrandsMenu />}>
							<Button>
								品牌 <IconFont name="icon-arrow-down" />
							</Button>
						</Dropdown>
						<Dropdown overlay={<TypesMenu />}>
							<Button>
								品类 <IconFont name="icon-arrow-down" />
							</Button>
						</Dropdown>
						<Dropdown overlay={<PlansMenu />}>
							<Button>
								套餐 <IconFont name="icon-arrow-down" />
							</Button>
						</Dropdown>
					</div>
				}
			>
				<List size="small">
					<List.Item>
						<Tag>ObjectId</Tag> {_.get(authOrder, "objectId")}
					</List.Item>
					<List.Item>
						<Tag>鉴定号</Tag> {_.get(authOrder, "id")}
					</List.Item>
					<List.Item>
						<Tag>用户</Tag>{" "}
						<Link to={`/user/${_.get(authOrder, "user.id")}`}>
							{_.get(authOrder, "user.name")}
						</Link>
					</List.Item>
					<List.Item>
						<Tag>用户ID</Tag> {_.get(authOrder, "user.id")}
					</List.Item>

					{_.get(authOrder, "certificate") ? (
						<List.Item>
							<Tag>鉴定证书</Tag>{" "}
							<a href={_.get(authOrder, "certificate")} target="_blank">
								<img src={_.get(authOrder, "certificate")} width="100px" />
							</a>
						</List.Item>
					) : (
						""
					)}

					<List.Item>
						<Tag>二维码</Tag>{" "}
						<img
							src={_.get(authOrder, "weapp_QR")}
							style={{ width: 100, height: 100 }}
						/>
					</List.Item>

					
					{/* <List.Item>
						<Tag>支付信息</Tag>{" "}
						{JSON.stringify(_.get(authOrder, "payment", {}))}
					</List.Item> */}


				</List>


				<List size="small">
					<AdminPaygrade requiredLevel={9}>
						<List.Item>
							<Tag>批准退单</Tag>

							{!!_.get(authOrder, "refund.appraiser_requested_refund") &&
							!_.get(authOrder, "refund.is_refunded") ? (
								<div style={{ margin: "24px 0" }}>
									<div>鉴定师申请退单: {_.get(authOrder, "refund.reason")}</div>
									<Popconfirm
										title="是否批准退单退款?"
										onConfirm={async () => {
											try {
												const res = await API.Appraise.AdminCP.refund_order({
													order_id: _.get(authOrder, "objectId"),
												})
												reload()
											} catch (err) {}
										}}
									>
										<a>批准退单退款</a>
									</Popconfirm>
								</div>
							) : (
								"鉴定师未申请退单"
							)}
						</List.Item>
					</AdminPaygrade>
					<AdminPaygrade requiredLevel={9}>
						<List.Item>
							<Tag>退款</Tag>
							{!_.get(authOrder, "refund.is_refunded") ? (
								<div>
									<a
										style={{ color: "red" }}
										onClick={() => {
											let reason =
												prompt("输入退款原因，不输入则显示默认原因") || ""
											reason = reason.trim()
											Modal.confirm({
												title: "确定退单退款吗?",
												content: "退款原因: " + (reason || "客户申请退单退款"),
												onOk: async () => {
													try {
														const res = await API.Appraise.AdminCP.direct_refund_order(
															{
																order_id: _.get(authOrder, "objectId"),
																reason: reason,
															}
														)
														Modal.success({
															title: "退款请求已发送",
															content:
																'退款请求已经发送到微信商户平台，具体到账时间视微信实际情况而定。退款成功后，点单状态会显示"已退款"',
														})
														reload()
													} catch (error) {}
												},
											})
										}}
									>
										直接退款 <IconFont name="icon-tuishui" />
									</a>
								</div>
							) : (
								""
							)}
						</List.Item>
					</AdminPaygrade>
					<List.Item>
						<Tag>删除鉴定订单</Tag>
						<a
							style={{ color: "red" }}
							onClick={() => {
								Modal.confirm({
									title: "确定删除该鉴定订单吗?",
									content: "此操作将不可逆转，请谨慎操作",
									onOk: () => {
										// api.delete_auth_order(record.objectId).then(res => {
										//     if (!res.error) {
										//         Modal.success({
										//             title: "成功",
										//             content: res.message,
										//             onOk: getPage(pagination.current)
										//         })
										//     } else {
										//         Modal.error({
										//             title: "失败",
										//             content: res.message
										//         })
										//     }
										// })
									},
									centered: true,
								})
							}}
						>
							删除鉴定(危险操作) <IconFont name="icon-shanchu" />
						</a>
					</List.Item>
				</List>
			</Card>
		</div>
	)
}
export default OrderExpandedRow
