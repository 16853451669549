import React, { useEffect, useState } from "react"
import "./RevnueStats.less"
import moment, { Moment } from "moment"
import API from "../../api/api"
import { Card, Form, DatePicker, Statistic, Alert, Spin, Button } from "antd"
import _ from "lodash"
import { navbar, DATE_RANGES } from "../../utils/helpers"
import { useSelector } from "react-redux"
import AdminPaygrade from "../../components/AdminPaygrade/AdminPaygrade"

const stats_key_dict = {
	weapp: "微信支付",
	swan: "百度支付",
	candy: "糖果支付",
	total: "交付营收",
	refund: "退款",
	revenue: "营收",
	count: "单数",
	refund_count: "退单",
	cash_equivalent: "现金价值",
	cash_revenue: "现金营收",
	candy_revenue: "糖果营收",
	candy_refill_revenue: "糖果充值",
	appraiser_commission: "鉴定师分成",
	commission: "分成",
	actual_stats: "实际营收",
	total_revenue: "营收合计",
}

const RevnueStats = () => {
	const requiredAdminLevel = 10
	const [loading, setLoading] = useState(false)
	const [dateRange, setDateRange] = useState(DATE_RANGES.today)
	const [stats, setStats] = useState()
	const [form] = Form.useForm()

	const currentUserALevel = useSelector((state) =>
		_.get(state, "global.user.ALevel", 0)
	)

	useEffect(() => {
		fetchData()
		navbar.setTitle(`鉴定神器统计`)
	}, [dateRange])

	const fetchData = async () => {
		if (currentUserALevel < requiredAdminLevel) {
			return
		}
		setLoading(true)
		try {
			const res = await API.Appraise.AdminCP.revenue_stats({
				start_date: dateRange[0].toDate(),
				end_date: dateRange[1].toDate(),
			})
			setStats(res)
		} catch (error) {}
		setLoading(false)
	}

	const formChanged = (params: { date: [Moment, Moment] }) => {
		// setDateFilter(params.date)
		console.log(params)
		setDateRange(params.date)
	}

	return (
		<AdminPaygrade requiredLevel={requiredAdminLevel}>
			<div>
				<Form
					style={{ marginBottom: 16 }}
					form={form}
					onValuesChange={formChanged}
					initialValues={{
						date: dateRange,
					}}
					layout="inline"
				>
					<Form.Item name="date">
						<DatePicker.RangePicker
							showTime
							ranges={{
								全部: DATE_RANGES.all,
								今天: DATE_RANGES.today,
								本月: DATE_RANGES.month,
								上个月: DATE_RANGES.lastMonth,
							}}
						/>
					</Form.Item>
					<Form.Item>
						<Button onClick={fetchData}>刷新</Button>
					</Form.Item>
				</Form>

				<Alert
					type="info"
					banner
					message={
						<>
							<div>营收/分成: 按鉴定师出具鉴定结果的时间结算，不包含退单</div>
							<div>糖果充值/退单: 按下单时间计算</div>
						</>
					}
					style={{ marginBottom: "1rem" }}
				></Alert>

				<Spin spinning={loading} className="spinner" tip="用力计数中 😂" />
				<div className="stats-grid">
					{!!stats
						? Object.keys(stats).map((key) => {
								let val = _.get(stats, key)
								return (
									<Card
										key={key}
										title={_.get(stats_key_dict, key)}
										loading={loading}
									>
										<div className="stats-content-grid">
											{Object.keys(val).map((subkey) => {
												let subval = _.get(val, subkey, 0)
												let isMoney = [
													"revenue",
													"total_revenue",
													"cash_revenue",
													"cash_equivalent",
													"appraiser_commission",
													"candy_refill_revenue",
												].includes(subkey)

												let isCandy = subkey.includes("candy")

												if (key == "candy") {
													isCandy = subkey == "revenue"
													isMoney =
														subkey == "cash_equivalent" ||
														subkey == "candy_refill_revenue"
												}
												return (
													<Statistic
														key={subkey}
														title={_.get(stats_key_dict, subkey)}
														precision={isMoney ? 2 : 0}
														prefix={isMoney ? "¥" : isCandy ? "🍬" : ""}
														value={subval}
														valueStyle={{
															color: isMoney ? (subval > 0 ? "" : "red") : "",
														}}
														decimalSeparator="."
														groupSeparator=""
													/>
												)
											})}
										</div>
									</Card>
								)
						  })
						: ""}
				</div>
			</div>
		</AdminPaygrade>
	)
}

export default RevnueStats
