import React, { useEffect, useState, useRef } from "react"
import "./WebVisit.less"
import AV from "leancloud-storage/live-query"
import { Table, Tooltip, message, Button } from "antd"
import _ from "lodash"
import moment from "moment"
import { navbar } from "../../utils/helpers"
import { ColumnsType } from "antd/lib/table"
import IconFont from "../../components/IconFont/IconFont"

const WebVisit = () => {
	const [loading, setLoading] = useState(false)
	const [webVisits, setWebVisits] = useState([])
	const webVisitRef = useRef(webVisits)
	webVisitRef.current = webVisits

	const [queryCountdown, setQueryCountdown] = useState(0)
	const queryCountdownRef = useRef(queryCountdown)
	queryCountdownRef.current = queryCountdown

	useEffect(() => {
		navbar.setTitle("官网搜索流量分析")

		const interval = setInterval(() => {
			if (queryCountdownRef.current > 0) {
				setQueryCountdown(queryCountdownRef.current - 1)
			}

			if (queryCountdownRef.current === 0) {
				setQueryCountdown(30)
				queryWebVisits()
			}
		}, 1000)

		return () => {
			clearInterval(interval)
		}
	}, [])

	const parseAdPosition = (s: string, pageNum: string) => {
		s = s || ""
		pageNum = pageNum || ""
		let icon = s.includes("c")
			? "icon-pc"
			: s.includes("m")
			? "icon-mobile-phone"
			: ""

		let color = s.includes("c") ? "#4caf4f" : "#db7b7b"
		s = s
			.replace("{adposition}", "")
			.replace("c", "")
			.replace("m", "")
			.replace("t", "顶")
			.replace("b", "底")
			.replace("l", "左")
			.replace("r", "右")
			.replace("g", "(有底色)")

		pageNum = parseInt(pageNum) ? `页${pageNum}` : ""
		return (
			<span>
				<IconFont name={icon} size="1.3m" color={color} />
				{pageNum}
				{s}
			</span>
		)
	}

	const queryWebVisits = async () => {
		setLoading(true)
		const query = new AV.Query("WebVisits").descending("createdAt")
		const res = await query.limit(1000).find()
		let json_res = res.map((r) => r.toJSON())
		setWebVisits([...webVisits, ...json_res])
		setLoading(false)
	}

	const columns: ColumnsType = [
		{
			title: "时间",
			dataIndex: "createdAt",
			render: (d, index) => moment(d).format("MM.DD HH:mm"),
		},
		{
			title: "关键词",
			render: (r) => (
				<Tooltip
					title={
						<div>
							<div>关键词ID: {_.get(r, "keywordid")}</div>
							<div>UTF8: {_.get(r, "keyword_utf8")}</div>
						</div>
					}
				>
					{_.get(r, "keyword")}
				</Tooltip>
			),
		},
		{ title: "渠道", align: "center", dataIndex: "ref" },
		{
			title: "排名",
			align: "center",
			render: (r) => (
				<span style={{ minWidth: "80px", flexShrink: 0 }}>
					{parseAdPosition(_.get(r, "adposition", ""), _.get(r, "pagenum"))}
				</span>
			),
		},
		{
			title: "创意",
			align: "center",
			render: (r) => {
				let creativeId = _.get(r, "creative")
				let shop_url = `https://fengchao.baidu.com/fc/manage/new/user/26900583/campaign/130680984/adgroup/4935319451/creative/${creativeId}/`
				let appraise_url = `https://fengchao.baidu.com/fc/manage/new/user/26900583/campaign/116548202/adgroup/1194057028/creative/${creativeId}/`
				let creativeUrl = _.get(r, "url", "").includes("ilux.ai/shop")
					? shop_url
					: appraise_url
				return (
					<Tooltip title={`ID: ${creativeId}`}>
						<Button href={creativeUrl} target="_blank" size="small">
							查看
						</Button>
					</Tooltip>
				)
			},
		},
		{
			title: "点击",
			align: "center",
			dataIndex: "clickedThrough",
			render: (clickedThrough) =>  clickedThrough ? <IconFont name="success" color="green" size={24}/>: ""
		},
		{
			title: "访问链接",
			dataIndex: "url",
			render: (url) => (
				<Tooltip title={url}>
					<div
						style={{
							maxWidth: "128px",
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						<a href={url} target="_blank">
							{_.get(url.split("?"), "0", "").replace("https://", "")}
						</a>
					</div>
				</Tooltip>
			),
		},
	]

	return (
		<div>
			<Button
				onClick={() => {
					queryWebVisits()
				}}
			>
				刷新({queryCountdown})
			</Button>
			<Table
				size="small"
				loading={loading}
				dataSource={webVisits}
				columns={columns}
				rowKey={(r) => _.get(r, "objectId")}
				pagination={{ pageSize: 100 }}
			/>
		</div>
	)
}

export default WebVisit
