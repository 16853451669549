import React, { useEffect } from "react"
import "./Home.less"
import { Card, Tabs } from "antd"

const Home = () => {
	const tabs = [
		{
			key: "leancloud",
			title: "LeanCloud",
			url: "https://status.leancloud.cn/",
		},
		{
			key: "iluxai_home",
			title: "官网",
			url: "https://ilux.ai",
		},
		{
			key: "leancloudLogs",
			title: "LeanCloud运维",
			url:
				"https://leancloud.cn/dashboard/cloud.html?appid=WIT5O5deQWcL8B1jo4yidtVy-gzGzoHsz#/log",
		},
	]

	return (
		<div className="home-page">
			<Tabs className="tabs" animated={true}>
				{tabs.map((tab) => (
					<Tabs.TabPane tab={tab.title} tabKey={tab.key} key={tab.key}>
						<iframe src={tab.url} className="frame" />
					</Tabs.TabPane>
				))}
			</Tabs>
		</div>
	)
}

export default Home
