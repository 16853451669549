import React, { CSSProperties } from "react"
import "material-icons/iconfont/material-icons.css"

const MaterialIcon = ({
	name,
	text,
	prefix,
	gap = 4,
	size,
	color,
	style,
	fontset = "material-icons-outlined",
}: {
	name: string
	prefix?: string
	text?: string
	gap?: number
	color?: string
	size?: number | string
	style?: CSSProperties
	fontset?:
		| "material-icons"
		| "material-icons-outlined"
		| "material-icons-round"
		| "material-icons-sharp"
		| "material-icons-two-tone"
}) => {
	style = style || {}
	size = size || 16
	style.fontSize = size
	style.color = color
	return (
		<span
			style={{ display: "inline-flex", flexFlow: "row", alignItems: "center" }}
		>
			{prefix ? (
				<span style={{ marginRight: gap, color: color }}>{prefix}</span>
			) : (
				""
			)}
			<span className={fontset} style={style}>
				{name}
			</span>
			{text ? (
				<span style={{ marginLeft: gap, color: color }}>{text}</span>
			) : (
				""
			)}
		</span>
	)
}

export default MaterialIcon
