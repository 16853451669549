import React, { useState, useEffect } from "react"
import "./HtmlContents.less"
import API from "../../api/api"
import { Typography, Table, Button, Popconfirm, Modal, Form } from "antd"
import _ from "lodash"
import { ColumnsType } from "antd/lib/table"
import HtmlContentEditor from "./HtmlContentEditor"

const HtmlContents = () => {
	const [contents, setContents] = useState([])

	const [editingContent, setEditingContent] = useState(null)

	useEffect(() => {
		fetchData()
	}, [])

	const fetchData = async () => {
		try {
			const res = await API.Admin.Common.get_html_contents()
			setContents(res)
		} catch (error) {}
	}

	const columns: ColumnsType = [
		{
			title: "标题",
			dataIndex: "title",
		},
		{
			title: "内容ID",
			dataIndex: "contentId",
			render: (contentId) => (
				<Typography.Paragraph copyable>{contentId}</Typography.Paragraph>
			),
		},
		{
			title: "操作",
			key: "actions",
			render: (r) => {
				return (
					<div className="btn-wrapper">
						<Button
							type="primary"
							ghost
							href={`https://cloud.ilux.ai/htmlcontent/${_.get(
								r,
								"contentId"
							)}`}
							target="_blank"
						>
							预览
						</Button>
						<Button
							type="primary"
							ghost
							onClick={() => {
								setEditingContent(r)
							}}
						>
							编辑
						</Button>

						<Button
							danger
							disabled={_.get(r, "protected")}
							onClick={() => {
								if (_.get(r, "protected")) {
									return
								}
								Modal.confirm({
									title: "确定删除这篇内容?",
									onOk: async () => {
										await API.Admin.Common.delete_html_content({
											objectId: _.get(r, "objectId"),
										})
										fetchData()
									},
								})
							}}
						>
							删除
						</Button>
					</div>
				)
			},
		},
	]

	return (
		<div>
			<Form.Item style={{ textAlign: "right" }}>
				<Button
					onClick={() => {
						setEditingContent({})
					}}
				>
					+ 新建
				</Button>
			</Form.Item>
			<Table
				dataSource={contents}
				columns={columns}
				rowKey={(r) => _.get(r, "objectId")}
				pagination={{
					pageSize: 100
				}}
			/>

			<Modal
				title="编辑内容"
				visible={!!editingContent}
				onCancel={() => {
					setEditingContent(null)
				}}
				centered
				footer={<></>}
				width="80vw"
				bodyStyle={{ height: "86vh", overflow: "scroll" }}
				destroyOnClose={true}
			>
				<HtmlContentEditor
					contentObject={editingContent}
					onSave={() => {
						setEditingContent(null)
						fetchData()
					}}
				/>
			</Modal>
		</div>
	)
}

export default HtmlContents
