import {
	Card,
	Form,
	Input,
	message,
	Modal,
	List,
	Button,
	Row,
	Col,
	Tag,
	Statistic,
	Avatar,
} from "antd"
import _, { reject } from "lodash"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router"
import API from "../../api/api"
import "./SellItemDetail.less"
import { Link } from "react-router-dom"
import { qiniuImageView } from "../../utils/helpers"
import moment from "moment"

const SellItemDetail = () => {
	const history = useHistory()
	const params = useParams()
	const [offerForm] = Form.useForm()
	const [rejectForm] = Form.useForm()
	const itemObjectId = _.get(params, "objectId")
	const [sellItem, setSellItem] = useState()
	const [sellPhotos, setSellPhotos] = useState([])

	const seller = _.get(sellItem, "seller", "-")
	const initialOffer = _.get(sellItem, "initial_offer")
	const finalOffer = _.get(sellItem, "final_offer")
	const authOrder = _.get(sellItem, "auth_order")
	const toShipping = _.get(sellItem, "to_shipping")
	const returnShipping = _.get(sellItem, "return_shipping")
	const extraInfo = _.get(sellItem, "extra_info")
	const payment = _.get(sellItem, "payment")

	useEffect(() => {
		fetchSellItem()
		fetchSellPhotos()
	}, [])

	const fetchSellItem = () => {
		API.Breal.SellItem.fetchSellItem({ sell_item_id: itemObjectId }).then(
			(res) => {
				setSellItem(res)
			}
		)
	}

	const fetchSellPhotos = () => {
		API.Breal.SellItem.fetchSellPhotos({ sell_item_id: itemObjectId }).then(
			(res) => {
				setSellPhotos(res)
			}
		)
	}

	const releaseFund = async () => {
		try {
			const res = await API.Breal.SellItem.close_deal({
				sell_item_id: itemObjectId,
			})
			console.log(`res`, res)
		} catch (err) {
			console.error(err)
		}
	}

	const makeOffer = ({ stage }: { stage: "initial" | "final" }) => {
		const stageName = stage == "initial" ? "初步" : "最终"

		Modal.confirm({
			title: stageName + "报价",
			content: (
				<Form
					form={offerForm}
					onFinish={(values) => {
						let offerAmount = parseFloat(values.offer)
						if (isNaN(offerAmount)) {
							return Modal.error({ title: "请输入数字" })
						}

						let priceInCents = offerAmount * 100
						Modal.confirm({
							title: "确认报价?",
							content: `是否确认出具${stageName}报价: ¥${offerAmount}元`,
							onOk: async () => {
								await API.Breal.SellItem.makeOffer({
									sell_item_id: itemObjectId,
									price_in_cents: priceInCents,
									offer_stage: stage,
								})

								message.success({ content: `${stageName}报价出具成功` })
								fetchSellItem()
							},
						})

						setTimeout(() => {
							offerForm.resetFields()
						}, 500)
					}}
				>
					<Form.Item name="offer">
						<Input placeholder="输入报价" prefix="¥" autoFocus />
					</Form.Item>
				</Form>
			),
			maskClosable: true,
			okText: `出具${stageName}报价`,
			onOk: offerForm.submit,
			onCancel: () => {
				offerForm.resetFields()
			},
		})
	}

	const rejectOffer = ({ stage }: { stage: "initial" | "final" }) => {
		const stageName = stage == "initial" ? "初步" : "最终"
		Modal.confirm({
			title: "拒绝出价",
			content: (
				<Form
					form={rejectForm}
					onFinish={(values) => {
						if (stage == "final") {
							if (!values.returnShippingNum) {
								return message.error("请输入退货物流订运单号")
							} else if (!values.returnShippingNum.startsWith("SF")) {
								return message.error("请输入正确的退货物流运单号")
							}
						}

						Modal.confirm({
							title: "确认拒绝回收?",
							onOk: async () => {
								await API.Breal.SellItem.reject({
									sell_item_id: itemObjectId,
									offer_stage: stage,
									reason: values.reason,
									return_shipping_num: values.returnShippingNum,
								})

								message.success({ content: `${stageName}-已拒绝回收` })
								fetchSellItem()
							},
						})

						setTimeout(() => {
							offerForm.resetFields()
						}, 500)
					}}
				>
					<Form.Item name="reason">
						<Input placeholder="输入拒绝出价的原因" />
					</Form.Item>

					{stage == "final" ? (
						<Form.Item name="returnShippingNum">
							<Input placeholder="输入退货物流订单" />
						</Form.Item>
					) : (
						""
					)}
				</Form>
			),
			onOk: rejectForm.submit,
		})
	}

	return (
		<div className="sell-item-detail">
			<Row gutter={8}>
				<Col md={24} lg={16}>
					<Card
						title="回收物品详情"
						extra={
							<Button
								danger
								onClick={() => {
									Modal.confirm({
										title: "是否确定删除这个回收物品?",
										content: "此操作不可逆，不懂别乱来",
										okText: "确定删除",
										onOk: async () => {
											await API.Breal.SellItem.delete_sell_item({
												sell_item_id: itemObjectId,
											})
											history.goBack()
										},
									})
								}}
							>
								删除
							</Button>
						}
					>
						<div className="img-grid">
							{sellPhotos.map((img, i) => (
								<img
									onClick={() => {
										Modal.info({
											width: "auto",
											className: "img-preview-modal",
											centered: true,
											maskClosable: true,
											icon: null,
											content: (
												<img
													className="preview-img"
													src={_.get(img, "file.url")}
												/>
											),
											okButtonProps: {
												style: { display: "none" },
											},
										})
									}}
									key={_.get(img, "file.file_id", i)}
									className="cover"
									src={qiniuImageView(_.get(img, "file.url"), 300)}
								/>
							))}
						</div>

						<Row gutter={16}>
							<Col lg={24} xl={12}>
								<List>
									<List.Item>
										<Tag>ID</Tag> {_.get(sellItem, "id")}
									</List.Item>
									<List.Item>
										<Tag>数据库ID</Tag> {_.get(sellItem, "object_id")}
									</List.Item>
									<List.Item>
										<Tag>品牌</Tag> {_.get(sellItem, "brand.name", "-")}
									</List.Item>
									<List.Item>
										<Tag>品类</Tag> {_.get(sellItem, "item_type.cn_name", "-")}
									</List.Item>
									<List.Item>
										<Tag>物品名称</Tag> {_.get(sellItem, "name", "-")}
									</List.Item>
									<List.Item>
										<Tag>卖家</Tag>{" "}
										<Link to={"/user/" + _.get(seller, "object_id")}>
											<Avatar src={_.get(seller, "avatar.url")} />
											{_.get(seller, "nick_name", "无昵称用户")}
										</Link>
									</List.Item>
									{authOrder ? (
										<List.Item>
											<Link
												to={"/appraise/order/" + _.get(authOrder, "object_id")}
											>
												<Tag>鉴定订单</Tag> #{_.get(authOrder, "id", "-")}
											</Link>
										</List.Item>
									) : (
										""
									)}
								</List>
							</Col>
							<Col lg={24} xl={12}>
								<List
									dataSource={extraInfo}
									renderItem={(r) => (
										<List.Item>
											<Tag>{_.get(r, "name", "")}</Tag>
											<div>
												{!_.get(r, "value")
													? _.get(r, "boolValue") == true
														? "有"
														: "无"
													: _.get(r, "value")}
											</div>
										</List.Item>
									)}
								></List>
							</Col>
						</Row>
					</Card>
					<Card
						title="初步报价"
						extra={
							!initialOffer ? (
								<div>
									<Button
										type="primary"
										onClick={() => {
											makeOffer({ stage: "initial" })
										}}
										style={{ marginRight: 8 }}
									>
										出具初步报价
									</Button>
									<Button
										danger
										onClick={() => {
											rejectOffer({ stage: "initial" })
										}}
									>
										拒绝出价
									</Button>
								</div>
							) : (
								""
							)
						}
					>
						{initialOffer ? (
							<div>
								<Statistic
									title="初步报价"
									value={
										_.get(initialOffer, "price", 0) <= 0
											? "拒绝回收"
											: _.get(initialOffer, "price", 0) / 100
									}
									prefix="¥"
								/>
								<div>
									{_.get(initialOffer, "accepted")
										? "客户已接受报价"
										: "等待客户反馈"}
								</div>
							</div>
						) : (
							<div>暂未报价</div>
						)}
					</Card>
					<Card
						title="最终报价"
						extra={
							!!toShipping && !finalOffer ? (
								<div>
									<Button
										type="primary"
										onClick={() => {
											makeOffer({ stage: "final" })
										}}
										style={{ marginRight: 8 }}
									>
										出具最终报价
									</Button>

									<Button
										danger
										onClick={() => {
											rejectOffer({ stage: "final" })
										}}
									>
										拒绝出价, 退回
									</Button>
								</div>
							) : (
								""
							)
						}
					>
						{finalOffer ? (
							<div>
								<Statistic
									title="最终报价"
									value={
										_.get(finalOffer, "price", 0) <= 0
											? "拒绝回收"
											: _.get(finalOffer, "price", 0) / 100
									}
									prefix="¥"
								/>
								{_.get(finalOffer, "price", 0) <= 0
									? `${"理由: " + _.get(finalOffer, "reason", "")}`
									: ""}
								<div>
									{_.get(finalOffer, "accepted")
										? "客户已接受报价"
										: "等待客户反馈"}
								</div>
							</div>
						) : (
							<div>暂未报价</div>
						)}
					</Card>

					<Card
						title="成交放款"
						extra={
							<Button
								size="large"
								type="primary"
								disabled={_.get(finalOffer, "accepted") != true || !!payment}
								onClick={() => {
									if (!!payment) return
									Modal.confirm({
										title: "是否确定放款?",
										content: `即将放款 ¥${
											_.get(finalOffer, "price", 0) / 100
										} 到用户的APP钱包，至此回收完成。`,
										okText: "确定放款",
										cancelText: "取消",
										onOk: releaseFund,
									})
								}}
							>
								{!!payment ? (
									"回收已完成"
								) : (
									<span>放款 ¥{_.get(finalOffer, "price", 0) / 100}</span>
								)}
							</Button>
						}
					>
						<div>
							在用户接受最终报价后，点击放款，即可将款项充值到用户的APP钱包内。用户可以选择提现，或者在APP内用钱包余额购物抵扣。
						</div>

						{!!payment ? (
							<List>
								<List.Item>
									<div>钱包转账交易</div>
									<div>{_.get(payment, "title")}</div>
								</List.Item>
								<List.Item>
									<div>交易ID</div>
									<div>{_.get(payment, "object_id")}</div>
								</List.Item>
								<List.Item>
									<div>关联订单</div>
									<div>{_.get(payment, "order")}</div>
								</List.Item>
								<List.Item>
									<div>支付款项</div>
									<div>¥{_.get(payment, "amount", 0) / 100}</div>
								</List.Item>
								<List.Item>
									<div>是否完成</div>
									<div>
										{_.get(payment, "complete") ? "已经完成" : "未完成"}
									</div>
								</List.Item>
								<List.Item>
									<div>放款时间</div>
									<div>
										{moment
											.unix(_.get(payment, "created_at"))
											.format("YYYY-MM-DD HH:mm")}
									</div>
								</List.Item>
							</List>
						) : (
							""
						)}
					</Card>
				</Col>
				<Col md={24} lg={8}>
					<Card title="物流 (寄送到鉴定中心)" className="shipping-card">
						{toShipping ? (
							<div className="shipping-wrapper">
								{_.get(toShipping, "kuaidi_num")}
								<List>
									{_.get(toShipping, "tracking_result.lastResult.data", []).map(
										(d) => (
											<List.Item>
												<div>
													<div style={{ display: "flex" }}>
														<Tag>{_.get(d, "status")}</Tag>
														<div>{_.get(d, "ftime")}</div>
													</div>
													<div style={{ color: "#666", fontSize: "0.9em" }}>
														{_.get(d, "areaName")}
													</div>
													<div>{_.get(d, "context")}</div>
												</div>
											</List.Item>
										)
									)}
								</List>
							</div>
						) : (
							"无"
						)}
					</Card>

					<Card title="物流 (退回给卖家)" className="shipping-card">
						{returnShipping ? (
							<div className="shipping-wrapper">
								{_.get(returnShipping, "kuaidi_num")}
								<List>
									{_.get(
										returnShipping,
										"tracking_result.lastResult.data",
										[]
									).map((d) => (
										<List.Item>
											<div>
												<div style={{ display: "flex" }}>
													<Tag>{_.get(d, "status")}</Tag>
													<div>{_.get(d, "ftime")}</div>
												</div>
												<div style={{ color: "#666", fontSize: "0.9em" }}>
													{_.get(d, "areaName")}
												</div>
												<div>{_.get(d, "context")}</div>
											</div>
										</List.Item>
									))}
								</List>
							</div>
						) : (
							"无"
						)}
					</Card>
				</Col>
			</Row>
		</div>
	)
}

export default SellItemDetail
