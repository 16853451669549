import {
	Form,
	Tabs,
	List,
	Button,
	Typography,
	Spin,
	Popconfirm,
	Input,
	Select,
	message,
	Card,
	Switch,
	InputNumber,
	Modal,
	Upload,
} from "antd"
import _ from "lodash"
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { LoadingOutlined } from "@ant-design/icons"
import "./Settings.less"
import API from "../../api/api"
import globalSlice from "../../redux/slices/global"
import IconFont from "../../components/IconFont/IconFont"
import { useForm } from "antd/lib/form/util"
import AdminPaygrade from "../../components/AdminPaygrade/AdminPaygrade"
import AspectImage from "../../components/AspectImage/AspectImage"
import AV from "leancloud-storage/live-query"

const { TabPane } = Tabs
const { Paragraph } = Typography

const SliderEditor = ({ slider, onSave }) => {
	const [editSlider, setEditSlider] = useState({})

	const saveSlider = async (params) => {
		if (parseInt(params.sort).toString() != params.sort) {
			return message.error("排序必须为正数数字")
		}
		params.sort = parseInt(params.sort)
		try {
			await API.Shop.Common.save_slider(params)
			message.success("保存成功")
		} catch (error) {}
		onSave()
	}

	const deleteSlider = () => {
		Modal.confirm({
			title: "删除头图?",
			content: "ID: " + _.get(editSlider, "objectId"),
			onOk: async () => {
				try {
					await API.Shop.Common.delete_slider({
						objectId: _.get(editSlider, "objectId"),
					})
				} catch (error) {}
				message.success("删除成功")
				onSave()
			},
		})
	}

	const [form] = Form.useForm()

	useEffect(() => {
		setEditSlider(slider)
	}, [slider])

	useEffect(() => {
		form.resetFields()
	}, [editSlider])

	const makeLivePlayerRoomUrl = (roomId) =>
		`plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin?room_id=${roomId}`

	const makeWebUrl = (url) => `/pages/webview/webview?src=${url}`

	const makeHtmlContentUrl = (contentId) =>
		`/pages/webview/webview?src=https://cloud.ilux.ai/htmlcontent/${contentId}`

	let urlParams = new URLSearchParams(
		(_.get(slider, "url") || "").split("?")[1]
	)
	console.log(urlParams.get("room_id"))
	return (
		<Card
			cover={
				<AspectImage
					heightRatio={(9 / 16) * 100 + "%"}
					src={_.get(editSlider, "image_url", "")}
				/>
			}
		>
			<Form form={form} initialValues={editSlider} onFinish={saveSlider}>
				<Form.Item name="objectId" style={{ display: "none" }}>
					<Input disabled hidden />
				</Form.Item>
				<Form.Item name="image_file_id" style={{ display: "none" }}>
					<Input></Input>
				</Form.Item>

				<Form.Item>
					<div>
						<Upload
							customRequest={async (options) => {
								const { onSuccess, onError, file, action, onProgress } = options
								let avfile = new AV.File("shop_slider_image.png", file)
								try {
									const res = await avfile.save()
									setEditSlider({
										...editSlider,
										image_url: res.url(),
										image_file_id: res.id,
									})
									onSuccess(null, file)
								} catch (error) {
									onError(error)
								}
							}}
						>
							<Button>选择图片</Button>
						</Upload>
					</div>
				</Form.Item>

				<Form.Item name="url" label="跳转链接" style={{ marginBottom: 0 }}>
					<Input placeholder="跳转链接" disabled />
				</Form.Item>

				<Form.Item>
					<div
						style={{
							display: "flex",
							justifyContent: "flex-end",
							width: "100%",
						}}
					>
						<Button
							style={{ marginRight: "4px" }}
							size="small"
							onClick={() => {
								setEditSlider({
									...editSlider,
									url: "",
								})
							}}
						>
							清除
						</Button>
						<Button
							style={{ marginRight: "4px" }}
							size="small"
							onClick={() => {
								let url = prompt("输入网页连接")
								if (!url) {
									return
								}
								setEditSlider({
									...editSlider,
									url: makeWebUrl(url),
								})
							}}
						>
							+ 网页链接
						</Button>

						<Button
							style={{ marginRight: "4px" }}
							size="small"
							onClick={() => {
								let contentId = prompt("Content ID")
								if (!contentId) {
									return
								}
								setEditSlider({
									...editSlider,
									url: makeHtmlContentUrl(contentId),
								})
							}}
						>
							+ HTML内容
						</Button>

						<Button
							size="small"
							onClick={() => {
								let room_id = prompt("输入房间号码")
								if (!room_id) {
									return
								}
								setEditSlider({
									...editSlider,
									url: makeLivePlayerRoomUrl(room_id),
								})
							}}
						>
							+ 直播链接
						</Button>
					</div>
				</Form.Item>

				<Form.Item name="sort" label="排序">
					<Input placeholder="按数字顺序排列，1、2、3、4等" />
				</Form.Item>

				<div className="btn-wrapper">
					<Button type="primary" danger onClick={deleteSlider}>
						删除
					</Button>
					<Button
						onClick={() => {
							setEditSlider(slider)
							form.resetFields()
						}}
					>
						重置
					</Button>
					<Button type="primary" htmlType="submit">
						保存
					</Button>
				</div>
			</Form>
		</Card>
	)
}

const SliderSettings = () => {
	const [sliders, setSliders] = useState([])

	useEffect(() => {
		getSliders()
	}, [])

	const getSliders = async () => {
		try {
			const res = await API.Shop.Common.get_sliders()
			setSliders(res)
		} catch (error) {}
	}

	const createNew = () => {
		let newSliders = [
			{
				objectId: null,
				image: null,
				url: null,
				sort: null,
			},
			...sliders,
		]
		console.log("newSliders", newSliders)
		setSliders(newSliders)
	}

	return (
		<>
			<div style={{ marginBottom: 8 }}>
				<Button onClick={createNew}>+ 新建</Button>
			</div>
			<div className="slider-settings">
				{sliders.map((slider) => {
					return (
						<SliderEditor
							slider={slider}
							onSave={() => {
								getSliders()
							}}
						/>
					)
				})}
			</div>
		</>
	)
}

const ShopCategoySettings = () => {
	const dispatch = useDispatch()

	const [isAddingCategory, setIsAddingCategory] = useState(false)
	const [editingCatId, setEditingCatId] = useState("")
	const shopCategories =
		useSelector((state) => _.get(state, "global.commonData.shop_categories")) ||
		[]

	const [form] = Form.useForm()
	const refresh = async () => {
		const res = await API.Admin.Common.get_common_data()
		dispatch(globalSlice.actions.setCommonData(res))
	}

	const changeCategoryName = async (name: string, objectId: string) => {
		try {
			setEditingCatId(objectId)
			const res = await API.Shop.Category.change_cateogry_name({
				name: name,
				objectId: objectId,
			})
			await refresh()
		} catch (error) {}
		setEditingCatId("")
	}

	const deleteCategory = async (objectId: string) => {
		try {
			setEditingCatId(objectId)
			const res = await API.Shop.Category.delete_category({
				objectId: objectId,
			})
			await refresh()
		} catch (error) {}
		setEditingCatId("")
	}

	const addCategory = async (params: { name: string; code: string }) => {
		try {
			setIsAddingCategory(true)
			const res = await API.Shop.Category.add_cateogry(params)
			await refresh()
		} catch (error) {}
		setIsAddingCategory(false)
		form.resetFields()
	}

	return (
		<div className="shop-categories-settings-wrapper">
			<Form
				form={form}
				onFinish={addCategory}
				layout="inline"
				className="add-cat-form"
			>
				<Form.Item name="name" label="品类名称">
					<Input placeholder="新增品类名称" />
				</Form.Item>
				<Form.Item name="code" label="品类代号">
					<Input placeholder="品类代号，请使用全小写" />
				</Form.Item>
				<Button type="primary" loading={isAddingCategory} htmlType="submit">
					确定添加
				</Button>
			</Form>
			<List
				className="list"
				bordered
				dataSource={shopCategories}
				renderItem={(cat) => (
					<List.Item
						extra={
							<Popconfirm
								title="确定删除品类?"
								onConfirm={() => {
									deleteCategory(_.get(cat, "objectId"))
								}}
							>
								<Button loading={editingCatId == _.get(cat, "objectId")}>
									<IconFont name="shanchu" />
								</Button>
							</Popconfirm>
						}
					>
						<List.Item.Meta
							title={
								<>
									<Paragraph
										editable={{
											onChange: (val) => {
												changeCategoryName(val, _.get(cat, "objectId"))
											},
										}}
									>
										<Spin
											spinning={editingCatId == _.get(cat, "objectId")}
											size="small"
											indicator={
												<LoadingOutlined style={{ fontSize: 16 }} spin />
											}
											style={{ marginRight: 8 }}
										/>
										{_.get(cat, "name")}
									</Paragraph>
								</>
							}
							description={_.get(cat, "code")}
						></List.Item.Meta>
					</List.Item>
				)}
			/>
		</div>
	)
}

const PhotoTagsSettings = () => {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const shopItemPhotoTags =
		useSelector((state) =>
			_.get(state, "global.commonData.shop_item_photo_tags")
		) || []

	const setTags = async (tags) => {
		try {
			setLoading(true)
			const res = await API.Shop.Setting.set_shop_item_photo_tags(tags)
			await refresh()
			message.success("保存成功")
		} catch (error) {}
		setLoading(false)
	}

	const refresh = async () => {
		const res = await API.Admin.Common.get_common_data()
		dispatch(globalSlice.actions.setCommonData(res))
	}

	return (
		<Card>
			<Form
				initialValues={{
					tags: shopItemPhotoTags,
				}}
				onFinish={setTags}
			>
				<Form.Item name="tags">
					<Select mode="tags" loading={loading}>
						{shopItemPhotoTags.map((tag: string) => (
							<Select.Option key={tag} value={tag}>
								{tag}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Button type="primary" htmlType="submit" loading={loading}>
					保存
				</Button>
			</Form>
		</Card>
	)
}

const DefaultSenderAddressSettings = () => {
	const [loading, setLoading] = useState(false)
	const [addressObject, setAddressObject] = useState()
	const [form] = Form.useForm()
	useEffect(() => {
		refresh()
	}, [])

	useEffect(() => {
		if (addressObject) {
			form.setFieldsValue(addressObject)
		}
	}, [addressObject])

	const refresh = async () => {
		try {
			setLoading(true)
			const res = await API.Shop.Setting.get_default_sender_address()
			setAddressObject(res)
		} catch (error) {}
		setLoading(false)
	}

	const saveAddress = async (params) => {
		try {
			setLoading(true)
			const res = await API.Shop.Setting.set_default_sender_address(params)
			setAddressObject(res)
			message.success("保存成功")
		} catch (error) {}
		setLoading(false)
	}

	return (
		<Card loading={loading}>
			<Form form={form} onFinish={saveAddress} layout="vertical">
				<Form.Item name="name" label="收/寄件人名称">
					<Input />
				</Form.Item>
				<Form.Item name="mobile" label="手机号">
					<Input />
				</Form.Item>
				<Form.Item name="province" label="省份">
					<Input />
				</Form.Item>
				<Form.Item name="city" label="城市">
					<Input />
				</Form.Item>
				<Form.Item name="area" label="区域">
					<Input />
				</Form.Item>
				<Form.Item name="address" label="街道地址">
					<Input />
				</Form.Item>
				<Button type="primary" htmlType="submit">
					保存
				</Button>
			</Form>
		</Card>
	)
}

const SettingEditForm = ({ setting, onSave }) => {
	const [loading, setLoading] = useState(false)
	const settingTitle = _.get(setting, "title")
	const settingType = _.get(setting, "type")
	const settingName = _.get(setting, "name")
	const [form] = Form.useForm()

	const [editSetting, setEditSetting] = useState(setting)

	let initialValue = null
	let formElement = <></>

	const save = async (values) => {
		if (settingType != "object") {
			values = _.get(values, settingType)
		}
		try {
			setLoading(true)
			const res = await API.Shop.Setting.admin_edit_setting({
				name: settingName,
				data_type: settingType,
				value: values,
			})
			Modal.success({
				title: "设置保存成功",
				onOk: onSave,
			})
		} catch (error) {}
		setLoading(false)
	}

	switch (settingType) {
		case "array":
			initialValue = editSetting
			formElement = (
				<Form.Item name="array">
					<Select mode="tags">
						{_.get(initialValue, "array", []).map((val) => (
							<Select.Option key={val} value={val}>
								{val}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			)
			break
		case "object":
			initialValue = editSetting.object
			formElement = (
				<Form.Item name="object">
					{Object.keys(initialValue).map((k) => (
						<Form.Item key={k} name={k} label={k}>
							<Input
								addonAfter={
									<a
										onClick={() => {
											let newEditSetting = { ...editSetting }
											delete newEditSetting.object[k]
											setEditSetting(newEditSetting)
										}}
									>
										<IconFont name="icon-reduce" size="1.2em" color="red" />
									</a>
								}
							/>
						</Form.Item>
					))}
				</Form.Item>
			)
			break

		case "string":
			initialValue = editSetting
			formElement = (
				<Form.Item name="string">
					<Input />
				</Form.Item>
			)
			break

		case "boolean":
			initialValue = setting
			formElement = (
				<Form.Item name="boolean">
					<Switch></Switch>
				</Form.Item>
			)
			break

		case "number":
			initialValue = editSetting
			formElement = (
				<Form.Item name="number">
					<InputNumber />
				</Form.Item>
			)
			break

		default:
			formElement = <div></div>
	}

	return (
		<Card
			className="setting-edit-form"
			title={`${settingTitle} ${settingName}`}
			size="small"
			style={{ marginBottom: "16px", background: "white" }}
			extra={
				settingType == "object" ? (
					<Button
						onClick={() => {
							const fieldName = prompt("字段Key")
							if (fieldName) {
								let newEditSetting = { ...editSetting }
								newEditSetting.object[fieldName] = ""
								setEditSetting(newEditSetting)
							}
						}}
					>
						+ 增加字段
					</Button>
				) : (
					""
				)
			}
		>
			<Form form={form} initialValues={initialValue} onFinish={save}>
				{formElement}
				<Button htmlType="submit" type="primary">
					保存
				</Button>
			</Form>
		</Card>
	)
}

const GeneralSettings = () => {
	const [loading, setLoading] = useState(false)
	const [settings, setSettings] = useState([])
	useEffect(() => {
		fetchSettings()
	}, [])

	const fetchSettings = async () => {
		setLoading(true)
		try {
			const settings = await API.Shop.Setting.admin_get_settings()
			setSettings(settings)
		} catch (error) {}
		setLoading(false)
	}

	return (
		<>
			{settings
				.filter(
					(setting) =>
						!["defaultSenderAddress", "shopItemPhotoTags"].includes(
							_.get(setting, "name")
						)
				)
				.map((setting) => (
					<SettingEditForm
						key={_.get(setting, "name")}
						setting={setting}
						onSave={fetchSettings}
					/>
				))}
		</>
	)
}

const Settings = () => {
	return (
		<AdminPaygrade requiredLevel={10}>
			<Tabs defaultActiveKey="sliderSettings" className="tabs">
				<TabPane tab="首页头图" key="sliderSettings">
					<SliderSettings />
				</TabPane>
				<TabPane tab="通用设置" key="generalSettings">
					<GeneralSettings />
				</TabPane>
				<TabPane tab="品类" key="categories">
					<ShopCategoySettings />
				</TabPane>
				<TabPane tab="照片部位" key="photoTags">
					<PhotoTagsSettings />
				</TabPane>
				<TabPane tab="收发件地址" key="defaultSenderAddress">
					<DefaultSenderAddressSettings />
				</TabPane>
			</Tabs>
		</AdminPaygrade>
	)
}

export default Settings
