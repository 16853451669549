import {
	configureStore,
	combineReducers,
	getDefaultMiddleware,
} from "@reduxjs/toolkit"
import { logger } from "redux-logger"
import thunk from "redux-thunk"

import globalSlice from "./slices/global"
import shopSlice from "./slices/shop/shop"

const rootReducer = combineReducers({
	global: globalSlice.reducer,
	shop: shopSlice.reducer,
})

const store = configureStore({
	reducer: rootReducer,
	middleware: [thunk],
	devTools: {},
})

export default store
