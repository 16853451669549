import React, { useState, useEffect } from "react"
import "./OperationTools.less"
import {
	Button,
	Tabs,
	List,
	Tooltip,
	Checkbox,
	Modal,
	Table,
	Avatar,
} from "antd"
import API from "../../api/api"
import _ from "lodash"
import { qiniuImageView } from "../../utils/helpers"
import { web_server } from "../../App"
import { Link, useHistory } from "react-router-dom"
import { ColumnsType } from "antd/lib/table"
import moment from "moment"
const { TabPane } = Tabs

const ShareCardMaker = () => {
	const [loading, setLoading] = useState(false)
	const [showcaseItems, setShowcaseItems] = useState([])

	const [isDownloading, setIsDownloading] = useState(false)
	const [downloadProgress, setDownloadProgress] = useState({
		total: 0,
		current: 0,
	})
	let batchItems = showcaseItems.filter((item) => item.checked)

	useEffect(() => {
		fetchData()
	}, [])

	const fetchData = async () => {
		setLoading(true)
		try {
			const res = await API.Shop.Item.get_shop_showcase_items({
				page: 0,
				limit: 1000,
			})
			setShowcaseItems(res.items)
		} catch (error) {}

		setLoading(false)
	}

	const batchMakeCard = async () => {
		setIsDownloading(true)

		for (let index = 0; index < batchItems.length; index++) {
			setDownloadProgress({
				total: batchItems.length,
				current: index + 1,
			})
			const item = batchItems[index]
			const itemId = item.objectId
			const itemNumId = _.get(item, "id")
			const itemName = _.get(item, "name")

			let defer: {
				resolve?: Function
				reject?: Function
			} = {}
			let p = new Promise((resolve, reject) => {
				defer.resolve = resolve
				defer.reject = reject
			})

			let cardUrl = `${web_server}/shop/render_share_card/${itemId}`
			console.log("loading", cardUrl)
			try {
				let req = new Request(cardUrl)
				const res = await fetch(req, {
					method: "GET",
					mode: "cors",
					cache: "reload",
				})
				const blob = await res.blob()
				const url = URL.createObjectURL(blob)
				let a = document.createElement("a")
				a.download = `${itemNumId}-${itemName}.jpg`
				a.href = url
				document.body.appendChild(a)
				a.click()
				a.remove()
				console.log(res)
				defer.resolve()
			} catch (error) {
				defer.reject(error)
			}

			await p
		}
		setIsDownloading(false)
	}

	const toggleSelectAll = () => {
		let items = [...showcaseItems]
		if (!items.every((item) => item.checked)) {
			items.forEach((item) => {
				item.checked = true
			})
		} else {
			items.forEach((item) => {
				item.checked = false
			})
		}
		setShowcaseItems(items)
	}

	return (
		<>
			<Modal
				title="批量生成分享卡片"
				okText={isDownloading ? "停止" : "完成"}
				onCancel={() => {
					setIsDownloading(false)
					window.location.reload()
				}}
				onOk={() => {
					setIsDownloading(false)
					window.location.reload()
				}}
				visible={isDownloading}
			>
				正在批量下载... {downloadProgress.current}/{downloadProgress.total}
			</Modal>

			<div className="card-maker-btn-wrapper">
				<Button onClick={toggleSelectAll}>
					{!showcaseItems.every((item) => item.checked) ? "全选" : "取消全选"}
				</Button>

				<Tooltip title={!batchItems.length ? "请选择要批量生成卡片的商品" : ""}>
					<Button
						type="primary"
						onClick={batchMakeCard}
						disabled={!batchItems.length}
					>
						批量生成
					</Button>
				</Tooltip>
			</div>
			<div className="share-card-maker">
				<List
					// header={"ojbk"}
					loading={loading}
					style={{ background: "white" }}
					size="small"
					dataSource={showcaseItems}
					renderItem={(item, i) => (
						<List.Item>
							<Checkbox
								className="check-box"
								checked={_.get(item, "checked", false)}
								onChange={(e) => {
									let newShowcaseItems = [...showcaseItems]
									newShowcaseItems[i].checked = e.target.checked
									setShowcaseItems(newShowcaseItems)
								}}
							>
								<Tooltip
									title={
										<img src={qiniuImageView(_.get(item, "cover_url"), 240)} />
									}
								>
									<img
										src={qiniuImageView(_.get(item, "cover_url"), 32)}
										className="cover"
									/>
								</Tooltip>
								{_.get(item, "name")}
							</Checkbox>
						</List.Item>
					)}
				></List>
			</div>
		</>
	)
}

const ShopFavs = () => {
	const history = useHistory()
	const [loading, setLoading] = useState(false)
	const [favs, setFavs] = useState([])
	useEffect(() => {
		fetchData()
	}, [])
	const fetchData = async () => {
		setLoading(true)
		try {
			const res = await API.Shop.Common.recent_favs()
			setFavs(res)
		} catch (error) {}
		setLoading(false)
	}

	const columns: ColumnsType = [
		{
			key: "user",
			title: "用户",
			align: "left",
			render: (r, i) => {
				return (
					<span>
						<span className="user-wrapper">
							<Tooltip
								title={
									<a href={_.get(r, "user.avatar")} target="_blank">
										<img
											src={qiniuImageView(_.get(r, "user.avatar.url"), 200)}
											className="avatar-large"
										/>
									</a>
								}
								placement="left"
							>
								<Avatar
									src={qiniuImageView(_.get(r, "user.avatar.url"), 100)}
									style={{ marginRight: 4 }}
								/>
							</Tooltip>
						</span>
						<Link to={`/user/${_.get(r, "user.objectId", "")}`} onClick={e=>{
							e.stopPropagation()
						}}>
							{_.get(r, "user.nickName")}
						</Link>
					</span>
				)
			},
		},
		{
			key: "cover",
			title: "封面",
			width: 88,
			align: "center",
			render: (r, i) => {
				return (
					<div className="cover-wrapper">
						<Tooltip
							title={
								<a href={_.get(r, "shopItem.cover_url")} target="_blank">
									<img
										src={qiniuImageView(_.get(r, "shopItem.cover_url"), 400)}
										className="pc cover-large"
									/>
								</a>
							}
							placement="right"
						>
							<img
								src={qiniuImageView(_.get(r, "shopItem.cover_url"), 100)}
								className="pc cover"
							/>
						</Tooltip>
					</div>
				)
			},
		},
		{
			key: "shopItem",
			title: "关注商品",
			render: (r, i) => {
				return (
					<span>
						<div className="pc id">商品ID: {_.get(r, "shopItem.id")}</div>
						<div className="pc brand">
							{_.get(r, "shopItem.brand.name")}
							{_.get(r, "shopItem.category.name")}
						</div>
						<div>{_.get(r, "shopItem.name")}</div>
					</span>
				)
			},
		},

		{
			title: "关注时间",
			align: "right",
			dataIndex: "createdAt",
			render: (r) => moment.unix(r).fromNow(),
		},
	]

	return (
		<Table
			loading={loading}
			className="shop-favs-table"
			dataSource={favs}
			columns={columns}
			pagination={{
				total: favs.length,
				pageSize: 100,
			}}
			rowKey={(r) => _.get(r, "objectId")}
			onRow={(r, i) => ({
				onClick: () => {
					history.push(`/shop/item/${_.get(r, "shopItem.id")}`)
				},
			})}
		/>
	)
}

const OperationTools = () => {
	const [activeKey, setActiveKey] = useState("shareCardMaker")

	return (
		<Tabs
			activeKey={activeKey}
			onChange={setActiveKey}
			className="operation-tools-tabs"
		>
			<TabPane key="shareCardMaker" tab="卡片生成器">
				<ShareCardMaker />
			</TabPane>
			<TabPane key="shopFavs" tab="最近被关注商品">
				<ShopFavs />
			</TabPane>
		</Tabs>
	)
}

export default OperationTools
