import React, { useEffect } from "react"
import { Button, ConfigProvider } from "antd"
import { Provider, useDispatch, useSelector } from "react-redux"
import "./App.less"
import MainFrame from "./components/MainFrame/MainFrame"
import IconFont from "./components/IconFont/IconFont"
import store from "./redux/store"
import { Switch, BrowserRouter, Route } from "react-router-dom"
import routes from "./routes"
import AV from "leancloud-storage/live-query"
import { Realtime, Event } from "leancloud-realtime"
import _ from "lodash"
import globalSlice from "./redux/slices/global"
import { AVDeepJsonify, Logout } from "./utils/helpers"
import LoginBox from "./components/LoginBox/LoginBox"
import zh_CN from "antd/es/locale/zh_CN"
import API from "./api/api"
import "./models/chat_client"

// const DEVELOPMENT_MODE = process.env.NODE_ENV == "development"
const DEVELOPMENT_MODE = false
const dev_server = `${window.location.protocol}//${window.location.hostname}:3000`
const stage_server = "https://stg-ilux.leanapp.cn/"
const engine_server = "https://cloudapi.ilux.ai"
const api_server = "https://cloudapi.ilux.ai"
export const web_server = DEVELOPMENT_MODE
	? dev_server
	: "https://cloud.ilux.ai"
const server = DEVELOPMENT_MODE ? dev_server : engine_server

AV.init({
	appId: "WIT5O5deQWcL8B1jo4yidtVy-gzGzoHsz",
	appKey: "U9WPlrBSvq0mephlHPkK1Oku",
	serverURLs: {
		api: api_server,
		engine: server,
		stats: server,
		push: server,
		rtm: server,
	},
})

// export const realtime = new Realtime({
// 	appId: "WIT5O5deQWcL8B1jo4yidtVy-gzGzoHsz",
// 	appKey: "U9WPlrBSvq0mephlHPkK1Oku",
// 	server: engine_server,
// })

function App() {
	const dispatch = useDispatch()
	const currentUser = useSelector((state) => _.get(state, "global.user"))
	const commonData = useSelector((state) => _.get(state, "global.commonData"))

	const syncUserToRedux = (user) => {
		if (!!user && !!user.get("ALevel")) {
			dispatch(globalSlice.actions.setUser(AVDeepJsonify(user)))
		} else {
			Logout()
		}
	}

	useEffect(() => {
		let user = AV.User.current()
		syncUserToRedux(user)
		if (user) {
			user.fetch().then((res) => {
				syncUserToRedux(user)
			})
		}

		if (!commonData) {
			API.Admin.Common.get_common_data().then((res) =>
				dispatch(globalSlice.actions.setCommonData(res))
			)
		}
	}, [])

	if (!currentUser) {
		return <LoginBox />
	}

	return (
		<ConfigProvider locale={zh_CN}>
			<BrowserRouter>
				<MainFrame>
					<Switch>
						{routes.map((r) => (
							<Route exact path={r.path} component={r.component} key={r.key} />
						))}
					</Switch>
				</MainFrame>
			</BrowserRouter>
		</ConfigProvider>
	)
}

export default App
