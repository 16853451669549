import AV from "leancloud-storage/live-query"
import { Modal } from "antd"
import { ProductFilters } from "../components/FilterTools/FilterTools"
import internal from "stream"

const DEVELOPMENT_MODE = process.env.NODE_ENV == "development"

const CloudRpc = async (funcName: string, params?: Object) => {
	try {
		const res = await AV.Cloud.rpc(funcName, params)
		if (DEVELOPMENT_MODE) {
			console.log(`${funcName} res`, res)
		}
		return res
	} catch (err) {
		console.error(err)
		Modal.error({
			centered: true,
			title: "出错了",
			content: `错误码: ${err.code} - ${err.rawMessage}`,
		})
		throw err
	}
}

export interface ShippingAddress {
	name?: string
	mobile?: string
	province?: string
	city?: string
	area?: string
	address?: string
	[key: string]: any
}

enum Platform {
	weapp,
	swan,
}

class API {
	static Admin = class {
		static Common = class {
			// get_common_data
			static get_common_data = () => CloudRpc("v3:admin:common:get_common_data")

			// get_html_contents
			static get_html_contents = () =>
				CloudRpc("v3:admin:common:get_html_contents")

			// save_html_content
			static save_html_content = (params: {
				objectId?: string
				title?: string
				contentId?: string
				content?: string
			}) => CloudRpc("v3:admin:common:save_html_content", params)

			// delete_html_content
			static delete_html_content = (params: { objectId?: string }) =>
				CloudRpc("v3:admin:common:delete_html_content", params)
		}

		static UserManagement = class {
			// get users
			static get_users = (params: {
				page: number
				limit?: number
				filters: { nickName?: string; objectId?: string }
			}) => CloudRpc("v3:admin:user_management:get_users", params)

			// fetch_user users
			static fetch_user = (params: { user_id: string }) =>
				CloudRpc("v3:admin:user_management:fetch_user", params)

			// search_users
			static search_users = (params: { keyword: string }) =>
				CloudRpc("v3:admin:user_management:search_users", params)
		}

		static Brand = class {
			// save_brand
			static save_brand = (params: { objectId: string; [key: string]: any }) =>
				CloudRpc("v3:admin:common:save_brand", params)
			// search_users
			static delete_brand = (params: { objectId: string }) =>
				CloudRpc("v3:admin:common:brand:delete_brand", params)
		}

		static Money = class {
			// get_wx_business_pay_recipients
			static get_wx_business_pay_recipients = () =>
				CloudRpc("v3:admin:money:get_wx_business_pay_recipients")

			// wx_businesss_pay_to_shop_user
			static wx_businesss_pay_to_shop_user = (params: {
				amount_in_cents: number
				user_id: string
				description: string
			}) => CloudRpc("v3:admin:money:wx_businesss_pay_to_shop_user", params)
		}
	}

	static Shop = class {
		static Common = class {
			static render_share_card = (params: { shop_item_id: string }) =>
				CloudRpc("v3:shop:common:render_share_card", params)

			static recent_favs = (params: { user_id?: string } = {}) =>
				CloudRpc("v3:shop:common:recent_favs", params)

			static get_sliders = () => CloudRpc("v3:shop:common:get_sliders")

			static save_slider = (params: {
				objectId?: string
				sort?: number
				image_file_id?: string
			}) => CloudRpc("v3:shop:common:save_slider", params)

			static delete_slider = (params: { objectId?: string }) =>
				CloudRpc("v3:shop:common:delete_slider", params)
		}

		static Setting = class {
			// set_shop_item_photo_tags
			static set_shop_item_photo_tags = (params: { tags: string[] }) =>
				CloudRpc("v3:shop:setting:set_shop_item_photo_tags", params)

			// set_default_sender_address
			static get_default_sender_address = () =>
				CloudRpc("v3:shop:setting:get_default_sender_address")

			// set_default_sender_address
			static set_default_sender_address = (params: {
				name: string
				mobile: string
				province: string
				city: string
				area: string
				address: string
			}) => CloudRpc("v3:shop:setting:set_default_sender_address", params)

			// admin_get_settings
			static admin_get_settings = () =>
				CloudRpc("v3:shop:setting:admin_get_settings")

			// admin_edit_setting
			static admin_edit_setting = (params: {
				name: string
				data_type: string
				value: any
			}) => CloudRpc("v3:shop:setting:admin_edit_setting", params)
		}

		static Category = class {
			// get_common_data
			static change_cateogry_name = (params: {
				name: string
				objectId: string
			}) => CloudRpc("v3:shop:category:change_cateogry_name", params)

			static add_cateogry = (params: { name: string; code: string }) =>
				CloudRpc("v3:shop:category:add_cateogry", params)

			static delete_category = (params: { objectId: string }) =>
				CloudRpc("v3:shop:category:delete_category", params)
		}

		static Item = class {
			// fetch_item
			static fetch_item = (params: { num_id?: number; object_id?: string }) =>
				CloudRpc("v3:shop:item:fetch_item", params)

			// get_shop_showcase_items
			static get_shop_showcase_items = (params: {
				page: number
				limit: number
				filters?: ProductFilters
			}) => CloudRpc("v3:shop:item:get_shop_showcase_items", params)

			// get_shop_items
			static get_shop_items = (params: {
				page: number
				limit: number
				archived?: boolean
				filters: ProductFilters
			}) => CloudRpc("v3:shop:item:get_shop_items", params)

			// create_shop_item
			static create_shop_item = (params: {
				name: string
				brand_id: string
				seller_id: string
			}) => CloudRpc("v3:shop:item:create_shop_item", params)

			// edit_shop_item
			static edit_shop_item = (params: {
				object_id: string
				[propName: string]: any
			}) => CloudRpc("v3:shop:item:edit_shop_item", params)

			// delete_shop_item
			static delete_shop_item = (params: { object_id: string }) =>
				CloudRpc("v3:shop:item:delete_shop_item", params)

			// delete_shop_item
			static set_shop_item_sold = (params: {
				object_id: string
				is_sold: boolean
			}) => CloudRpc("v3:shop:item:set_shop_item_sold", params)

			// publish_shop_item
			static publish_shop_item = (params: { object_id: string }) =>
				CloudRpc("v3:shop:item:publish_shop_item", params)

			// unpublish_shop_item
			static unpublish_shop_item = (params: { object_id: string }) =>
				CloudRpc("v3:shop:item:unpublish_shop_item", params)

			// archive_shop_item
			static archive_shop_item = (params: { object_id: string }) =>
				CloudRpc("v3:shop:item:archive_shop_item", params)

			// unarchive_shop_item
			static unarchive_shop_item = (params: { object_id: string }) =>
				CloudRpc("v3:shop:item:unarchive_shop_item", params)

			// delete_shop_item_photo
			static edit_shop_item_photo = (params: {
				object_id: string
				[key: string]: any
			}) => CloudRpc("v3:shop:item:edit_shop_item_photo", params)

			// get_shop_item_photos
			static get_shop_item_photos = (params: { shop_item_id: string }) =>
				CloudRpc("v3:shop:item:get_shop_item_photos", params)

			// delete_shop_item_photo
			static delete_shop_item_photo = (params: { object_id: string }) =>
				CloudRpc("v3:shop:item:delete_shop_item_photo", params)

			// reorder_showcase_items
			static reorder_showcase_items = (
				params: {
					object_id: string
					sort: number
				}[]
			) =>
				CloudRpc("v3:shop:item:reorder_showcase_items", {
					sort_orders: params,
				})

			// reorder_shop_item_photos
			static reorder_shop_item_photos = (
				params: {
					object_id: string
					sort: number
				}[]
			) =>
				CloudRpc("v3:shop:item:reorder_shop_item_photos", {
					sort_orders: params,
				})
		}

		static Order = class {
			// get_orders
			static fetch_order = (params: { num_id?: number; object_id?: string }) =>
				CloudRpc("v3:shop:order:fetch_order", params)

			// get_orders
			static get_orders = (params: {
				page?: number
				limit?: number
				buyer_id?: string
				shop_item_id?: string
				filters?: object
			}) => CloudRpc("v3:shop:order:get_orders", params)

			// place_order
			static place_order = (params: {
				shop_item_id: string
				buyer_shipping_address?: ShippingAddress
				user_id?: string
				final_price?: number
			}) => CloudRpc("v3:shop:order:place_order", params)

			// edit_order_final_price
			static edit_order_final_price = (params: {
				object_id?: string
				num_id?: number
				final_price_in_cents: number
			}) => CloudRpc("v3:shop:order:edit_order_final_price", params)

			// edit_order_buyer_shipping_address
			static edit_order_buyer_shipping_address = (params: {
				object_id?: string
				num_id?: number
				address_params: ShippingAddress
			}) => CloudRpc("v3:shop:order:edit_order_buyer_shipping_address", params)

			// pay_for_order
			static pay_for_order = (params: {
				order_id: string
				platform: Platform
				open_id?: string
				user_id?: string
			}) => CloudRpc("v3:shop:order:pay_for_order", params)

			// refund_shop_order
			static refund_shop_order = (params: {
				order_id: string
				custom_refund_fee_in_cents?: number
			}) => CloudRpc("v3:shop:order:refund_shop_order", params)

			// cancel_order
			static cancel_order = (params: { order_id: string }) =>
				CloudRpc("v3:shop:order:cancel_order", params)

			// get_order_shippings
			static get_order_shippings = (params: { order_id: string | number }) =>
				CloudRpc("v3:shop:order:get_order_shippings", params)

			// set_payment_complete
			static set_payment = (params: {
				shop_item_id: string
				order_id: string
				payment_result: object
				platform: string
			}) => CloudRpc("v3:shop:order:set_payment", params)
		}

		static SellItem = class {
			// fetch_sell_item
			static create_sell_item = (params: {
				name: string
				brand_id: string
				cat_id: string
				seller_id: string
			}) => CloudRpc("v3:shop:sell_item:create_sell_item", params)

			// fetch_sell_item
			static fetch_sell_item = (params: { sell_item_id: string | number }) =>
				CloudRpc("v3:shop:sell_item:fetch_sell_item", params)

			// get_sell_items
			static get_sell_items = (params: {
				page: number
				limit: number
				status_id?: string
			}) => CloudRpc("v3:shop:sell_item:get_sell_items", params)

			// approve_sell_item
			static approve_sell_item = (params: { id: string | number }) =>
				CloudRpc("v3:shop:sell_item:approve_sell_item", params)

			// approve_sell_item
			static reject_sell_item = (params: { id: string | number }) =>
				CloudRpc("v3:shop:sell_item:reject_sell_item", params)

			// convert_sell_item_to_shop_item
			static convert_sell_item_to_shop_item = (params: {
				id: string | number
			}) => CloudRpc("v3:shop:sell_item:convert_sell_item_to_shop_item", params)

			// delete_sell_item
			static delete_sell_item = (params: { id: string | number }) =>
				CloudRpc("v3:shop:sell_item:delete_sell_item", params)
		}
		static Shipping = class {
			// create_shipping_for_order
			static create_shipping_for_order = (params: {
				order_id: string | number
				kuaidiNum?: string
			}) => CloudRpc("v3:shop:shipping:create_shipping_for_order", params)

			// create_shipping_for_order
			static delete_shippping = (params: {
				shipping_object_id: string
				order_id: string
			}) => CloudRpc("v3:shop:shipping:delete_shippping", params)
		}
	}

	static Appraise = class {
		static AdminCP = class {
			static get_orders = (params: {
				page?: number
				limit?: number
				auth_brand_id?: string
				auth_type_id?: string
				user_id?: string
				auth_num_id_s?: number | number[]
			}) => CloudRpc("v3:appraise:admincp:get_orders", params)

			static get_order = (params: {
				order_id?: string
				include_payloads?: boolean
			}) => CloudRpc("v3:appraise:room:get_order", params)

			static get_order_payloads = (params: { order_id: string }) =>
				CloudRpc("v3:appraise:admincp:get_order_payloads", params)

			static delete_order = (params: { order_id: string }) =>
				CloudRpc("v3:appraise:admincp:delete_order", params)

			static refund_order = (params: { order_id: string }) =>
				CloudRpc("v3:appraise:admincp:refund_order", params)

			// direct_refund_order: request refund as appraiser, then approves refund
			static direct_refund_order = async (params: {
				order_id: string
				reason?: string
			}) => {
				try {
					const res = await CloudRpc(
						"v3:appraise:appraiser:request_refund_order",
						{
							order_id: params.order_id,
							reason: params.reason,
						}
					)
					return CloudRpc("v3:appraise:admincp:refund_order", {
						order_id: params.order_id,
					})
				} catch (err) {
					throw err
				}
			}

			static revenue_stats = (params: { start_date: Date; end_date: Date }) =>
				CloudRpc("v3:appraise:admincp:revenue_stats", params)

			static set_auth_brand = (params: {
				order_id: string
				brand_id: string
			}) => CloudRpc("v3:appraise:admincp:set_auth_brand", params)

			static set_auth_plan = (params: { order_id: string; plan_id: string }) =>
				CloudRpc("v3:appraise:admincp:set_auth_plan", params)

			static set_auth_type = (params: { order_id: string; type_id: string }) =>
				CloudRpc("v3:appraise:admincp:set_auth_type", params)
		}
	}

	static CandyWallet = class {
		static get_balance = (params: { user: string }) =>
			CloudRpc("v3:candy:wallet:get_balance", params)

		static get_transactions = (params: { user: string }) =>
			CloudRpc("v3:candy:wallet:get_transactions", params)

		static fill_candy_wallet = (params: { user_id: string; amount: number }) =>
			CloudRpc("v3:candy:wallet:fill_candy_wallet", params)
	}

	static Breal = class {
		static User = class {
			// get users
			static get_users = (params: {
				page: number
				limit?: number
				filters: { nickName?: string; objectId?: string }
			}) => CloudRpc("breal.admin.user.get_users", params)

			// fetch_user users
			static fetch_user = (params: { user_id: string }) =>
				CloudRpc("breal.admin.user.fetch_user", params)

			// search_users
			static search_users = (params: {
				keyword: string
				search_type: "nickname" | "id"
			}) => CloudRpc("breal.admin.user.search_users", params)
		}

		static Wallet = class {
			static getWallet = (params: { wallet_id: string }) =>
				CloudRpc("breal.admin.wallet.get_wallet", params)

			static getWalletByUserId = (params: { user_id: string }) =>
				CloudRpc("breal.admin.wallet.get_wallet_by_user_id", params)

			static getTransactions = (params: {
				wallet_id: string
				skip: number
				limit: number
			}) => CloudRpc("breal.admin.wallet.get_transactions", params)

			static revertTransaction = (params: { transaction_id: string }) =>
				CloudRpc("breal.admin.wallet.revert_transaction", params)

			static addBalance = (params: {
				wallet_id: string
				amount_in_cents: number
			}) => CloudRpc("breal.admin.wallet.add_balance", params)

			static approveWithdraw = (params: { transaction_id: string }) =>
				CloudRpc("breal.admin.wallet.approve_withdraw", params)

			static reset = (params: { wallet_id: string }) =>
				CloudRpc("breal.admin.wallet.reset", params)
		}

		static Authenticate = class {
			static fetchOrder = (params: { object_id: string }) =>
				CloudRpc("breal.authenticate.fetch_order", params)
		}

		static SellItem = class {
			static fetchSellItem = (params: { sell_item_id: string }) =>
				CloudRpc("breal.admin.sell_item.fetch_sell_item", params)

			// fetch_sell_photos
			static fetchSellPhotos = (params: { sell_item_id: string }) =>
				CloudRpc("breal.admin.sell_item.fetch_sell_photos", params)

			static getSellItems = (params: {
				page: number
				limit: number
				pending_offer: boolean
			}) => CloudRpc("breal.admin.sell_item.get_sell_items", params)

			static makeOffer = (params: {
				sell_item_id: string
				price_in_cents: number
				offer_stage: "initial" | "final"
			}) => CloudRpc("breal.admin.sell_item.make_offer", params)

			static reject = (params: {
				sell_item_id: string
				offer_stage: "initial" | "final"
				reason: string
				return_shipping_num: string
			}) =>
				CloudRpc("breal.admin.sell_item.make_offer", {
					...params,
					price_in_cents: 0,
				})

			static close_deal = (params: { sell_item_id: string }) =>
				CloudRpc("breal.admin.sell_item.close_deal", params)

			static delete_sell_item = (params: { sell_item_id: string }) =>
				CloudRpc("breal.admin.sell_item.delete_sell_item", params)
		}

		static Shop = class {
			static fetchShopItem = async (params: { shop_item_id: string }) => {
				return CloudRpc("breal.shop.fetch_shop_item", params)
			}

			static fetchShopOrder = async (params: { order_id: string }) => {
				return CloudRpc("breal.shop.fetch_order", params)
			}

			static refundOrder = async (params: {
				shop_order_id: string
				refund_reason: string
			}) => {
				return CloudRpc("breal.admin.shop.refund_order", params)
			}
		}

		static pushNotification = class {
			static tpnsPush = async (params: {
				user_ids: string[]
				title: string
				content: string
				custom_content?: object
				show_in_app?: boolean
				environment: "dev" | "product"
			}) => CloudRpc("breal.push_notification.tpns_push", params)
		}

		static Settings = class {
			static getSettings = async () =>
				CloudRpc("breal.admin.settings.get_settings")
			static changeSetting = async (params: { object_id: string; value: any }) =>
				CloudRpc("breal.admin.settings.change_setting", params)
		}
	}
}

export default API
