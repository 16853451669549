import React, { useState, useEffect } from "react"
import "./Money.less"
import {
	Select,
	Form,
	Input,
	Modal,
	Button,
	message,
	Card,
	List,
	Typography,
} from "antd"
import API from "../../api/api"
import _ from "lodash"

const WXBusinessPay = () => {
	const [loading, setLoading] = useState(false)
	const [recipients, setRecipients] = useState([])

	useEffect(() => {
		fetchRecipients()
	}, [])

	const fetchRecipients = async () => {
		try {
			setLoading(true)
			const res = await API.Admin.Money.get_wx_business_pay_recipients()
			setRecipients(res)
		} catch (error) {}
		setLoading(false)
	}

	const submit = (values) => {
		if (!values.user_id) {
			return message.error("请选择收款人")
		}
		if (
			values.amount_in_cents != parseFloat(values.amount_in_cents).toString()
		) {
			return message.error("金额必须为数字")
		}

		let secret_code = prompt("Secret code")
		if (secret_code != "imveryterry") {
			return message.error("secret错误")
		}

		values.amount_in_cents = parseFloat(values.amount_in_cents) * 100
		let amount_in_yuan = values.amount_in_cents / 100

		let recipient = recipients.find(
			(r) => _.get(r, "recipient.objectId") == values.user_id
		)

		Modal.confirm({
			title: "请确认发送金额信息",
			content: (
				<div>
					<div>
						将向 [{_.get(recipient, "recipient.nickName")}] 支付{" "}
						<span style={{ fontWeight: "bold" }}>¥{amount_in_yuan}元</span>
					</div>
					<div>描述: {values.description}</div>
				</div>
			),
			onOk: async () => {
				try {
					const res = await API.Admin.Money.wx_businesss_pay_to_shop_user({
						amount_in_cents: values.amount_in_cents,
						user_id: values.user_id,
						description: values.description,
					})
					console.log("res", res)
					Modal.success({
						title: "支付成功",
						onOk: () => {
							window.location.reload()
						},
					})
				} catch (error) {}
			},
		})
	}

	return (
		<Card title="企业支付到零钱" loading={loading}>
			<Form layout="vertical" onFinish={submit}>
				<Form.Item name="user_id" label="收款人">
					<Select placeholder="选择收款人">
						{recipients.map((r) => (
							<Select.Option
								key={_.get(r, "recipient.objectId")}
								value={_.get(r, "recipient.objectId")}
							>
								{_.get(r, "recipient.nickName")} (备注: {_.get(r, "note")})
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item
					name="amount_in_cents"
					label="发送金额 (微信限制: 单个人收款人单日最高2万)"
				>
					<Input addonBefore="¥" placeholder="输入金额" />
				</Form.Item>

				<Form.Item name="description" label="描述">
					<Input placeholder="输入描述" />
				</Form.Item>

				<Button type="primary" htmlType="submit">
					支付
				</Button>
			</Form>
		</Card>
	)
}

const Billing = () => {
	const billingPlatforms = [
		{
			name: "LeanCloud",
			url: "https://leancloud.cn/dashboard/bill.html#/bill/general",
			description: "后端服务器/数据库 - 每日成本约¥30",
		},
		{
			name: "阿里云",
			url: "https://usercenter2.aliyun.com/home",
			description: "官网服务器",
		},
		{
			name: "快递100",
			url: "https://poll.kuaidi100.com/manager/page/myinfo/info",
			description: "快递下单及查询平台",
		},
		{
			name: "顺丰月结账户",
			url: "https://v.sf-express.com/",
			description: "顺丰月结账户，Ina & Terry可用微信或手机号登录",
		},
	]

	return (
		<Card title="第三方充值">
			<List
				dataSource={billingPlatforms}
				renderItem={(r) => (
					<List.Item key={r.name}>
						<List.Item.Meta
							style={{ color: "blue !important" }}
							title={
								<Typography.Link href={r.url} target="_blank">
									{r.name}
								</Typography.Link>
							}
							description={r.description}
						/>
					</List.Item>
				)}
			/>
		</Card>
	)
}

const Platforms = () => {
	const platforms = [
		{
			name: "微信商户平台",
			url: "https://pay.weixin.qq.com/",
			description: "微信支付的资金管理",
		},
		{
			name: "微信开放平台",
			url: "https://open.weixin.qq.com/",
			description: "用于将小程序、公众号、应用等关联起来。仅开发需要使用。",
		},
		{
			name: "微信公众平台",
			url: "https://mp.weixin.qq.com/",
			description: "登录公众号或小程序后台",
		},
		{
			name: "百度小程序平台",
			url: "https://smartprogram.baidu.com/developer/index.html",
			description: "登录百度小程序后台",
		},
	]
	return (
		<Card title="相关平台">
			<List
				dataSource={platforms}
				renderItem={(r) => (
					<List.Item key={r.name}>
						<List.Item.Meta
							style={{ color: "blue !important" }}
							title={
								<Typography.Link href={r.url} target="_blank">
									{r.name}
								</Typography.Link>
							}
							description={r.description}
						/>
					</List.Item>
				)}
			/>
		</Card>
	)
}

const Money = () => {
	return (
		<div className="money-grid">
			<WXBusinessPay />
			<Billing />
			<Platforms />
		</div>
	)
}

export default Money
