import React, { useEffect, useState } from "react"
import "./ChatWindow.less"
import {
	Conversation,
	Event,
	IMClient,
	Message,
	PresistentConversation,
	TextMessage,
} from "leancloud-realtime"
import _ from "lodash"
import {
	Affix,
	Avatar,
	Button,
	Card,
	Drawer,
	Input,
	List,
	message,
	Modal,
	Tooltip,
} from "antd"
import AV from "leancloud-storage"
import { conditionParse, qiniuImageView, tryReturn } from "../../utils/helpers"
import API from "../../api/api"
import { useSelector } from "react-redux"
import Leancloud from "../Leancloud/Leancloud"
import { useChat } from "../../models/chat_client"

const ConvInfo = ({
	attr,
}: {
	attr: {
		type?: string
		className?: string
		title?: string
		imageUrl?: string
		itemId?: string
	}
}) => {
	const className = _.get(attr, "className")
	const [obj, setObj]: [object, any] = useState()
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		fetchData()
		return () => {
			setObj()
		}
	}, [attr.itemId])

	const fetchData = async () => {
		if (attr && attr.itemId) {
			if (obj && _.get(obj, "object_id") == attr.itemId) return
			setLoading(true)
			try {
				let res
				if (attr.className == "ShopOrder") {
					res = await API.Breal.Shop.fetchShopOrder({ order_id: attr.itemId })
				} else if (attr.className == "ShopItem") {
					res = await API.Breal.Shop.fetchShopItem({
						shop_item_id: attr.itemId,
					})
				} else if (attr.className == "ShopSellItem") {
					res = await API.Breal.SellItem.fetchSellItem({
						sell_item_id: attr.itemId,
					})
				} else if (attr.className == "AuthOrders") {
					res = await API.Breal.Authenticate.fetchOrder({
						object_id: attr.itemId,
					})
				}
				setObj(res)
			} catch (err) {}
			setLoading(false)
		}
	}

	let actionUrl
	let itemDatas = []
	if (obj) {
		switch (className) {
			case "AuthOrders":
				actionUrl = `/appraise/order/${_.get(obj, "object_id")}`
				itemDatas = [
					{ key: "oid", value: _.get(obj, "objectId") },
					{ key: "ID", value: _.get(obj, "id") },
					{
						key: "品牌",
						value: tryReturn(() => _.get(obj, "auth_brand.name"), ""),
					},
					{
						key: "品类",
						value: tryReturn(() => _.get(obj, "auth_type.cn_name"), ""),
					},
					{
						key: "套餐",
						value: tryReturn(() => _.get(obj, "auth_plan.name"), ""),
					},
					{
						key: "鉴定结果",
						value: _.get(obj, "appraisal.pass", "无"),
					},
				]
				break
			case "ShopItem":
				actionUrl = `/shop/item/${_.get(obj, "id")}`
				itemDatas = [
					{
						key: "",
						value: (
							<img
								style={{ width: "100%", aspectRatio: "1", objectFit: "cover" }}
								src={tryReturn(
									() => qiniuImageView(_.get(obj, "cover.file.url", ""), 300),
									""
								)}
							/>
						),
					},
					{
						key: "",
						value: _.get(obj, "name", ""),
					},
					{ key: "", value: _.get(obj, "description", "") },
					{ key: "oid", value: _.get(obj, "object_id") },
					{ key: "ID", value: _.get(obj, "id") },
					{ key: "品牌", value: tryReturn(() => _.get(obj, "brand.name", "")) },
					{ key: "成色", value: _.get(obj, "condition", "") },
					{ key: "价格", value: "¥" + (_.get(obj, "price") || 0) / 100 },
					{ key: "已售", value: _.get(obj, "sold") ? "是" : "否" },
				]
				break
			case "ShopSellItem":
				actionUrl = `/breal/sell-item-detail/${_.get(obj, "object_id")}`
				itemDatas = [
					{
						key: "",
						value: (
							<img
								style={{ width: "100%", aspectRatio: "1", objectFit: "cover" }}
								src={tryReturn(
									() => qiniuImageView(_.get(obj, "cover.url", ""), 300),
									""
								)}
							/>
						),
					},
					{
						key: "名称",
						value: _.get(obj, "name", ""),
					},
					{ key: "oid", value: _.get(obj, "object_id", "") },
					{ key: "ID", value: _.get(obj, "id", "") },
					{ key: "品牌", value: tryReturn(() => _.get(obj, "brand.name", "")) },
					..._.get(obj, "extra_info", []).map((info, idx) => {
						let value = ""
						let boolValue = _.get(info, "boolValue")
						if (_.get(info, "value") == null) {
							value = boolValue ? "是" : "否"
						} else {
							value = _.get(info, "value", "")
						}
						return { key: _.get(info, "name"), value: value }
					}),
				]
				break
			case "ShopOrder":
				actionUrl = `/shop/order/${_.get(obj, "id")}`
				itemDatas = [
					{
						key: "",
						value: _.get(obj, "name"),
					},
					{ key: "oid", value: _.get(obj, "object_id") },
					{ key: "订单号ID", value: "SON-" + _.get(obj, "id") },
					{
						key: "订单商品",
						value: (
							<a
								href={`/shop/item/${_.get(obj, "shop_item.id")}`}
								target="_blank"
							>
								{_.get(obj, "shop_item.name")}
							</a>
						),
					},
					{
						key: "地址",
						value: (
							<div>
								<div>{_.get(obj, "buyer_shipping_address.name", "")}</div>
								<div>{_.get(obj, "buyer_shipping_address.mobile", "")}</div>
								<div>
									{_.get(obj, "buyer_shipping_address.province", "")}{" "}
									{_.get(obj, "buyer_shipping_address.city", "")}{" "}
									{_.get(obj, "buyer_shipping_address.area", "")}
								</div>
								<div>{_.get(obj, "buyer_shipping_address.address", "")}</div>
							</div>
						),
					},
				]
				break
			default:
				break
		}
	}

	return (
		<Card
			loading={loading}
			title="咨询关于"
			bodyStyle={{ padding: 12 }}
			extra={
				actionUrl ? (
					<Button type="primary" ghost target="_blank" href={actionUrl}>
						详情
					</Button>
				) : (
					""
				)
			}
		>
			<List
				dataSource={itemDatas}
				renderItem={(r) => (
					<List.Item style={{ alignItems: "flex-start" }}>
						{r.key ? (
							<div style={{ marginRight: 16, flexShrink: 0 }}>{r.key}</div>
						) : (
							""
						)}

						{r.value ? <div>{r.value}</div> : ""}
					</List.Item>
				)}
			></List>
		</Card>
	)
}

interface ChatWindowInterface {
	conversation: PresistentConversation
	refreshConversation: Function
}

let prevConv: Conversation

const ChatWindow = ({
	conversation,
	refreshConversation,
}: ChatWindowInterface) => {
	const fetchLimit = 20
	const [lastPushTime, setLastPushTime]: [number, Function] = useState()
	const [dataEnd, setDataEnd] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [members, setMembers]: [(string | Object)[], Function] = useState([])
	const [messages, _setMessages] = useState([])
	const messagesRef = React.useRef(messages)
	const setMessages = (data) => {
		messagesRef.current = data
		_setMessages(data)
	}

	const [showAbout, setShowAbout] = useState(false)

	// const chatClient: IMClient = useSelector((state) =>
	// 	_.get(state, "global.chatClient")
	// )

	const [chatClient, conversations, clientloading] = useChat()

	const agentName: IMClient = useSelector((state) =>
		_.get(state, "global.user.username")
	)

	const conversationAgent = conversation ? conversation.get("attr.agent") : null
	const scrollRef = React.useRef()
	const [inputText, setInputText]: [string, any] = useState("")
	const iAmAgent = !!conversation ? conversationAgent == agentName : false

	const attr = conversation ? conversation.get("attr") || {} : {}

	const newMessageHanlder = (message) => {
		setMessages([...messagesRef.current, message])
		scrollToEnd()
	}

	useEffect(() => {
		setLastPushTime(null)
		setDataEnd(false)
		setMessages([])
		setMembers([])
		if (!!conversation) {
			var members = conversation.members.map((mid) => {
				if (mid.length == 24) {
					var user = AV.Object.createWithoutData("_User", mid)
					user.fetch()
					return user
				} else {
					return mid
				}
			})

			var objectMembers = members.filter(
				(m) => m instanceof AV.Object
			) as AV.Object[]
			AV.Object.fetchAll(objectMembers).then(() => {
				setMembers(members)
			})

			loadMessages()
			conversation.on(Event.MESSAGE, newMessageHanlder)
		}

		return () => {
			try {
				conversation.off(Event.MESSAGE, newMessageHanlder)
			} catch (err) {}
		}
	}, [conversation])

	const scrollToEnd = () => {
		if (!!scrollRef.current) {
			var d: Element = scrollRef.current
			d.scrollTop = d.scrollHeight
		}
	}

	const loadMessages = async () => {
		if (!chatClient) return
		setIsLoading(true)

		var firstMessage: Message = _.get(messagesRef.current, 0)
		const startMessageId = _.get(firstMessage, "id")
		const startTime = _.get(firstMessage, "timestamp")

		let conv: PresistentConversation = await chatClient.getConversation(
			conversation.id
		)

		try {
			var msgs = await conv.queryMessages({
				limit: fetchLimit,
				startMessageId: startMessageId,
				startTime: startTime,
			})

			if (msgs.length < fetchLimit) {
				setDataEnd(true)
			}

			setMessages([...msgs, ...messagesRef.current])
		} catch (err) {
			console.error(err)
		}
		setIsLoading(false)
		if (!firstMessage) {
			scrollToEnd()
		}
	}

	const sendTextMessage = async () => {
		if (!!inputText) {
			var msg = new TextMessage(inputText)

			var options = {}

			// 每180秒对同一个用户只推送一次
			var agentMessages = messages.filter((m) => {
				return (m as Message).from.startsWith("service_agent")
			})
			var lastAgentMsg = agentMessages[agentMessages.length - 1]
			var lastAgentMsgTimeFromNow = 0

			try {
				if (!!lastAgentMsg) {
					lastAgentMsgTimeFromNow =
						(Date.now() - (lastAgentMsg as Message).timestamp.getTime()) / 1000
				}
			} catch (err) {
				console.error(err)
			}

			msg = await conversation.send(msg)
			setMessages([...messages, msg])
			setInputText("")
			scrollToEnd()

			// if (!lastAgentMsg || lastAgentMsgTimeFromNow >= 180) {
			if (true) {
				let sendToMembers = conversation.members.filter(
					(m) => !!m && m != "service_agent"
				)

				const res = await API.Breal.pushNotification.tpnsPush({
					user_ids: sendToMembers,
					title: "客服消息",
					content: msg.text,
					// custom_content: {
					// 	page: "service/chat",
					// 	objectId: conversation.id,
					// },
					show_in_app: false,
					environment: "product",
				})
			}
		}
	}

	const MessageCell = ({ msg }: { msg: Message }) => {
		var fromId = msg.from
		var fromMember = (
			members.filter((m) => m instanceof AV.Object) as AV.Object[]
		).find((m) => m.get("objectId") == fromId)

		var align = !!fromMember || msg.from.startsWith("guest_") ? "left" : "right"

		var avatarUrl =
			"https://file.ilux.ai/y6GEj2sr8GWMuXRqrsV5xdnQq4KTD2vp/default-avatar.png"
		if (!!fromMember) {
			avatarUrl = _.get(fromMember.get("avatar"), "attributes.url")
			if (!!avatarUrl) {
				avatarUrl = qiniuImageView(
					_.get(fromMember.get("avatar"), "attributes.url"),
					300
				)
			}
		}

		if (msg instanceof TextMessage) {
			return (
				<div className={`message-cell ${align}`}>
					<img className="avatar" src={avatarUrl} />
					<div className="content">{msg.text}</div>
				</div>
			)
		} else {
			console.log(`msg`, msg)
			var fileUrl = _.get(msg, "content._lcfile.url")
			console.log(`fileUrl`, fileUrl)
			if (fileUrl) {
				return (
					<div className={`message-cell ${align}`}>
						{!!avatarUrl ? <img className="avatar" src={avatarUrl} /> : ""}
						<div className="content">
							<a href={fileUrl} target="_blank">
								<img
									className="message-img"
									src={qiniuImageView(fileUrl, 300)}
								></img>
							</a>
						</div>
					</div>
				)
			} else {
				return <div className={`message-cell ${align}`}>未知类型消息</div>
			}
		}
	}

	const becomeAgent = async () => {
		const conv = await conversation.set("attr.agent", agentName).save()
		refreshConversation(conv)
	}

	const leave = async () => {
		const conv = await conversation.set("attr.agent", null).save()
		refreshConversation(conv)
	}

	const deleteChat = async () => {
		const res = await conversation.remove(conversation.members)
		console.log(`res`, res)
	}

	if (!conversation) {
		return <></>
	}

	return (
		<>
			<div className="current-chat">
				<div className="chat-window-container">
					<div className="chat-info">
						<div className="members">
							{members.map((m) => {
								if (m instanceof AV.Object) {
									var userId = m.get("objectId")
									var nickName = m.get("nickName")
									var avatarUrl = _.get(m.get("avatar"), "attributes.url")
									if (!!avatarUrl) {
										avatarUrl = qiniuImageView(
											_.get(m.get("avatar"), "attributes.url"),
											300
										)
									}
									return (
										<Tooltip
											key={m.get("objectId")}
											title={
												<div>
													<div>昵称: {nickName}</div>
													<div>ID: {userId}</div>
												</div>
											}
										>
											<div className="member-container">
												{!!avatarUrl ? (
													<img className="member-avatar-img" src={avatarUrl} />
												) : (
													<div className="member-avatar">{nickName}</div>
												)}
											</div>
										</Tooltip>
									)
								} else if ((m as string).startsWith("guest_")) {
									return (
										<Tooltip title={m} key={m as string}>
											<div className="member-container">
												<div className="member-avatar">新客</div>
											</div>
										</Tooltip>
									)
								} else {
									return <span key={m as string} />
								}
							})}
						</div>

						<div>
							<Button
								className="mobile-regard-btn"
								style={{ marginRight: 8 }}
								onClick={() => {
									setShowAbout(true)
								}}
							>
								咨询什么
							</Button>

							{conversationAgent ? (
								iAmAgent ? (
									<Button
										type="primary"
										onClick={() => {
											Modal.confirm({
												title: "释放对话?",
												content:
													"你是当前对话的服务人员，释放后对话将可以被其他客服人员接待。",
												onOk: leave,
											})
										}}
									>
										释放客户
									</Button>
								) : (
									<div>{conversationAgent}的客户</div>
								)
							) : (
								<Button type="primary" onClick={becomeAgent}>
									接待客户
								</Button>
							)}
						</div>
					</div>
					<div className="message-list" ref={scrollRef}>
						<div className="spacer"></div>
						<List
							loading={isLoading}
							dataSource={messages}
							rowKey={(item) => (item as Message).id}
							renderItem={(item: Message, idx) => {
								return (
									<>
										{idx == 0 && !dataEnd ? (
											<div className="load-more-div">
												<a onClick={loadMessages}>加载更多 ^</a>
											</div>
										) : (
											""
										)}
										<MessageCell msg={item} />
									</>
								)
							}}
						></List>
					</div>

					{!!conversation ? (
						<div>
							{iAmAgent ? (
								<div className="input-container">
									<Input
										autoFocus={true}
										className="text-input"
										placeholder="输入文字..."
										value={inputText}
										onChange={(e) => {
											setInputText(e.currentTarget.value)
										}}
										onPressEnter={sendTextMessage}
									></Input>
									<Button type="primary" onClick={sendTextMessage}>
										发送
									</Button>
								</div>
							) : (
								<></>
							)}
						</div>
					) : (
						""
					)}
				</div>

				<div className="chat-regard-item-container">
					<ConvInfo attr={attr} />
				</div>

				<Modal
					visible={showAbout}
					className="chat-regard-item-modal"
					centered
					onCancel={() => {
						setShowAbout(false)
					}}
					bodyStyle={{ padding: 0, maxHeight: "80vh", overflow: "scroll" }}
					cancelText="关闭"
					title="咨询关于"
					footer={false}
				>
					<ConvInfo attr={attr} />
				</Modal>
			</div>
		</>
	)
}

export default ChatWindow
