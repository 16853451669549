import { Button, Drawer, Layout, Affix } from "antd"
import React, { ReactNode, useState } from "react"
import { BrowserView, MobileView } from "react-device-detect"
import IconFont from "../IconFont/IconFont"
import "./MainFrame.less"
import Menu from "./MainMenu"
import { useSelector } from "react-redux"
import _ from "lodash"

const { Header, Footer, Sider, Content } = Layout

interface MainFrameProps {
	children: ReactNode
}

const MainFrame = ({ children }: MainFrameProps) => {
	const navbarTitle =
		useSelector((state) => _.get(state, "global.navbarTitle")) || "iLux控制台"
	const navbarRightItem = useSelector((state) =>
		_.get(state, "global.navbarRightItem")
	)
	const [drawerVisible, setDrawerVisible] = useState(false)
	const toggleSideMenu = () => {
		setDrawerVisible(!drawerVisible)
	}

	return (
		<Layout className="main-layout">
			<BrowserView>
				<Affix offsetTop={0} className="menu-affix">
					<Sider theme="light" className="side-menu">
						<Menu />
					</Sider>
				</Affix>
			</BrowserView>

			<MobileView>
				<Drawer
					getContainer={false}
					visible={drawerVisible}
					closable
					onClose={toggleSideMenu}
					footer
					placement="left"
					className="drawer"
					bodyStyle={{ padding: 0 }}
					title="这是一个菜单"
				>
					<Menu onSelected={toggleSideMenu} />
				</Drawer>
			</MobileView>

			<Layout className="content-layout">
				<Affix offsetTop={0} style={{zIndex: 1000}}>
					<Header className="header">
						<div className="left-item">
							<MobileView>
								<Button
									onClick={toggleSideMenu}
									icon={<IconFont name="category" />}
								></Button>
							</MobileView>
						</div>
						<div className="title">{navbarTitle}</div>
						<div className="right-item">{navbarRightItem}</div>
					</Header>
				</Affix>
				<Content className="content">{children}</Content>
			</Layout>
		</Layout>
	)
}

export default MainFrame
