import React from "react"
import { useSelector } from "react-redux"
import _ from "lodash"

const AdminPaygrade = ({ requiredLevel = 0, children }) => {
	const currentUserALevel = useSelector((state) =>
		_.get(state, "global.user.ALevel", 0)
	)

	if (requiredLevel <= currentUserALevel) {
		return children
	}

	return <div>Access Denied</div>
}

export default AdminPaygrade
