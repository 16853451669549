import React, { useState, useEffect } from "react"
import BraftEditor from "braft-editor"
import "braft-editor/dist/index.css"
import AV from "leancloud-storage/live-query"
import { Input, Form, Button, Popconfirm, Modal } from "antd"
import API from "../../api/api"
import _, { values } from "lodash"
import "./HtmlContentEditor.less"

const HtmlContentEditor = ({ contentObject, onSave }) => {
	const [title, setTitle] = useState("")
	const [contentId, setContentId] = useState("")
	const [editorState, setEditorState] = useState(
		BraftEditor.createEditorState(_.get(contentObject, "content"))
	)

	const [htmlEditorVisible, setHtmlEditorVisible] = useState(false)

	useEffect(() => {
		setTitle(_.get(contentObject, "title"))
		setContentId(_.get(contentObject, "contentId"))
		setEditorState(
			BraftEditor.createEditorState(_.get(contentObject, "content"))
		)
	}, [])

	const handleEditorChange = (state) => {
		setEditorState(state)
	}

	const save = async () => {
		const htmlContent = editorState.toHTML()

		let params = {
			objectId: _.get(contentObject, "objectId"),
			contentId: contentId,
			title: title,
			content: htmlContent,
		}
		console.log(params)

		try {
			const res = await API.Admin.Common.save_html_content(params)
			Modal.success({
				title: "保存成功",
				onOk: onSave,
			})
		} catch (error) {}
	}

	return (
		<div className="content-editor-container">
			<Form.Item style={{ textAlign: "right", marginBottom: 8 }}>
				<Popconfirm title="确认保存?" onConfirm={save}>
					<Button type="primary">保存</Button>
				</Popconfirm>
			</Form.Item>
			<Form.Item label="标题" style={{ marginBottom: 8 }}>
				<Input
					value={title}
					onChange={(e) => {
						setTitle(e.currentTarget.value)
					}}
				/>
			</Form.Item>
			<Form.Item label="内容ID" style={{ marginBottom: 8 }}>
				<Input
					value={contentId}
					onChange={(e) => {
						setContentId(e.currentTarget.value)
					}}
				/>
			</Form.Item>
			<div className="editor-wrapper">
				{htmlEditorVisible ? (
					<Form
						initialValues={{
							html: editorState.toHTML(),
						}}
						onFinish={(values) => {
							setEditorState(BraftEditor.createEditorState(values.html))
							setHtmlEditorVisible(false)
						}}
					>
						<Button htmlType="submit">回到可视化编辑器</Button>
						<Form.Item name="html">
							<Input.TextArea style={{ minHeight: "600px" }}></Input.TextArea>
						</Form.Item>
					</Form>
				) : (
					<BraftEditor
						contentStyle={{ height: "auto", minHeight: 600 }}
						controls={[
							"undo",
							"redo",
							"separator",
							"font-family",
							"font-size",
							"line-height",
							"letter-spacing",
							"separator",
							"text-color",
							"bold",
							"italic",
							"underline",
							"strike-through",
							"separator",
							"superscript",
							"subscript",
							"remove-styles",
							"emoji",
							"separator",
							"text-indent",
							"text-align",
							"separator",
							"headings",
							"list-ul",
							"list-ol",
							"blockquote",
							"code",
							"separator",
							"link",
							"separator",
							"hr",
							"separator",
							"media",
							"separator",
							"separator",
							"clear",
						]}
						extendControls={[
							{
								key: "show-html-btn", // 控件唯一标识，必传
								type: "button",
								title: "显示HTML编辑器", // 指定鼠标悬停提示文案
								className: "my-button", // 指定按钮的样式名
								html: null, // 指定在按钮中渲染的html字符串
								text: "HTML", // 指定按钮文字，此处可传入jsx，若已指定html，则text不会显示
								onClick: () => {
									setHtmlEditorVisible(!htmlEditorVisible)
								},
							},
						]}
						value={editorState}
						onChange={handleEditorChange}
						fontFamilies={[
							{
								name: "Araial",
								family: "Arial, Helvetica, sans-serif",
							},
							{
								name: "Georgia",
								family: "Georgia, serif",
							},
							{
								name: "Impact",
								family: "Impact, serif",
							},
							{
								name: "Monospace",
								family: '"Courier New", Courier, monospace',
							},
							{
								name: "Tahoma",
								family: 'tahoma, arial, "Hiragino Sans GB", 宋体, sans-serif',
							},
						]}
						media={{
							accepts: {
								image:
									"image/png,image/jpeg,image/gif,image/webp,image/apng,image/svg",
								video: "video/mp4",
							},
							uploadFn: async (options) => {
								try {
									let avfile = await new AV.File(
										"html_content_image.png",
										options.file
									).save()
									options.success({
										url: avfile.url(),
										meta: {
											id: avfile.id,
											title: "html_content_image",
											alt: "",
											autoPlay: false,
											controls: true,
											loop: false,
											poster: "",
										},
									})
								} catch (error) {
									options.error({
										msg: JSON.stringify(error),
									})
								}
							},
						}}
					/>
				)}
			</div>
		</div>
	)
}

export default HtmlContentEditor
