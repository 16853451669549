import React, { useState, useEffect } from "react"
import "./User.less"
import { useParams, useHistory, useLocation, Link } from "react-router-dom"
import _ from "lodash"
import API from "../../api/api"
import {
	Card,
	Tabs,
	Table,
	Typography,
	Tooltip,
	Statistic,
	Modal,
	Button,
	Form,
	Input,
	InputNumber,
} from "antd"
import AspectImage from "../../components/AspectImage/AspectImage"
import { orderColumns } from "../Shop/Orders"
import IconFont from "../../components/IconFont/IconFont"
import { ColumnsType } from "antd/lib/table"
import { qiniuImageView, navbar } from "../../utils/helpers"
import moment from "moment"
import { appraiseOrderColumns } from "../Appraise/Orders"
import OrderExpandedRow from "../Appraise/OrderExpandedRow"

const { Paragraph } = Typography

const BasicInfo = ({ userInfo }) => {
	const user = _.get(userInfo, "user")
	const userAuths = _.get(userInfo, "user_auths", [])

	const basicInfoKeys = [
		{
			key: "object_id",
			label: "ID",
			copiable: true,
		},
		{
			key: "nick_name",
			label: "昵称",
			copiable: true,
		},
		{
			key: "created_at",
			label: "注册时间",
			formatter: (r) => {
				console.log(`r`, r)
				return moment(r * 1000).format("YYYY.MM.DD HH:mm")
			},
		},
		{
			key: "country",
			label: "国家",
		},
		{
			key: "province",
			label: "省份",
		},
		{
			key: "sex",
			label: "性别",
			formatter: (v) => {
				switch (v) {
					case 1:
						return (
							<>
								男 <IconFont name="icon-nan" color="#9fd2d6" />
							</>
						)
					case 2:
						return (
							<>
								女 <IconFont name="icon-nv" color="#edd2cc" />
							</>
						)
					default:
						return "无"
				}
			},
		},
	]

	const authAuthDict = {
		swan: "百度",
		weapp: "微信",
	}

	return (
		<Card
			loading={!user}
			title="基本资料"
			cover={<AspectImage src={_.get(user, "avatar.url")} />}
		>
			{basicInfoKeys.map((k, i) => (
				<div key={i}>
					{k.label}:{" "}
					<Paragraph copyable={!!k.copiable}>
						{k.formatter
							? k.formatter(_.get(user, k.key, ""))
							: _.get(user, k.key, "")}
					</Paragraph>
				</div>
			))}

			{userAuths.map((ua, i) => (
				<div key={i}>
					{_.get(authAuthDict, _.get(ua, "platform"))} {_.get(ua, "app")}{" "}
					{_.get(ua, "type")}: <Paragraph copyable>{_.get(ua, "id")}</Paragraph>
				</div>
			))}
		</Card>
	)
}

const ShopOrders = ({ user }) => {
	const history = useHistory()
	const [loading, setLoading] = useState(false)
	const [orders, setOrders] = useState()

	useEffect(() => {
		if (user) {
			getOrders()
		}
	}, [user])

	const getOrders = async () => {
		setLoading(true)
		try {
			const res = await API.Shop.Order.get_orders({
				page: 0,
				limit: 1000,
				buyer_id: _.get(user, "object_id"),
			})
			setOrders(res.orders)
		} catch (error) {}
		setLoading(false)
	}

	return (
		<Table
			loading={loading}
			dataSource={orders}
			columns={orderColumns.filter((c) => {
				return !["buyer"].includes(String(c.key))
			})}
			onRow={(order, index) => ({
				onClick: () => {
					history.push(`/shop/order/${_.get(order, "id")}`)
				},
			})}
			rowKey={(r, i) => _.get(r, "objectId")}
		/>
	)
}

const ShopFavs = ({ user }) => {
	const history = useHistory()
	const [loading, setLoading] = useState(false)
	const [userfavs, setUserfavs] = useState([])
	useEffect(() => {
		if (user) {
			fetchUserFavs()
		}
	}, [user])

	const fetchUserFavs = async () => {
		try {
			setLoading(true)
			const res = await API.Shop.Common.recent_favs({
				user_id: _.get(user, "objectId"),
			})
			setUserfavs(res)
		} catch (error) {}
		setLoading(false)
	}

	const columns: ColumnsType = [
		{
			key: "cover",
			title: "封面",
			width: 88,
			align: "center",
			render: (r, i) => {
				return (
					<div className="cover-wrapper">
						<Tooltip
							title={
								<a href={_.get(r, "shopItem.cover_url")} target="_blank">
									<img
										src={qiniuImageView(_.get(r, "shopItem.cover_url"), 400)}
										className="pc cover-large"
									/>
								</a>
							}
							placement="right"
						>
							<img
								src={qiniuImageView(_.get(r, "shopItem.cover_url"), 80)}
								className="pc cover"
							/>
						</Tooltip>
					</div>
				)
			},
		},
		{
			key: "shopItem",
			title: "关注商品",
			render: (r, i) => {
				return (
					<span>
						<div className="pc id">商品ID: {_.get(r, "shopItem.id")}</div>
						<div className="pc brand">
							{_.get(r, "shopItem.brand.name")}
							{_.get(r, "shopItem.category.name")}
						</div>
						<div>{_.get(r, "shopItem.name")}</div>
					</span>
				)
			},
		},

		{
			title: "关注时间",
			align: "right",
			dataIndex: "createdAt",
			render: (r) => moment.unix(r).fromNow(),
		},
	]

	return (
		<Table
			loading={loading}
			className="shop-favs-table"
			dataSource={userfavs}
			columns={columns}
			pagination={{
				total: userfavs.length,
				pageSize: 100,
			}}
			rowKey={(r) => _.get(r, "objectId")}
			onRow={(r, i) => ({
				onClick: () => {
					history.push(`/shop/item/${_.get(r, "shopItem.id")}`)
				},
			})}
		/>
	)
}

const AppraiseInfo = ({ user }) => {
	const history = useHistory()
	const [loading, setLoading] = useState(false)
	const [pageOrders, setPageOrders] = useState([])
	const [currentPage, setCurrentPage] = useState(0)
	const [pageSize, setPageSize] = useState(20)

	const [expandedRowKeys, setExpandedRowKeys] = useState([])

	useEffect(() => {
		if (user) {
			fetchAppraiseOrders()
		}
	}, [user, currentPage, pageSize])

	const fetchAppraiseOrders = async () => {
		try {
			setLoading(true)
			const res = await API.Appraise.AdminCP.get_orders({
				limit: pageSize,
				page: currentPage,
				user_id: _.get(user, "objectId"),
			})
			setPageOrders(_.get(res, "orders"))
			setCurrentPage(_.get(res, "page"))
		} catch (error) {}
		setLoading(false)
	}

	return (
		<Table
			loading={loading}
			className="shop-favs-table"
			dataSource={pageOrders}
			columns={appraiseOrderColumns}
			pagination={{
				total: currentPage,
				pageSize: pageSize,
				onShowSizeChange: (current, size) => {
					setPageSize(size)
				},
				onChange: (page, pageSize) => {
					setCurrentPage(page)
				},
			}}
			rowKey={(r) => _.get(r, "objectId")}
			onRow={(r, index) => ({
				onClick: () => {
					history.push("/appraise/order/" + _.get(r, "objectId"))
				},
			})}
		/>
	)
}

const CandyWallet = ({ user }) => {
	const [loading, setLoading] = useState(false)
	const [balance, setBalance] = useState(0)
	const [transactions, setTransactions] = useState([])
	const [showCandyFiller, setShowCandyFiller] = useState(false)
	const [candyFillerForm] = Form.useForm()

	useEffect(() => {
		if (user) {
			getBalance()
			getTransactions()
		}
	}, [user])

	const getBalance = async () => {
		try {
			setLoading(true)
			const res = await API.CandyWallet.get_balance({
				user: _.get(user, "object_id"),
			})
			setBalance(res)
		} catch (error) {}
		setLoading(false)
	}

	const getTransactions = async () => {
		try {
			setLoading(true)
			const res = await API.CandyWallet.get_transactions({
				user: _.get(user, "object_id"),
			})
			setTransactions(res)
		} catch (error) {}
		setLoading(false)
	}

	const fillCandyWallet = async (amount: number) => {
		try {
			setLoading(true)
			const res = await API.CandyWallet.fill_candy_wallet({
				user_id: _.get(user, "object_id"),
				amount: amount,
			})
			getBalance()
			getTransactions()
			Modal.success({
				title: "充值成功",
			})
		} catch (error) {}
		setLoading(false)
	}

	useEffect(() => {
		if (showCandyFiller) {
			candyFillerForm.resetFields()
		}
	}, [showCandyFiller])

	const columns: ColumnsType = [
		{
			title: "类型",
			dataIndex: "transaction_type",
			render: (r) => {
				return _.get(r, "title")
			},
		},
		{
			title: "鉴定订单",
			dataIndex: "related_order",
			render: (r) => {
				return (
					<Link to={`/appraise/order/${_.get(r, "objectId")}`}>
						{_.get(r, "objectId")}
					</Link>
				)
			},
		},
		{
			title: "变动",
			dataIndex: "amount",
		},
		{
			title: "时间",
			dataIndex: "created_at",
			render: (r) => moment.unix(r).format("YYYY.MM.DD HH:mm"),
		},
	]

	return (
		<>
			<Card
				title="糖果钱包"
				loading={loading}
				extra={
					<Button
						type="primary"
						onClick={() => {
							setShowCandyFiller(true)
						}}
					>
						手动充值
					</Button>
				}
			>
				<Statistic value={balance} prefix="糖果余额" />
				<Table
					loading={loading}
					columns={columns}
					dataSource={transactions}
					pagination={{
						pageSize: 50,
					}}
					rowKey={(r) => _.get(r, "created_at")}
				/>
			</Card>
			<Modal
				title="手动充值糖果"
				visible={showCandyFiller}
				onOk={() => {
					candyFillerForm.submit()
				}}
				onCancel={() => {
					setShowCandyFiller(false)
				}}
				destroyOnClose={true}
			>
				<Form
					initialValues={{
						amount: 0,
					}}
					form={candyFillerForm}
					onFinish={(values) => {
						Modal.confirm({
							title: "确定充值",
							content: `是否充值${parseInt(values.amount)}糖果?`,
							onOk: async () => {
								await fillCandyWallet(parseInt(values.amount))
								setShowCandyFiller(false)
							},
						})
					}}
				>
					<Form.Item name="amount" label="充值额度">
						<InputNumber />
					</Form.Item>
				</Form>
			</Modal>
		</>
	)
}

const User = () => {
	const location = useLocation()
	const history = useHistory()
	const params = useParams()
	const userId = _.get(params, "id")
	const [userInfo, setUserInfo] = useState()
	const user = _.get(userInfo, "user")

	console.log(`user`, user)
	useEffect(() => {
		if (userId) {
			fetchUser()
		}
		navbar.setTitle("用户详情")
		return () => {
			navbar.reset()
		}
	}, [userId])

	const fetchUser = async () => {
		try {
			const res = await API.Breal.User.fetch_user({
				user_id: userId,
			})
			setUserInfo(res)
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<div className="user-page">
			<BasicInfo userInfo={userInfo} />
			<Tabs
				defaultActiveKey={_.get(params, "tab")}
				onChange={(activeKey) => {
					history.push(`/user/${userId}/${activeKey}`)
				}}
			>
				<Tabs.TabPane key="shopOrders" tab="闪拍订单">
					<ShopOrders user={user} />
				</Tabs.TabPane>
				<Tabs.TabPane key="shopFavs" tab="闪拍关注">
					<ShopFavs user={user} />
				</Tabs.TabPane>
				<Tabs.TabPane key="appraiseOrders" tab="鉴定订单">
					<AppraiseInfo user={user} />
				</Tabs.TabPane>
				<Tabs.TabPane key="candyWallet" tab="糖果钱包">
					<CandyWallet user={user} />
				</Tabs.TabPane>
			</Tabs>
		</div>
	)
}

export default User
