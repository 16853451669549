import React, { CSSProperties } from "react"
import "./AspectImage.less"
import IconFont from "../IconFont/IconFont"
import { Skeleton } from "antd"

interface AspectImageProps {
	src?: string
	heightRatio?: string
	isSkeleton?: boolean
	fillMode?: "contain" | "cover"
	wrapperStyle?: CSSProperties
	style?: CSSProperties
	children?: any
}

const AspectImage = ({
	src,
	heightRatio = "100%",
	isSkeleton = false,
	fillMode = null,
	wrapperStyle = {},
	style = {},
	children,
}: AspectImageProps) => {
	return (
		<div
			className="image-wrapper"
			style={{ paddingTop: heightRatio || "100%", ...wrapperStyle }}
		>
			<div className="image-mask"></div>
			{isSkeleton ? (
				<Skeleton.Button className="image" active />
			) : src ? (
				<img
					src={src}
					className="image"
					style={fillMode ? { ...style, objectFit: fillMode } : style}
				/>
			) : (
				<div className="image">
					<IconFont name="pic" size="3em" />
				</div>
			)}
			{children}
		</div>
	)
}

export default AspectImage
