import { Skeleton, Tag, Tooltip, Button } from "antd"
import _ from "lodash"
import React, { CSSProperties, useCallback, useState } from "react"
import { useDrag } from "react-dnd"
import { useDispatch } from "react-redux"
import AspectImage from "../../components/AspectImage/AspectImage"
import shopSlice from "../../redux/slices/shop/shop"
import { qiniuImageView, conditionParse } from "../../utils/helpers"
import "./ShopItemCard.less"
import { useHistory } from "react-router-dom"
import IconFont from "../IconFont/IconFont"

interface ShopItemCardProps {
	item?: object
	index?: number
	isSkeleton?: boolean
	style?: CSSProperties
	showActionBtns?: boolean
}

const ShopItemCard = ({
	item,
	index,
	isSkeleton,
	style,
	showActionBtns = true,
}: ShopItemCardProps) => {
	const thumbnailAspectRatio = (10 / 16) * 100 + "%"
	const history = useHistory()
	const dispatch = useDispatch()
	const setEditorItem = (item) => {
		dispatch(shopSlice.actions.setEditorItem(item))
	}

	if (isSkeleton) {
		return (
			<div className="grid-card" style={style}>
				<AspectImage heightRatio={thumbnailAspectRatio} isSkeleton={true} />
				<div className="content">
					<Skeleton
						active
						round
						title={false}
						paragraph={{ rows: 3, style: { marginBottom: 0 } }}
					/>
				</div>
			</div>
		)
	}

	return (
		<div
			key={index}
			className="grid-card"
			style={style}
			onClick={() => {
				let num_id = _.get(item, "id")
				history.push("/shop/item/" + num_id)
			}}
		>
			<AspectImage
				heightRatio={thumbnailAspectRatio}
				src={
					_.get(item, "cover_url")
						? qiniuImageView(_.get(item, "cover_url"), 300)
						: null
				}
			>
				{_.get(item, "sold") ? <div className="sold-mask">Sold</div> : ""}
			</AspectImage>

			<div className="content">
				<div className="brand">
					{_.get(item, "brand.name")}
					{_.get(item, "category.name")}
				</div>
				<div className="name">{_.get(item, "name") || "没有名称"}</div>
				<div className="description">
					{_.get(item, "description") || "暂无描述"}
				</div>
				<div className="tags">
					{conditionParse(_.get(item, "condition"))} |{" "}
					¥{_.get(item, "price") || "N/A"}
				</div>

				<Button
					className="quick-edit-btn"
					onClick={(e) => {
						e.stopPropagation()
						setEditorItem(item)
					}}
					size="small"
					hidden={!showActionBtns}
				>
					<IconFont name="icon-editor" size="1em" />
				</Button>
			</div>
		</div>
	)
}

export const DraggableShopItemCard = (props: ShopItemCardProps) => {
	const [width, setWidth] = useState()
	const [height, setHeight] = useState()

	const div = useCallback((node) => {
		if (node !== null) {
			setHeight(node.getBoundingClientRect().height)
			setWidth(node.getBoundingClientRect().width)
		}
	}, [])

	const [beginDrag, setBeginDrag] = useState(false)
	const [collectedProps, drag, preview] = useDrag({
		item: {
			type: "ShopItemCard",
			index: props.index,
			name: _.get(props.item, "name"),
			width: width,
			height: height,
			shopItem: props.item,
		},
		collect: (monitor) => {
			console.log('"monitor"', "monitor")
			return { isDragging: !!monitor.isDragging() }
		},
		begin: (monitor) => {
			setBeginDrag(true)
			console.log("begin")
		},
		previewOptions: {},
	})
	const { isDragging } = collectedProps

	return (
		<>
			{/* <DragPreviewImage connect={preview} src="https://iluxnet.oss-cn-hangzhou.aliyuncs.com/web/assets/images/authenticate-qr.png" /> */}
			<div
				className="draggable-wrapper"
				ref={drag}
				style={{
					opacity: isDragging ? 0.5 : 1,
				}}
			>
				<div ref={div}>
					<ShopItemCard {...props} />
				</div>
			</div>
		</>
	)
}

export default ShopItemCard
