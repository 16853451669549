import React, { useState, useEffect } from "react"
import "./MainMenu.less"
import { Badge, Menu } from "antd"
import IconFont from "../IconFont/IconFont"
import routes, { groupedRoutes, CpRoute } from "../../routes"
import { SelectParam } from "antd/lib/menu"
import { useLocation, useHistory, Route } from "react-router-dom"
import { useSelector } from "react-redux"
import _ from "lodash"
import { useChat } from "../../models/chat_client"

const { Item, Divider, ItemGroup, SubMenu } = Menu

interface MainMenuProps {
	onSelected?: Function
}

const renderMenuItem = (route: CpRoute, params: { badge?: number }) => {
	return (
		<Item className="item" key={route.key}>
			<Badge count={params.badge || 0} offset={[16, 12]}>
				<IconFont
					name={route.icon}
					style={{ marginRight: "0.5rem" }}
					size="1.4em"
				/>
				<span>{route.label}</span>
			</Badge>
		</Item>
	)
}

const MainMenu = ({ onSelected }: MainMenuProps) => {
	const history = useHistory()
	const currentLocation = useLocation()
	const [selectedKey, setSelectedKey] = useState("home")
	const currentUser = useSelector((state) => _.get(state, "global.user"))
	const currentUserALevel = useSelector((state) =>
		_.get(state, "global.user.ALevel")
	)

	const [chatClient, unreadMessagesCount, conversations, isLoading] = useChat()

	useEffect(() => {
		let currentRoute = routes.find((r) => r.path === currentLocation.pathname)
		if (currentRoute) {
			setSelectedKey(currentRoute.key)
		} else {
			setSelectedKey(null)
		}
	}, [currentLocation])

	const selectMenuItem = (param: SelectParam) => {
		setSelectedKey(param.key)
		let newRoute = routes.find((r) => r.key === param.key)
		if (newRoute.func) {
			newRoute.func()
		} else if (newRoute.component) {
			history.push(newRoute.path)
		}

		if (onSelected) {
			onSelected(param)
		}
	}

	return (
		<Menu
			className="menu"
			mode="vertical-left"
			selectedKeys={[selectedKey]}
			onSelect={selectMenuItem}
			defaultSelectedKeys={[selectedKey]}
		>
			{groupedRoutes.map((routeGroup) => {
				return (
					<ItemGroup title={routeGroup.groupName} key={routeGroup.groupName}>
						{routeGroup.routes
							.filter((r) => !r.alevel || r.alevel <= currentUserALevel)
							.map((r) =>
								renderMenuItem(r, {
									badge:
										r.key == "breal/customer-service" ? unreadMessagesCount : 0,
								})
							)}
					</ItemGroup>
				)
			})}
		</Menu>
	)
}

export default MainMenu
