import { Table, Avatar, Tag, Tooltip, Form, Select, Spin } from "antd"
import _ from "lodash"
import React, { useState, useEffect } from "react"
import "./Orders.less"
import API from "../../api/api"
import moment from "moment"
import { qiniuImageView } from "../../utils/helpers"
import { Link } from "react-router-dom"
import { ColumnsType } from "antd/lib/table"
import "moment/locale/zh-cn"
import { useSelector } from "react-redux"
import OrderExpandedRow from "./OrderExpandedRow"
moment.locale("zh-cn")

let searchInputTimeout: NodeJS.Timeout

export const appraiseOrderColumns: ColumnsType = [
	{
		key: "date",
		title: "日期/ID",
		render: (r) => (
			<>
				<Tooltip
					title={moment(_.get(r, "createdAt")).format("YYYY-MM-DD HH:mm")}
				>
					<span className="created-at">
						{moment(_.get(r, "createdAt")).format("MM/DD HH:mm")}
					</span>
				</Tooltip>
				<div>{_.get(r, "id")}号</div>
			</>
		),
	},
	{
		key: "plan",
		title: "套餐",
		render: (r) => (
			<>
				<div>
					{_.get(r, "authBrand.name")} {_.get(r, "authType.cnName")}
				</div>
				<div>{_.get(r, "authPlan.name")}</div>
			</>
		),
	},
	{
		key: "user",
		title: "用户",
		dataIndex: "user",
		ellipsis: true,
		render: (user) => (
			<>
				<Avatar src={qiniuImageView(_.get(user, "avatar"), 80)} />
				<Link to={`/user/${_.get(user, "objectId")}`}>
					{_.get(user, "nickName")}
				</Link>
			</>
		),
	},
	{
		title: "支付方式",

		align: "center",
		render: (record, index) => {
			let { payment, channel } = record
			let cashPrice = _.get(record, "cashPrice")
			let candyPrice = _.get(record, "candyPrice")
			let color = ""
			let res = ""
			if (payment.paymentType == "wxpay") {
				res = "微信 ¥" + cashPrice
				color = "#4caf4f"
			} else if (payment.paymentType == "swanpay") {
				res = "百度 ¥" + cashPrice
				color = "rgb(0,0,238)"
			} else if (payment.paymentType == "alipay") {
				res = "支付宝 ¥" + cashPrice
				color = "#3379F6"
			} else {
				res = "糖果 " + candyPrice
				color = "#9fd2d6"
			}

			return (
				<Tag color={color}>
					{res} {channel == "breal_app" ? "(APP)" : ""}
				</Tag>
			)
		},
	},
	{
		title: "鉴定师",
		dataIndex: "appraiser",
		align: "center",
		render: (appraiser, record, index) => {
			if (!appraiser) {
				return <Tag>暂无</Tag>
			} else {
				return (
					<Tooltip title={_.get(appraiser, "nickName")}>
						<Avatar src={_.get(appraiser, "avatar")} />
					</Tooltip>
				)
			}
		},
	},
	{
		title: "状态",
		align: "center",
		dataIndex: "status",
		// fixed: "right",
		render: (status, record, index) => {
			if (_.get(record, "refund")) {
				if (!!_.get(record, "refund.is_refunded")) {
					return <Tag>已退款</Tag>
				} else {
					return <Tag color="red">退单</Tag>
				}
			}
			if (status == 0) {
				return <Tag>待用户拍照</Tag>
			} else if (status == 1) {
				return <Tag color="#d69f9f">等待接单</Tag>
			} else if (status == 2) {
				let lastPayload = _.get(record, "payloads", [])[0]
				if (!!_.get(record, "userReplied")) {
					return <Tag color="#d69f9f">待鉴定师回复</Tag>
				} else {
					return <Tag color="#63c5e4">待用户回复</Tag>
				}
			} else if (status == 3) {
				return (
					<Tag>
						{"鉴定为" + (!!_.get(record, "appraisal.pass") ? "真" : "假")}
					</Tag>
				)
			}
		},
	},
]

const Orders = () => {
	const [loading, setLoading] = useState(false)
	const [orders, setOrders] = useState()
	const [currentPage, setCurrentPage] = useState(0)
	const [pageSize, setPageSize] = useState(20)
	const [filters, setFilters] = useState({})
	const [total, setTotal] = useState(0)
	const [form] = Form.useForm()
	const [expandedRowKeys, setExpandedRowKeys] = useState([])

	const [brandSearchKeyword, setBrandSearchKeyword]: [string, Function] =
		useState()

	const commonData = useSelector((state) => _.get(state, "global.commonData"))
	const brands = (_.get(commonData, "brands") || []).filter((b) => {
		let name: string = _.get(b, "name") || ""
		let cnName: string = _.get(b, "cnName") || ""
		let fullname = (name + cnName).toLowerCase()
		let kw = (brandSearchKeyword || "").toLowerCase()
		return fullname.includes(kw)
	})

	const authTypes = _.get(commonData, "appraise_auth_types", [])

	const [isSearchingUsers, setIsSearchingUsers] = useState(false)
	const [searchedUsers, setSearchedUsers] = useState()

	useEffect(() => {
		fetchAppraiseOrders()
	}, [currentPage, pageSize, filters])

	const fetchAppraiseOrders = async () => {
		setLoading(true)
		try {
			const { orders, total, page_size, current_page } =
				await API.Appraise.AdminCP.get_orders({
					page: currentPage,
					limit: pageSize,
					...filters,
				})
			console.log("orders", orders)
			setOrders(orders)
			setTotal(total)
			setCurrentPage(current_page)
		} catch (error) {}
		setLoading(false)
	}

	const searchUsers = (kw: string) => {
		if (!kw) {
			setSearchedUsers(null)
			setIsSearchingUsers(false)
			if (searchInputTimeout) {
				clearTimeout(searchInputTimeout)
				searchInputTimeout = null
			}
			return
		}

		setIsSearchingUsers(true)
		if (searchInputTimeout) {
			clearTimeout(searchInputTimeout)
			searchInputTimeout = null
		}
		searchInputTimeout = setTimeout(async () => {
			setSearchedUsers(null)
			if (kw) {
				try {
					const res = await API.Admin.UserManagement.search_users({
						keyword: kw,
					})
					setSearchedUsers(res)
				} catch (err) {}
				setIsSearchingUsers(false)
			}

			clearTimeout(searchInputTimeout)
		}, 350)
	}

	const filterChanged = (params) => {
		console.log("params", params)
		setFilters({ ...filters, ...params })
	}

	return (
		<div className="appraise-orders-wrapper">
			<Form
				form={form}
				layout="inline"
				className="filter-form"
				onValuesChange={filterChanged}
			>
				<Form.Item name="auth_brand_id" required>
					<Select
						style={{ minWidth: 200 }}
						allowClear
						showSearch
						placeholder="选择品牌"
						onSearch={(kw) => setBrandSearchKeyword(kw)}
						filterOption={false}
					>
						{brands.map((brand) => (
							<Select.Option value={brand.objectId} key={brand.objectId}>
								{brand.name} {brand.cnName}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item name="auth_type_id" required>
					<Select allowClear placeholder="选择品类" style={{ minWidth: 140 }}>
						{authTypes.map((t) => (
							<Select.Option value={t.objectId} key={t.objectId}>
								{t.cnName}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item name="user_id">
					<Select
						style={{ minWidth: 240 }}
						allowClear
						showSearch
						placeholder="搜索用户"
						filterOption={false}
						onSearch={(kw) => searchUsers(kw)}
						notFoundContent={isSearchingUsers ? <Spin size="small" /> : null}
					>
						{(searchedUsers || []).map((user, i) => (
							<Select.Option
								value={_.get(user, "objectId")}
								key={_.get(user, "objectId", i)}
							>
								<img
									src={qiniuImageView(_.get(user, "avatar"), 200)}
									className="user-avatar"
								/>{" "}
								{_.get(user, "nickName")}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item name="auth_num_id_s">
					<Select
						style={{ minWidth: 300 }}
						mode="tags"
						tokenSeparators={[",", " "]}
						placeholder="鉴定号搜索，可同时输入多个"
						allowClear
					></Select>
				</Form.Item>
			</Form>
			<Table
				size="small"
				className="orders-table"
				loading={loading}
				dataSource={orders}
				pagination={{
					pageSize: pageSize,
					current: currentPage + 1,
					total: total,
					onChange: (page, size) => {
						setCurrentPage(page - 1)
					},
					onShowSizeChange: (current, size) => {
						setPageSize(size)
					},
				}}
				rowKey={(r) => _.get(r, "objectId")}
				columns={appraiseOrderColumns}
				expandRowByClick
				onExpand={(expanded, record) => {
					if (!expanded) {
						setExpandedRowKeys([])
					} else {
						setExpandedRowKeys([_.get(record, "objectId")])
					}
				}}
				expandedRowKeys={expandedRowKeys}
				expandedRowRender={(r) => (
					<OrderExpandedRow
						orderId={_.get(r, "objectId")}
						reload={() => {
							fetchAppraiseOrders()
						}}
					/>
				)}
			/>
		</div>
	)
}

export default Orders
