import React, { useEffect, useState } from "react"
import "./OrderDetail.less"
import { useLocation, useParams, Link, useHistory } from "react-router-dom"
import _ from "lodash"
import API, { ShippingAddress } from "../../api/api"
import {
	Card,
	List,
	Avatar,
	Typography,
	Modal,
	message,
	Tag,
	Spin,
	Button,
	Tooltip,
	notification,
	Table,
	Form,
	Input,
	Select,
} from "antd"
import moment from "moment"
import { qiniuImageView, navbar, conditionParse } from "../../utils/helpers"
import AspectImage from "../../components/AspectImage/AspectImage"
import { LoadingOutlined } from "@ant-design/icons"
import ShopItemDetail from "./ShopItemDetail"
import IconFont from "../../components/IconFont/IconFont"
import { ColumnsType } from "antd/lib/table"

const { Paragraph } = Typography

const addressKeyDict = {
	name: "姓名",
	mobile: "手机",
	province: "省份",
	city: "城市",
	area: "区域",
	address: "街道",
}

const OrderShippings = ({ orderId }: { orderId: string }) => {
	const [loading, setLoading] = useState(false)
	const [shopShippings, setShopShippings] = useState([])
	const [shippingFormVisible, setShippingFormVisible] = useState(false)
	const [form] = Form.useForm()

	useEffect(() => {
		fetchOrderShipppings()
	}, [])

	const fetchOrderShipppings = async () => {
		try {
			setLoading(true)
			const res = await API.Shop.Order.get_order_shippings({
				order_id: orderId,
			})
			setShopShippings(res)
		} catch (error) { }
		setLoading(false)
	}

	const createNewShipping = async () => {
		setShippingFormVisible(true)
	}

	const deleteShipping = async (shipping_object_id: string) => {
		Modal.confirm({
			title: "删除物流记录?",
			content: "只可以删除未生效的面单",
			okText: "确定删除",
			onOk: async () => {
				try {
					const res = await API.Shop.Shipping.delete_shippping({
						shipping_object_id: shipping_object_id,
						order_id: orderId,
					})
					fetchOrderShipppings()
				} catch (error) { }
			},
		})
	}

	const parseTrackingResultState = (trackingResult: object) => {
		let state = _.get(trackingResult, "lastResult.state", null)
		if (state == null) {
			return "暂无"
		} else {
			switch (parseInt(state)) {
				case 0:
					return "0: 在途"
				case 1:
					return "1: 揽收"
				case 2:
					return "2: 疑难"
				case 3:
					return "3: 签收"
				case 4:
					return "4: 退签"
				case 5:
					return "5: 派件"
				case 6:
					return "6: 退回"
				case 7:
					return "7: 转投"
				default:
					return state
			}
		}
	}

	const shippingCols: ColumnsType = [
		{
			key: "shipping",
			title: "运单号",
			render: (r) => (
				<Tooltip
					title={
						<div>
							<div>顺丰运单号: {_.get(r, "kuaidiNum")}</div>
							<div>顺丰订单号: {_.get(r, "kdComOrderNum")}</div>
						</div>
					}
				>
					<div>{_.get(r, "kuaidiNum")}</div>
				</Tooltip>
			),
		},
		{
			key: "waybill",
			title: "打印面单",
			align: "center",
			render: (r) => (
				<Tooltip title="打开电子面单页面后，在连接了面单打印机的电脑上，按CTRL + P (Windows) 或 CMD + P (Mac) 即可打印">
					<a href={_.get(r, "shipto_template_url")} target="_blank">
						打开
						<IconFont name="icon-share" />
					</a>
				</Tooltip>
			),
		},
		{
			key: "trackingStatus",
			title: "物流跟踪",
			align: "center",
			dataIndex: "trackingResult",
			render: (trackingResult) => {
				const wayPoints = _.get(trackingResult, "lastResult.data") || []

				return (
					<>
						<a
							onClick={() => {
								Modal.info({
									title: "物流跟踪",
									content: (
										<List
											dataSource={wayPoints}
											renderItem={(item) => {
												return (
													<List.Item>
														<List.Item.Meta
															title={`${_.get(item, "ftime")}`}
															description={`${_.get(item, "context")}`}
														></List.Item.Meta>
													</List.Item>
												)
											}}
										/>
									),
								})
							}}
						>
							{parseTrackingResultState(trackingResult)}
						</a>
					</>
				)
			},
		},
		{
			key: "delete",
			title: "删除",
			align: "center",
			render: (r) =>
				!_.get(r, "trackingResult") ? (
					<Button
						type="link"
						danger
						onClick={() => {
							deleteShipping(_.get(r, "objectId"))
						}}
					>
						<IconFont name="icon-shanchu" size="1.1em" />
					</Button>
				) : (
					""
				),
		},
	]

	const submit = (values) => {
		console.log("values", values)
		Modal.confirm({
			title: "新建物流?",
			content: values.kuaidiNum
				? `即将为订单添加已有的运单${values.kuaidiNum}，只有通过公司月结账号生成的运单号才可以被追踪。`
				: "即将为订单创建顺丰电子面单，请确保地址正确",
			okText: "创建",
			onOk: async () => {
				try {
					const res = await API.Shop.Shipping.create_shipping_for_order({
						order_id: orderId,
						kuaidiNum: values.kuaidiNum,
					})
					fetchOrderShipppings()
				} catch (error) { }
			},
		})
	}

	return (
		<>
			<Card
				title="物流"
				loading={loading}
				bodyStyle={{ padding: 0 }}
				extra={<Button onClick={createNewShipping}>+ 新建物流</Button>}
			>
				<Table
					size="small"
					dataSource={shopShippings}
					columns={shippingCols}
					rowKey={(r) => _.get(r, "kuaidiNum")}
				/>
			</Card>

			<Modal
				title="新建物流"
				visible={shippingFormVisible}
				onCancel={() => {
					setShippingFormVisible(false)
				}}
				onOk={() => {
					form.submit()
				}}
			>
				<Form form={form} onFinish={submit}>
					<Form.Item name="kuaidiNum" label="运单号">
						<Input placeholder="自定义运单号，可不填"></Input>
					</Form.Item>
				</Form>
			</Modal>
		</>
	)
}

const OrderDetail = () => {
	const params = useParams()
	const history = useHistory()
	const [loading, setLoading] = useState(false)
	const [order, setOrder] = useState()
	const pendingPayment = _.get(order, "status.id") === "pending_payment"
	const orderIsCancelled = _.get(order, "status.code", 0) < 20
	const orderIsRefunded =
		_.get(order, "status.code", 0) >= 400 &&
		_.get(order, "status.code", 0) < 500
	const orderIsNoGood = orderIsCancelled || orderIsRefunded
	const shopItem = _.get(order, "shopItem")
	const buyer = _.get(order, "buyer")
	const payment = _.get(order, "payment")
	const orderIsPaid = !!_.get(payment, "paymentResult")
	const [refundReasonForm] = Form.useForm()

	const [showPaymentCompleteModal, setShowPaymentCompleteModal] =
		useState(false)
	const [paymentCompleteForm] = Form.useForm()

	let emptyAddressObject = {
		name: "",
		mobile: "",
		province: "",
		city: "",
		area: "",
		address: "",
	}
	let buyerShippingAddress: ShippingAddress =
		_.get(order, "buyerShippingAddress") || {}

	Object.keys(emptyAddressObject).forEach((key) => {
		if (!buyerShippingAddress[key]) {
			buyerShippingAddress[key] = ""
		}
	})

	const num_id: number = parseInt(_.get(params, "id"))
	const [loadingKey, setLoadingKey]: [string, Function] = useState()

	useEffect(() => {
		fetchData(num_id)
		navbar.setTitle(`订单详情 #${num_id}`)
	}, [num_id])

	const fetchData = async (num_id: number) => {
		if (num_id) {
			try {
				setLoading(true)
				const res = await API.Shop.Order.fetch_order({ num_id: num_id })
				setOrder(res)
			} catch (err) { }
			setLoading(false)
		}
	}

	const editFinalPrice = (text: string) => {
		if (text && parseFloat(text).toString() == text) {
			let originalFinalPrice = _.get(order, "finalPrice")
			let newFinalPrice: number = parseFloat(text)
			let newFinalPriceInCents = Math.round(newFinalPrice * 100)
			Modal.confirm({
				title: "修改成交价格",
				content: `¥${originalFinalPrice} --> ¥${newFinalPrice}? 客户将按照这个价格支付`,
				okText: "确定改价",
				onOk: async () => {
					setLoadingKey("finalPrice")
					try {
						const res = await API.Shop.Order.edit_order_final_price({
							num_id: num_id,
							final_price_in_cents: newFinalPriceInCents,
						})
						setOrder(res)
					} catch (err) { }
					Modal.success({
						title: "成功",
						content: `成交价格已从 ¥${originalFinalPrice} 修改为 ¥${newFinalPrice}`,
					})
					setLoadingKey(null)
				},
			})
		} else {
			message.error("价格只能输入数字")
		}
	}

	const changeAddress = async (val, key) => {
		console.log(key, val)

		let address_params = {}
		address_params[key] = val
		let params = {
			num_id: num_id,
			address_params: address_params,
		}
		setLoadingKey(key)
		try {
			const res = await API.Shop.Order.edit_order_buyer_shipping_address(params)
			setOrder(res)
		} catch (err) { }
		setLoadingKey(null)
	}

	const refundOrder = () => {
		Modal.confirm({
			title: "退款",
			content: (
				<div>
					<Form
						form={refundReasonForm}
						onFinish={async (values) => {
							if (!values.reason || !values.reason.trim()) {
								return Modal.error({
									title: "请输入退款理由",
								})
							}

							try {
								const res = await API.Breal.Shop.refundOrder({
									shop_order_id: _.get(order, "objectId"),
									refund_reason: values.reason,
								})

								Modal.success({
									title: "操作成功",
									content:
										"已向微信商户平台申请退款，请刷新本页面查看是否退款成功到账。一般情况下微信会在几十秒 ~ 几分钟内完成原路退款。",
									okText: "刷新",
									onOk: () => {
										fetchData(num_id)
									},
								})
							} catch (err) {
								Modal.error({
									title: "退款出错",
									content: _.get(err, "message", JSON.stringify(err)),
								})
							}
						}}
					>
						<Form.Item name="reason" label="退款理由">
							<Input placeholder="输入退款理由" />
						</Form.Item>
					</Form>
				</div>
			),
			onOk: async () => {
				refundReasonForm.submit()
			},
		})
	}

	const cancelOrder = () => {
		console.log('_.get(order, "objectId")', _.get(order, "objectId"))
		Modal.confirm({
			title: "取消订单",
			content: `确定取消买家 ${_.get(buyer, "nickName")} 的订单?`,
			onOk: async () => {
				try {
					const res = await API.Shop.Order.cancel_order({
						order_id: _.get(order, "objectId"),
					})
					notification.success({
						message: "操作成功",
						description: `订单 (#${_.get(order, "id")}) 已取消`,
						placement: "bottomRight",
					})
					fetchData(num_id)
				} catch (err) { }
			},
		})
	}

	const basicInfo = [
		{
			title: `订单 #${_.get(order, "id")}`,
			description: (
				<div className="two-col-list-item">
					<div>
						<div className="title">状态</div>
						<Tooltip title={_.get(order, "status.id")}>
							<Tag color={orderIsNoGood ? "red" : "blue"}>
								{_.get(order, "status.name")}
							</Tag>
						</Tooltip>
						<span>{_.get(order, "status.description")}</span>
					</div>

					<div>
						<div className="title">买家</div>
						<div
							className="flex-center-align"
							style={{ justifyContent: "flex-start" }}
						>
							<Avatar
								size="small"
								src={_.get(order, "buyer.avatar")}
								style={{ marginRight: "0.5rem" }}
							/>
							<Link to={`/user/${_.get(order, "buyer.objectId")}`}>
								{_.get(order, "buyer.nickName")}
							</Link>
						</div>
					</div>
				</div>
			),
		},
		{
			description: (
				<div className="two-col-list-item">
					<div>
						<div className="title">下单时间</div>
						<div>
							{moment
								.unix(_.get(order, "createdAt"))
								.format("YYYY/MM/DD HH:mm")}
						</div>
					</div>
					<div>
						<div className="title">下单时间</div>
						<div>
							{moment
								.unix(_.get(order, "createdAt"))
								.format("YYYY/MM/DD HH:mm")}
						</div>
					</div>
				</div>
			),
		},
		{
			description: (
				<div className="two-col-list-item">
					<div>
						<div className="title">数据库号</div>
						<div>{_.get(order, "objectId")}</div>
					</div>
				</div>
			),
		},
		{
			title: `商品 #${_.get(order, "shopItem.id")}`,
			description: (
				<>
					<div className="flex-grid">
						<div>
							<div className="title">品牌</div>
							<div>
								{_.get(shopItem, "brand.name")}
								{_.get(shopItem, "brand.cnName")}
							</div>
						</div>

						<div>
							<div className="title">品类</div>
							<div>{_.get(shopItem, "category.name")}</div>
						</div>
						<div>
							<div className="title">成色</div>
							<div>
								{_.get(shopItem, "condition")
									? conditionParse(_.get(shopItem, "condition"))
									: "无"}
							</div>
						</div>
					</div>
				</>
			),
		},
		{
			title: "商品名称",
			description: (
				<a
					onClick={() => {
						history.push(`/shop/item/${_.get(shopItem, "id")}`)
					}}
				>
					{_.get(shopItem, "name")}
				</a>
			),
		},
		{
			description: (
				<div className="two-col-list-item">
					<div>
						<div className="title">商品价格:</div>
						<div>¥{_.get(order, "price") || "无"}</div>
					</div>
					<div>
						<div className="title">成交价格:</div>
						<Paragraph
							editable={pendingPayment ? { onChange: editFinalPrice } : false}
							className="price-paragraph"
						>
							<Spin
								spinning={loadingKey == "finalPrice"}
								indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />}
							/>{" "}
							{_.get(order, "finalPrice") || "无"}
						</Paragraph>
					</div>
				</div>
			),
		},
		{
			title: "收货地址",
			description: (
				<div>
					<div>
						{Object.entries(buyerShippingAddress).map(([k, v], i) => {
							return (
								<div className="address-item" key={k}>
									<div className="label">{addressKeyDict[k]}</div>
									<Paragraph
										key={k}
										editable={
											orderIsNoGood
												? false
												: { onChange: (val) => changeAddress(val, k) }
										}
										className="paragraph"
									>
										<Spin
											spinning={loadingKey == k}
											indicator={
												<LoadingOutlined style={{ fontSize: 16 }} spin />
											}
										/>{" "}
										{v || "无"}
									</Paragraph>
								</div>
							)
						})}
					</div>
				</div>
			),
		},
		// {
		// 	title: "更新时间",
		// 	description: moment
		// 		.unix(_.get(order, "updatedAt"))
		// 		.format("YYYY.MM.DD HH.mm"),
		// },
	]

	const setManualPaid = async (values) => {
		let params = {
			platform: "weapp",
			shop_item_id: _.get(order, "shopItem.objectId"),
			order_id: _.get(order, "objectId"),
			payment_result: values,
		}

		try {
			console.log(params)
			const res = await API.Shop.Order.set_payment(params)
			window.location.reload()
		} catch (error) {
			Modal.error({
				title: "出错了",
				content: JSON.stringify(error),
			})
		}
	}

	return (
		<>
			<div className="order-detail-grid">
				<div className="left-col">
					<Card
						title="订单基本信息"
						loading={loading}
						extra={
							pendingPayment ? (
								<Button onClick={() => setShowPaymentCompleteModal(true)}>
									手动支付
								</Button>
							) : (
								""
							)
						}
						cover={
							<Tooltip title="查看商品">
								<div
									style={{ cursor: "pointer" }}
									onClick={() => {
										history.push(`/shop/item/${_.get(shopItem, "id")}`)
									}}
								>
									<AspectImage
										heightRatio={(9 / 16) * 100 + "%"}
										src={qiniuImageView(_.get(shopItem, "cover_url"), 800)}
									/>
								</div>
							</Tooltip>
						}
					>
						<List
							className="list"
							dataSource={basicInfo}
							renderItem={(item, i) => {
								return (
									<List.Item>
										<List.Item.Meta
											className="list-item"
											title={item.title}
											description={item.description}
										/>
									</List.Item>
								)
							}}
						></List>
					</Card>
				</div>

				<div className="right-col">
					{_.get(order, "payment.paymentResult") ? (
						<Card title="支付结果">
							<Paragraph>
								<Tag>已支付</Tag>{" "}
								{_.get(payment, "paid") === true ? "是" : "否"}
							</Paragraph>

							{!!_.get(payment, "paymentResult.paymentType") ? (
								<>
									{Object.keys(_.get(payment, "paymentResult", {})).map((k) => {
										return (

											<Form.Item label={k} style={{ marginBottom: 0 }}>
												<Input size="small" className="info" value={_.get(payment, `paymentResult.${k}`)} disabled />
											</Form.Item>

										)
									})}
								</>
							) : (
								<div>非微信支付</div>
							)}
						</Card>
					) : (
						""
					)}

					{_.get(order, "payment.refundResult") ? (
						<Card title="退款结果">
							<Paragraph>
								<Tag>已退款</Tag>{" "}
								{_.get(payment, "refunded") === true ? "是" : "否"}
							</Paragraph>

							{_.get(payment, "platform") == "weapp" ? (
								<>
									{Object.keys(_.get(payment, "refundResult", {})).map((k) => {
										return (
											<Form.Item label={k} style={{ marginBottom: 0 }}>
												<Input size="small" className="info" value={_.get(payment, `refundResult.${k}`)} disabled />
											</Form.Item>
										)
									})}
								</>
							) : (
								<div>非微信支付</div>
							)}
						</Card>
					) : (
						""
					)}

					{order ? <OrderShippings orderId={_.get(order, "objectId")} /> : ""}

					<Card title="操作" className="actions-card">
						<div className="btn-grid">
							<Tooltip
								title={
									orderIsRefunded
										? "不能退款: 订单已退款"
										: !orderIsPaid
											? "不能退款: 未支付订单"
											: ""
								}
							>
								<Button
									danger
									onClick={refundOrder}
									disabled={orderIsNoGood || !orderIsPaid}
								>
									退款
								</Button>
							</Tooltip>

							<Tooltip title={orderIsNoGood ? "订单已经取消了" : ""}>
								<Button danger onClick={cancelOrder} disabled={orderIsNoGood}>
									取消订单
								</Button>
							</Tooltip>
						</div>
					</Card>
				</div>
			</div>

			<Modal
				title="手动支付"
				visible={showPaymentCompleteModal}
				onCancel={() => {
					setShowPaymentCompleteModal(false)
				}}
				onOk={() => {
					paymentCompleteForm.submit()
				}}
			>
				<Form
					form={paymentCompleteForm}
					initialValues={{
						platform: "weapp",
						payment_result_payment_channel: null,
						payment_result_transaction_id: null,
					}}
					onFinish={setManualPaid}
				>
					<Form.Item name="payment_channel" label="支付方式">
						<Select>
							<Select.Option value="alipay">支付宝</Select.Option>
							<Select.Option value="wxpay_to_personal">
								微信转账给小鉴货
							</Select.Option>
							<Select.Option value="wxpay_to_company">
								微信转账给公司
							</Select.Option>
						</Select>
					</Form.Item>

					<Form.Item name="transaction_id" label="转账明细号">
						<Input />
					</Form.Item>

					<Form.Item name="notes" label="备注">
						<Input />
					</Form.Item>
				</Form>
			</Modal>
		</>
	)
}

export default OrderDetail
