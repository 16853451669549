import React, { CSSProperties } from "react"
import "./IconFont.less"

interface IconFontProps {
	name: string
	size?: string | number
	color?: string
	style?: CSSProperties
	spin?: boolean
}

const IconFont = ({ name, size, color, style, spin }: IconFontProps) => {
	let iconName = name.startsWith("icon-") ? name : `icon-${name}`
	let spinClass = !!spin ? "spin" : ""

	return (
		<i
			className={`iconfont ${iconName} ${spinClass}`}
			style={{
				fontSize:
					typeof size == "number" ? `${size}px` : size ? size : "inherit",
				color: color ? color : "inherit",
				lineHeight: "1em",
				...style,
			}}
		/>
	)
}

export default IconFont
