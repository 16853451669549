import {
	Card,
	List,
	Button,
	Avatar,
	Modal,
	notification,
	Switch,
	message,
} from "antd"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { useParams, useLocation, Link, useHistory } from "react-router-dom"
import API from "../../api/api"
import { navbar, qiniuImageView, conditionParse } from "../../utils/helpers"
import "./ShopItemDetail.less"
import AspectImage from "../../components/AspectImage/AspectImage"
import moment from "moment"
import IconFont from "../../components/IconFont/IconFont"
import { useDispatch } from "react-redux"
import shopSlice from "../../redux/slices/shop/shop"
import ShopItemEditor from "../../components/ShopItemEditor/ShopItemEditor"
import "moment/locale/zh-cn"
import { isMobile } from "react-device-detect"
import { web_server } from "../../App"
moment.locale("zh-cn")

const ShopItemOrders = ({ object_id }: { object_id: string }) => {
	const [orders, setOrders] = useState([])

	useEffect(() => {
		if (object_id) {
			get_orders(object_id)
		}
	}, [object_id])

	const get_orders = async (object_id: string) => {
		if (object_id) {
			const res = await API.Shop.Order.get_orders({
				page: 0,
				limit: 1000,
				shop_item_id: object_id,
			})
			setOrders(res.orders)
		}
	}

	return (
		<Card title="商品订单" hidden={orders.length <= 0}>
			<List
				dataSource={orders}
				renderItem={(order, i) => (
					<List.Item>
						<List.Item.Meta
							avatar={<Avatar src={_.get(order, "buyer.avatar")} />}
							title={_.get(order, "buyer.nickName")}
							description={
								<div>
									<div>
										<Link to={`/shop/order/${_.get(order, "id")}`}>
											订单号: {_.get(order, "id")}
										</Link>
									</div>
									<div>成交价: ¥{_.get(order, "finalPrice")}</div>
									<div>状态: {_.get(order, "status.name")}</div>
									<div>
										下单时间: {moment.unix(_.get(order, "createdAt")).fromNow()}
									</div>
								</div>
							}
						></List.Item.Meta>
					</List.Item>
				)}
			></List>
		</Card>
	)
}

const ShopItemDetail = ({ id }: { id: number }) => {
	const dispatch = useDispatch()
	const location = useLocation()
	const params = useParams()
	const history = useHistory()
	const num_id: number = id || parseInt(_.get(params, "id"))
	const [loading, setLoading] = useState(false)
	const [publishing, setPublishing] = useState(false)
	const [shopItem, setShopItem] = useState()
	const [shopItemPhotos, setShopItemPhotos] = useState([])

	let currentShopItemLocation = [
		_.get(shopItem, "archived")
			? "商品存档"
			: _.get(shopItem, "published")
			? "商品橱窗"
			: "商品仓库",
	]

	const published = _.get(shopItem, "published")
	const archived = _.get(shopItem, "archived")

	const isRootRoute = location.pathname.startsWith("/shop/item/")
	const singleCol = !isRootRoute || isMobile

	useEffect(() => {
		fetchData(num_id)
		if (isRootRoute) {
			navbar.setTitle(`商品详情 #${num_id}`)
		}
	}, [num_id])

	useEffect(() => {
		if (shopItem && isRootRoute) {
			navbar.setRightItem(
				<>
					<Button
						style={{ marginRight: 8 }}
						onClick={() => {
							history.push(`/shop/order/create/${_.get(shopItem, "id")}`)
						}}
					>
						创建订单
					</Button>
					<Button
						onClick={() => {
							dispatch(shopSlice.actions.setEditorItem(shopItem))
						}}
					>
						编辑商品
					</Button>
				</>
			)
		}
		return () => {
			navbar.setRightItem(null)
		}
	}, [shopItem])

	useEffect(() => {
		return () => {
			navbar.reset()
		}
	}, [])

	const fetchData = async (num_id: number) => {
		if (num_id) {
			try {
				setLoading(true)
				const res = await API.Shop.Item.fetch_item({ num_id: num_id })
				getShopItemPhotos(_.get(res, "objectId"))
				setShopItem(res)
			} catch (err) {}
			setLoading(false)
		}
	}

	const getShopItemPhotos = async (shop_item_id: string) => {
		if (shop_item_id) {
			try {
				setLoading(true)
				const res = await API.Shop.Item.get_shop_item_photos({
					shop_item_id: shop_item_id,
				})
				setShopItemPhotos(res)
			} catch (err) {}
			setLoading(false)
		}
	}

	const archiveItem = () => {
		Modal.confirm({
			centered: true,
			title: "商品归档?",
			content: "商品归档后将不会被搜索得到，但是购买这个包包的客户仍然能看到。",
			okText: "确认归档",
			cancelText: "取消",
			onOk: async () => {
				try {
					const itemId = _.get(shopItem, "objectId")
					const res = await API.Shop.Item.archive_shop_item({
						object_id: itemId,
					})
					notification.success({
						message: "归档成功",
						description: _.get(shopItem, "name"),
						placement: "bottomRight",
					})
					fetchData(num_id)
				} catch (err) {}
			},
		})
	}

	const unarchiveItem = () => {
		Modal.confirm({
			centered: true,
			title: "提档?",
			content: "将商品从商品存档里提档到仓库",
			okText: "确认提档",
			cancelText: "取消",
			onOk: async () => {
				try {
					const itemId = _.get(shopItem, "objectId")
					const res = await API.Shop.Item.unarchive_shop_item({
						object_id: itemId,
					})
					notification.success({
						message: "提档成功",
						description: _.get(shopItem, "name"),
						placement: "bottomRight",
					})
					fetchData(num_id)
				} catch (err) {}
			},
		})
	}

	const deleteItem = () => {
		Modal.confirm({
			centered: true,
			title: "删除商品 (不可逆操作!)",
			content:
				"如非必要，请使用归档功能。删除将会彻底将此商品从数据库删除，购买此商品的客户也没法再看到。",
			okText: "确认删除",
			cancelText: "取消",
			onOk: async () => {
				try {
					const itemId = _.get(shopItem, "objectId")
					const res = await API.Shop.Item.delete_shop_item({
						object_id: itemId,
					})
					history.goBack()
					notification.success({
						message: "删除成功",
						description: _.get(shopItem, "name"),
						placement: "bottomRight",
					})
				} catch (err) {}
			},
		})
	}

	const renderShareCard = async () => {
		let shopItemId = _.get(shopItem, "objectId")
		let cardUrl = `${web_server}/shop/render_share_card/${shopItemId}`
		Modal.success({
			icon: null,
			width: 800,
			title: "分享卡片",
			content: <img src={cardUrl} style={{ maxWidth: "100%" }} />,
			maskClosable: true,
		})
	}

	const shopItemInfo = [
		{
			description: (
				<>
					<div className="flex-grid">
						<div>
							<div className="title">商品ID</div>
							<div>{num_id}</div>
						</div>

						<div>
							<div className="title">数据库ID</div>
							<div>{_.get(shopItem, "objectId")}</div>
						</div>
					</div>
				</>
			),
		},
		{
			description: (
				<>
					<div className="flex-grid">
						<div>
							<div className="title">品牌</div>
							<div>
								{_.get(shopItem, "brand.name")}
								{_.get(shopItem, "brand.cnName")}
							</div>
						</div>

						<div>
							<div className="title">品类</div>
							<div>{_.get(shopItem, "category.name")}</div>
						</div>
						<div>
							<div className="title">成色</div>
							<div>
								{_.get(shopItem, "condition")
									? conditionParse(_.get(shopItem, "condition"))
									: "无"}
							</div>
						</div>
					</div>
				</>
			),
		},
		{
			title: "商品名称",
			description: _.get(shopItem, "name"),
		},
		{
			title: "商品价格",
			description: `¥${_.get(shopItem, "price") || "无"}`,
		},

		{
			title: "入库时间",
			description: moment
				.unix(_.get(shopItem, "createdAt"))
				.format("YYYY.MM.DD HH.mm"),
		},
		// {
		// 	title: "更新时间",
		// 	description: moment
		// 		.unix(_.get(shopItem, "updatedAt"))
		// 		.format("YYYY.MM.DD HH.mm"),
		// },
	]

	return (
		<div
			className="shop-item-detail-grid"
			style={
				singleCol
					? {
							gridTemplateColumns: `1fr`,
					  }
					: {}
			}
		>
			<Card
				title={
					<div>
						{_.get(shopItem, "brand.name")} {_.get(shopItem, "name")}
					</div>
				}
				loading={loading}
				cover={
					<AspectImage
						heightRatio={(10 / 16) * 100 + "%"}
						src={qiniuImageView(_.get(shopItem, "cover_url"), 600)}
					/>
				}
				extra={<Button onClick={renderShareCard}>生成卡片</Button>}
			>
				<List
					className="list"
					dataSource={shopItemInfo}
					renderItem={(item, i) => {
						return (
							<List.Item>
								<List.Item.Meta
									className="list-item"
									title={item.title}
									description={item.description}
								/>
							</List.Item>
						)
					}}
				></List>
			</Card>

			<div className="right-grid">
				<Card
					key="publish"
					title={`操作: 商品现在在 [${currentShopItemLocation}] 里`}
					size="small"
				>
					<div className="action-btn-grid">
						{!_.get(shopItem, "archived") ? (
							<Button
								loading={publishing}
								size="large"
								className="publish-btn"
								type={published ? "default" : "primary"}
								icon={
									<IconFont
										name={!published ? "icon-store" : "icon-warehouse"}
										style={{ marginRight: 4 }}
									/>
								}
								onClick={async (e) => {
									try {
										setPublishing(true)
										var res = null
										if (!published) {
											res = await new Promise((resolve, reject) => {
												Modal.confirm({
													centered: true,
													title: "上架商品",
													content: "商品将移动到在橱窗中，前端用户可见",
													onOk: async () => {
														resolve(
															await API.Shop.Item.publish_shop_item({
																object_id: _.get(shopItem, "objectId"),
															})
														)
													},
													onCancel: async () => {
														reject()
													},
												})
											})
										} else {
											res = await new Promise((resolve, reject) => {
												Modal.confirm({
													centered: true,
													title: "下架商品",
													content:
														"下架商品将移回商品仓库，并取消所有该商品下的未支付订单",
													onOk: async () => {
														resolve(
															await API.Shop.Item.unpublish_shop_item({
																object_id: _.get(shopItem, "objectId"),
															})
														)
													},
													onCancel: async () => {
														reject()
													},
												})
											})
										}

										notification.success({
											message: `${res.published ? "上架成功" : "已下架"}`,
											description: `${_.get(shopItem, "name")}`,
											placement: "bottomRight",
										})
										fetchData(num_id)
									} catch (err) {
										console.log("err", err)
									}
									setPublishing(false)
								}}
							>
								{!published ? "上架到橱窗" : "下架到仓库"}
							</Button>
						) : (
							""
						)}
						<Button
							size="large"
							className="archive-btn"
							onClick={archived ? unarchiveItem : archiveItem}
							icon={
								<IconFont name="icon-packaging" style={{ marginRight: 4 }} />
							}
						>
							{_.get(shopItem, "published") ? "下架并" : ""}
							{archived ? "提档" : "归档"}
						</Button>
						<Button
							size="large"
							type="ghost"
							danger
							className="delete-btn"
							onClick={deleteItem}
							icon={<IconFont name="icon-shanchu" style={{ marginRight: 4 }} />}
						>
							{_.get(shopItem, "published") ? "下架并" : ""}删除
						</Button>
						<div className="flex-center-align non-btn-wrapper">
							<Switch
								checkedChildren={
									<div>
										<IconFont name="icon-success" /> 已售出
									</div>
								}
								unCheckedChildren={
									<div>
										<IconFont name="icon-product" /> 未售出
									</div>
								}
								onChange={async (checked) => {
									if (checked) {
										Modal.confirm({
											title: "将商品状态改成[已售出]? ",
											content:
												'将会同时取消所有该商品下的订单，并对订单的客户显示 "取消原因: 商品已经售出"',
											onOk: async () => {
												const res = await API.Shop.Item.set_shop_item_sold({
													object_id: _.get(shopItem, "objectId"),
													is_sold: checked,
												})
												setShopItem(res)
											},
										})
									} else {
										const res = await API.Shop.Item.set_shop_item_sold({
											object_id: _.get(shopItem, "objectId"),
											is_sold: checked,
										})
										setShopItem(res)
									}
								}}
								disabled={_.get(shopItem, "order")}
								checked={_.get(shopItem, "sold") == true}
								loading={loading}
								size="default"
							/>{" "}
						</div>
					</div>
				</Card>

				<Card title="商品图片">
					<div className="image-grid">
						{shopItemPhotos.map((p, i) => (
							<a
								href={_.get(p, "file.url")}
								target="_blank"
								key={_.get(p, "objectId")}
							>
								<AspectImage src={qiniuImageView(_.get(p, "file.url"), 300)} />
							</a>
						))}
					</div>
				</Card>

				{_.get(shopItem, "appraisal") ? (
					<Card title="鉴定证书">
						<img src={_.get(shopItem, "appraisal.certificate.attributes.url")} width="100%"/>
					</Card>
				) : (
					""
				)}

				{isRootRoute ? (
					<ShopItemOrders object_id={_.get(shopItem, "objectId")} />
				) : (
					""
				)}
			</div>

			<ShopItemEditor
				onClose={() => {
					fetchData(num_id)
				}}
			/>
		</div>
	)
}

export default ShopItemDetail
