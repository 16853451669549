import _ from "lodash"
import API from "../../../api/api"
import store from "../../store"
import shopSlice from "./shop"
import { ProductFilters } from "../../../components/FilterTools/FilterTools"
const { dispatch, getState } = store

const shopAction = {
	fetchShopItems: async ({
		pageSize = 24,
		archived = false,
		filters,
	}: {
		pageSize?: number
		archived?: boolean
		filters?: ProductFilters
	} = {}) => {
		let currentPage = _.get(getState(), "shop.shopItemsDisplayData.currentPage", 0)
		try {
			const res = await API.Shop.Item.get_shop_items({
				page: currentPage,
				limit: pageSize,
				filters: filters,
				archived: archived,
			})

			dispatch(
				shopSlice.actions.setDisplayData({
					currentPage: currentPage,
					totalCount: _.get(res, "count", 0),
					pageItems: _.get(res, "items", []),
				})
			)

			return _.get(res, "items") || []
		} catch (err) {
			console.error(err)
		}
	},
}

export default shopAction
