import React, { useState } from "react"
import "./FilterTools.less"
import { Form, Input, Select, Button } from "antd"
import { useSelector } from "react-redux"
import _ from "lodash"
import IconFont from "../IconFont/IconFont"

export interface ProductFilters {
	cat_id: string
	brand_id: string
	[key: string]: any
}

interface FilterToolsProps {
	onFilter: (filters: ProductFilters) => void
}

const FilterTools = ({ onFilter }: FilterToolsProps) => {
	const initialValues: ProductFilters = {
		cat_id: null,
		brand_id: null,
		search_keyword: "",
	}

	const [form] = Form.useForm()
	const commonData = useSelector((state) => _.get(state, "global.commonData"))

	const categories = [
		{ name: "全部品类", objectId: null },
		...(_.get(commonData, "shop_categories") || []),
	]
	const brands = [
		{ name: "全部品牌", objectId: null },
		...(_.get(commonData, "brands") || []),
	]

	const onFinish = (values: ProductFilters) => {
		onFilter(values)
	}

	const reset = () => {
		form.resetFields()
		form.submit()
	}

	const [formChanged, setFormChnaged] = useState(false)

	return (
		<Form
			form={form}
			onFinish={onFinish}
			initialValues={initialValues}
			layout="inline"
			className="filter-form"
			onValuesChange={(values: ProductFilters) => {
				if (!Object.keys(values).includes("search_keyword")) {
					onFinish(values)
				}
			}}
			onFieldsChange={() => {
				let changed = !Object.keys(initialValues).every((k) => {
					let fieldValue = form.getFieldValue(k)
					return fieldValue == initialValues[k]
				})
				setFormChnaged(changed)
			}}
		>
			<Form.Item name="brand_id">
				<Select
					placeholder="选择品牌"
					filterOption={false}
					dropdownMatchSelectWidth={300}
				>
					{brands.map((brand) => (
						<Select.Option value={brand.objectId} key={brand.objectId}>
							{brand.name} {brand.cnName}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item name="cat_id">
				<Select
					placeholder="选择分类"
					filterOption={false}
					dropdownMatchSelectWidth={300}
				>
					{categories.map((cat) => (
						<Select.Option value={cat.objectId} key={cat.objectId}>
							{cat.name} {cat.code}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item name="search_keyword">
				<Input.Search
					onSearch={form.submit}
					type="search"
					placeholder="搜索名称/商品ID/数据库ID"
				/>
			</Form.Item>

			{/* <Form.Item>
				<Button htmlType="submit">
					<IconFont name="icon-search" size="1.2em" />
				</Button>
			</Form.Item> */}
			{formChanged ? (
				<Form.Item>
					<Button onClick={reset}>重置</Button>
				</Form.Item>
			) : (
				""
			)}
		</Form>
	)
}

export default FilterTools
