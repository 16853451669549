import AV from "leancloud-storage"
import {
	IMClient,
	Message,
	TextMessage,
	Event,
	Conversation,
	PresistentConversation,
	Realtime,
} from "leancloud-realtime"
// @ts-expect-error
import popding from "../assets/sound/pop_ding.mp3"
import { useEffect, useRef } from "react"
import { useState } from "react"
import _ from "lodash"

const engine_server = "https://cloudapi.ilux.ai"
export const realtime = new Realtime({
	appId: "WIT5O5deQWcL8B1jo4yidtVy-gzGzoHsz",
	appKey: "U9WPlrBSvq0mephlHPkK1Oku",
	server: engine_server,
})

export const useChat: () => [
	IMClient,
	number,
	PresistentConversation[],
	boolean
] = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [client, setClient]: [IMClient, any] = useState()
	const [conversations, _setConversations]: [PresistentConversation[], any] =
		useState([])
	const conversationsRef = useRef(conversations)
	const setConversations = (data) => {
		conversationsRef.current = data
		_setConversations(data)
	}

	const [unreadMessageCount, setUnreadMessageCount] = useState(0)

	useEffect(() => {
		calculateUnreadMessageCount()
	}, [conversations])

	const calculateUnreadMessageCount = () => {
		let count = _.sum(conversations.map((c) => c.unreadMessagesCount))
		setUnreadMessageCount(count)
	}

	useEffect(() => {
		createClient().then((client) => {
			console.log("client created")
			fetchConversations(client)
		})
	}, [])

	const playDing = () => {
		try {
			const audio = new Audio(popding)
			audio.play()
		} catch (err) {}
	}

	const createClient = async () => {
		try {
			console.log("creating IM client for service_agent")
			setIsLoading(true)
			let c = await realtime.createIMClient("service_agent")
			c.on(
				Event.MESSAGE,
				async (payload: Message, conversation: Conversation) => {
					playDing()
					await fetchConvUser(conversation)
					let convs = [
						conversation,
						...conversationsRef.current.filter((c) => c.id != conversation.id),
					]
					setConversations(convs)
				}
			)

			c.on(Event.UNREAD_MESSAGES_COUNT_UPDATE, (conversation) => {
				calculateUnreadMessageCount()
			})
			setClient(c)
			return c
		} catch (err) {
			console.error(err)
		}
		setIsLoading(false)
	}

	const fetchConvUser = async (conv: Conversation) => {
		try {
			let customer_ids: string[] = _.uniq(
				conv.members.filter(
					(member) =>
						!member.startsWith("service_agent") && !member.startsWith("guest_")
				)
			)

			if (customer_ids.length) {
				let c = await AV.User.createWithoutData(
					"_User",
					customer_ids[0]
				).fetch()
				conv.set("user", c)
			}
		} catch (err) {
			console.error(err)
		}
	}

	const fetchConversations = async (client) => {
		if (!client) return
		setIsLoading(true)
		var q = client.getQuery().exists("m").exists("attr").limit(1000)
		q.withLastMessagesRefreshed(true)

		try {
			let convs = await q.find()
			

			let customer_ids: string[] = _.uniq(
				convs
					.map((c) => c.members)
					.flat()
					.filter(
						(member) =>
							!member.startsWith("service_agent") &&
							!member.startsWith("guest_")
					)
			)

			let customers = customer_ids.map((cid) =>
				AV.User.createWithoutData("_User", cid)
			)

			try {
				await AV.User.fetchAll(customers)
			} catch(err) {
				console.error("FETCH CUSTOMERS ERROR", err)
			}

			convs.forEach((conv, idx) =>
				conv.set(
					"user",
					customers.find(
						(customer: AV.User) =>
							customer.id == conv.creator || conv.members.includes(customer.id)
					)
				)
			)

			setConversations(convs)
		} catch (err) {
			console.error(err)
		}
		setIsLoading(false)
	}

	return [client, unreadMessageCount, conversationsRef.current, isLoading]
}
