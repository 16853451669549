import React, { useState, useEffect } from "react"
import "./BrandEditor.less"
import {
	Modal,
	Form,
	Input,
	Slider,
	Switch,
	Upload,
	Select,
	Button,
	message,
} from "antd"
import _ from "lodash"
import AspectImage from "../../components/AspectImage/AspectImage"
import AV from "leancloud-storage/live-query"
import API from "../../api/api"

const BrandEditor = ({
	visible,
	brand = {},
	onCancel,
	success,
}: {
	visible: boolean
	brand?: object
	onCancel: () => void
	success: () => void
}) => {
	const [saving, setSaving] = useState(false)
	const [form] = Form.useForm()

	const [editingBrand, setEditingBrand] = useState({})

	useEffect(() => {
		if (brand) {
			setEditingBrand({ ...brand })
		}
	}, [brand, visible])

	useEffect(() => {
		form.setFieldsValue(editingBrand)
	}, [editingBrand])

	const save = async (params) => {
		try {
			setSaving(true)
			console.log("params", params)
			const res = await API.Admin.Brand.save_brand(params)
			console.log("res", res)
			message.success("保存成功")
		} catch (error) {}
		setSaving(false)
		if (success) {
			success()
		}
		onCancel()
	}

	const deleteBrand = async () => {
		Modal.confirm({
			centered: true,
			title: "删除品牌",
			content: "只允许删除没用过的品牌，一般情况下不要用",
			onOk: async () => {
				let brandId = _.get(brand, "objectId")
				try {
					if (brandId) {
						await API.Admin.Brand.delete_brand({ objectId: brandId })
						message.success("删除成功")
					}
				} catch (error) {}
				if (success) {
					success()
				}
				onCancel()
			},
			okText: "确定删除品牌",
			cancelText: "取消",
		})
	}

	return (
		<Modal
			destroyOnClose={true}
			title="编辑品牌"
			confirmLoading={saving}
			visible={!!brand || visible == true}
			onCancel={onCancel}
			onOk={form.submit}
			okText="保存"
			cancelText="取消"
		>
			<Form initialValues={editingBrand} form={form} onFinish={save}>
				<Form.Item name="objectId" label="ID" required>
					<Input placeholder="ID" disabled />
				</Form.Item>
				<Form.Item label="logo">
					{_.get(editingBrand, "logo") ? (
						<Form.Item>
							<img
								width={200}
								height={200}
								src={_.get(editingBrand, "logo.url")}
								style={{ objectFit: "contain" }}
							/>
						</Form.Item>
					) : (
						""
					)}

					<Form.Item name={["logo", "objectId"]}>
						<Input placeholder="Logo File id" disabled></Input>
					</Form.Item>

					<Upload
						name="file"
						multiple={false}
						accept="image/*"
						listType="text"
						customRequest={async (options) => {
							console.log('"upload now"', "upload now")
							let brandName = form.getFieldValue("name")
							const { onSuccess, onError, file, action, onProgress } = options
							let avfile = new AV.File(`${brandName}_brand_logo.png`, file)
							try {
								const res: AV.File = await avfile.save()
								let newValues = {
									...editingBrand,
									logo: {
										objectId: res.id,
										name: res.name(),
										url: res.url(),
									},
								}
								console.log("newValues", newValues)
								setEditingBrand(newValues)
								onSuccess(null, file)
							} catch (err) {
								console.log("err", err)
							}
						}}
					>
						<Button>上传</Button>
					</Upload>
				</Form.Item>

				<Form.Item name="name" label="英文名称" required>
					<Input placeholder="输入英文名称" />
				</Form.Item>
				<Form.Item name="cnName" label="中文名称">
					<Input placeholder="输入中文名称" />
				</Form.Item>
				<Form.Item name="abv" label="简称">
					<Input placeholder="输入简称，例如: LV, BV 等" />
				</Form.Item>
				<Form.Item name="alias" label="别名">
					<Select mode="tags" placeholder="如香奶奶, 驴牌, 可输入多个"></Select>
				</Form.Item>
				<Form.Item name="authTypes" label="可鉴定类别">
					<Select mode="tags"></Select>
				</Form.Item>
				<Form.Item name="hotness" label="权重">
					<Slider min={0} max={10} />
				</Form.Item>
				<Form.Item name="isHidden" label="是否隐藏" valuePropName="checked">
					<Switch checkedChildren="是" unCheckedChildren="否" />
				</Form.Item>
				<Form.Item label="删除品牌">
					<Button danger type="primary" onClick={deleteBrand}>
						删除品牌(慎用!!)
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	)
}

export default BrandEditor
