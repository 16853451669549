import React from 'react'
import ShopItems from './ShopItems'


const ShopItemsArchived = () => {
    return (
        <ShopItems archived={true}/>
    )
}

export default ShopItemsArchived
