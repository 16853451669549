import { Button, Card, Form, Input, Layout, Modal } from "antd"
import AV from "leancloud-storage/live-query"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import globalSlice from "../../redux/slices/global"
import { AVDeepJsonify } from "../../utils/helpers"
import IconFont from "../IconFont/IconFont"
import "./LoginBox.less"

const LoginBox = () => {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const submit = async (values) => {
		setLoading(true)
		await new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve()
			}, 1000)
		})

		const { username, password } = values
		try {
			const res = await AV.User.logIn(username, password)
			if (res.get("ALevel")) {
				dispatch(globalSlice.actions.setUser(AVDeepJsonify(res)))
			} else {
				throw Error("")
			}
		} catch (err) {
			Modal.error({
				title: "Something's wrong",
				content: "Try again or just leave",
				centered: true,
			})
		}
		setLoading(false)
	}

	return (
		<Layout className="login-page">
			<Card
				className="login-card"
				title={loading ? "Loggin you in..." : "Everything is under control"}
			>
				<Form layout="vertical" onFinish={submit}>
					{!loading ? (
						<>
							<Form.Item name="username">
								<Input
									placeholder="username"
									size="large"
									prefix={<IconFont name="icon-usercenter" size="1.4em" />}
								/>
							</Form.Item>
							<Form.Item name="password">
								<Input
									placeholder="password"
									size="large"
									type="password"
									prefix={<IconFont name="icon-unlock" size="1.4em" />}
								/>
							</Form.Item>
						</>
					) : (
						""
					)}

					<Form.Item style={{ marginBottom: 0 }}>
						<Button
							type="primary"
							style={{ float: "right" }}
							size="large"
							htmlType="submit"
							className={`login-btn ${loading ? "loading" : ""}`}
							disabled={loading}
						>
							{loading ? (
								<IconFont name="icon-loading" spin={true} size="1.4em" />
							) : (
								<>
									LOGIN <IconFont name="icon-arrow-right" />
								</>
							)}
						</Button>
					</Form.Item>
				</Form>
			</Card>
		</Layout>
	)
}

export default LoginBox
