import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"

interface ShopSliceInitialState {
	editorItem: object
	shopItemsDisplayData: {
		currentPage: number
		totalCount: number
		pageItems: object[]
	}
}

const initialState: ShopSliceInitialState = {
	editorItem: null,
	shopItemsDisplayData: {
		currentPage: 0,
		totalCount: 0,
		pageItems: [],
	},
}

const shopSlice = createSlice({
	name: "shopItems",
	initialState: initialState,
	reducers: {
		setEditorItem: (state, action) => ({
			...state,
			editorItem: action.payload,
		}),
		setDisplayData: (state, action) => ({
			...state,
			shopItemsDisplayData: action.payload,
		}),
		setCurrentPage: (state, action) => ({
			...state,
			shopItemsDisplayData: {
				...state.shopItemsDisplayData,
				currentPage: action.payload,
			},
		}),
		setTotalCount: (state, action) => ({
			...state,
			shopItemsDisplayData: {
				...state.shopItemsDisplayData,
				totalCount: action.payload,
			},
		}),
		setPageItems: (state, action) => ({
			...state,
			shopItemsDisplayData: {
				...state.shopItemsDisplayData,
				pageItems: action.payload,
			},
		}),
		replaceItemInPage: (state, action) => ({
			...state,
			shopItemsDisplayData: {
				...state.shopItemsDisplayData,
				pageItems: state.shopItemsDisplayData.pageItems.map((item) => {
					if (_.get(item, "objectId") == _.get(action.payload, "objectId")) {
						return action.payload
					} else {
						return item
					}
				}),
			},
		}),
		removeItemInPage: (state, action) => ({
			...state,
			shopItemsDisplayData: {
				...state.shopItemsDisplayData,
				pageItems: state.shopItemsDisplayData.pageItems.filter((item) => {
					return _.get(item, "objectId") != action.payload
				}),
			},
		}),
	},
})

export default shopSlice
