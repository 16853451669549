import OrderExpandedRow from "./pages/Appraise/OrderExpandedRow"
import AppraiseOrders from "./pages/Appraise/Orders"
import RevnueStats from "./pages/Appraise/RevnueStats"
import BrandsManagement from "./pages/BrandsManagement/BrandsManagement"
import SellItemDetail from "./pages/Breal/SellItemDetail"
import SellItems from "./pages/Breal/SellItems"
import BrealAppSettings from "./pages/Breal/Settings"
import Wallet from "./pages/Breal/Wallet"
import Home from "./pages/Home/Home"
import HtmlContents from "./pages/HtmlContents/HtmlContents"
import ChatList from "./pages/ServiceChat/ChatList"
import ShopCreateOrder from "./pages/Shop/CreateOrder"
import Money from "./pages/Shop/Money"
import OperationTools from "./pages/Shop/OperationTools"
import ShopOrderDetail from "./pages/Shop/OrderDetail"
import ShopOrders from "./pages/Shop/Orders"
import ShopSettings from "./pages/Shop/Settings"
import ShopItemDetail from "./pages/Shop/ShopItemDetail"
import ShopItems from "./pages/Shop/ShopItems"
import ShopItemsArchived from "./pages/Shop/ShopItemsArchived"
import ShopShowcase from "./pages/Shop/ShopShowcase"
import User from "./pages/User/User"
import Users from "./pages/User/Users"
import WebVisit from "./pages/WebVisit/WebVisit"
import { Logout } from "./utils/helpers"

export interface CpRoute {
	key: string
	label: string
	icon?: string
	path?: string
	component?: (props?: any) => JSX.Element
	func?: Function
	alevel?: number
}

const topRoutes: CpRoute[] = [
	{
		key: "home",
		label: "系统状态",
		icon: "icon-home",
		path: "/",
		component: Home,
	},
	// {
	// 	key: "leancloud",
	// 	label: "Leancloud运维",
	// 	icon: "icon-code",
	// 	path: "/leancloud",
	// 	component: Leancloud,
	// 	alevel: 10,
	// },
	{
		key: "brands",
		label: "品牌库",
		icon: "icon-discount",
		path: "/brands",
		component: BrandsManagement,
		alevel: 10,
	},
	{
		key: "users",
		label: "用户管理",
		icon: "icon-usercenter",
		path: "/users",
		component: Users,
	},
	{
		key: "htmlContents",
		label: "HTML内容",
		icon: "icon-editor",
		path: "/htmlcontents",
		component: HtmlContents,
		alevel: 10,
	},
	{
		key: "money",
		label: "Money",
		icon: "icon-discounts",
		path: "/money",
		component: Money,
		alevel: 10,
	},
	{
		key: "WebVisit",
		label: "WebVisit",
		icon: "icon-data",
		path: "/webvisit",
		component: WebVisit,
		alevel: 10,
	},
]

const shopRoutes: CpRoute[] = [
	{
		key: "shopOrders",
		label: "订单管理",
		icon: "icon-invoice",
		path: "/shop/orders",
		component: ShopOrders,
	},
	{
		key: "shopShowcase",
		label: "橱窗管理",
		icon: "icon-store",
		path: "/shop/showcase",
		component: ShopShowcase,
	},
	{
		key: "shopItems",
		label: "商品仓库",
		icon: "icon-warehouse",
		path: "/shop/items",
		component: ShopItems,
	},
	{
		key: "shopItemsArchived",
		label: "商品存档",
		icon: "icon-packaging",
		path: "/shop/archive",
		component: ShopItemsArchived,
	},
	{
		key: "shopOperationTools",
		label: "运营工具",
		icon: "icon-code",
		path: "/shop/tools",
		component: OperationTools,
	},
	{
		key: "shopCategories",
		label: "闪拍设置",
		icon: "icon-category",
		path: "/shop/settings",
		component: ShopSettings,
		alevel: 10,
	},
]

const appraiseRoutes: CpRoute[] = [
	{
		key: "appraiseOrders",
		label: "鉴定订单",
		icon: "icon-assessed-badge",
		path: "/appraise/orders",
		component: AppraiseOrders,
	},
	{
		key: "appraiseStats",
		label: "鉴定统计",
		icon: "icon-data",
		path: "/appraise/stats",
		component: RevnueStats,
		alevel: 10,
	},
]

const miscRoutes: CpRoute[] = [
	{
		key: "logout",
		label: "退出登录",
		icon: "icon-assessed-badge",
		path: "/logout",
		func: () => {
			Logout()
		},
	},
]

const subRoutes: CpRoute[] = [
	{
		key: "createOrder",
		label: "创建订单",
		path: "/shop/order/create/:id",
		component: ShopCreateOrder,
	},
	{
		key: "shopOrderDetail",
		label: "订单详情",
		path: "/shop/order/:id",
		component: ShopOrderDetail,
	},
	{
		key: "shopItem",
		label: "订单详情",
		path: "/shop/item/:id",
		component: ShopItemDetail,
	},
	{
		key: "user",
		label: "用户",
		path: "/user/:id/:tab?",
		component: User,
	},
	{
		key: "appraiseOrderDetail",
		label: "鉴定订单详情",
		icon: "icon-data",
		path: "/appraise/order/:id",
		component: OrderExpandedRow,
		alevel: 10,
	},
	{
		key: "breal/sell-item-detail",
		label: "较真回收-详情",
		icon: "icon-data",
		path: "/breal/sell-item-detail/:objectId",
		component: SellItemDetail,
	},
]

const bRealRoutes: CpRoute[] = [
	{
		key: "breal/customer-service",
		label: "客服",
		icon: "icon-kefu",
		path: "/breal/customer-service",
		component: ChatList,
	},
	{
		key: "breal/wallet",
		label: "较真钱包",
		icon: "icon-data",
		path: "/breal/wallet",
		component: Wallet,
		alevel: 10,
	},
	{
		key: "breal/sell-items",
		label: "较真回收",
		icon: "icon-data",
		path: "/breal/sell-items",
		component: SellItems,
	},
	{
		key: "breal/settings",
		label: "APP设置",
		icon: "icon-category",
		path: "/breal/settings",
		component: BrealAppSettings,
	},
]

const groupedRoutes = [
	{ groupName: "iLux", routes: topRoutes },
	{ groupName: "较真App", routes: bRealRoutes },
	{ groupName: "闪拍", routes: shopRoutes },
	{ groupName: "鉴定神器", routes: appraiseRoutes },
	{ groupName: "其他", routes: miscRoutes },
]
const combinedRoutes = [
	...groupedRoutes.map((g) => g.routes).flat(),
	...subRoutes,
]

export { groupedRoutes }
export default combinedRoutes
