import { Button, Form, Input, message, Modal, Table, Tooltip } from "antd"
import { ColumnsType } from "antd/lib/table"
import _, { initial } from "lodash"
import moment from "moment"
import React, { CSSProperties, useEffect, useState } from "react"
import API from "../../api/api"
import MaterialIcon from "../../components/MaterialIcon/MaterialIcon"
import { qiniuImageView } from "../../utils/helpers"
import { useHistory } from "react-router-dom"

const completeStyle: CSSProperties = {
	background: "#4caf4f",
	height: "100%",
	textAlign: "center",
	borderRadius: 4,
	padding: 4,
	color: "white",
}

const SellItems = () => {
	const history = useHistory()
	const [total, setTotal] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const [currentPage, setCurrentPage] = useState(1)
	const [sellItems, setSellItems] = useState([])
	const [isPendingOffer, setIsPendingOffer] = useState(false)

	const [initialOfferForm] = Form.useForm()
	const [finalOfferForm] = Form.useForm()

	useEffect(() => {
		getSellItems()
	}, [pageSize, currentPage])

	const getSellItems = async (params?: { refresh?: boolean }) => {
		const res = await API.Breal.SellItem.getSellItems({
			page: _.get(params, "refresh") ? 0 : currentPage - 1,
			limit: pageSize,
			pending_offer: isPendingOffer,
		})
		console.log(`res.results`, res.results)
		setSellItems(res.results)
		setTotal(res.count)
	}

	const columns: ColumnsType<any> = [
		{
			title: "封面",
			dataIndex: "cover",
			render: (c) => (
				<img
					style={{
						width: 64,
						height: 64,
						objectFit: "cover",
						borderRadius: "8px",
					}}
					src={qiniuImageView(_.get(c, "url", ""), 300, 300)}
				/>
			),
		},
		{
			title: "ID",
			dataIndex: "id",
			render: (id) => `SSN-${id}`,
		},
		{
			title: "日期",
			dataIndex: "created_at",
			render: (c) => {
				return <div>{moment(c * 1000).format("YYYY-MM-DD")}</div>
			},
		},
		{
			title: "基本资料",
			render: (sellItem) => {
				let name = _.get(sellItem, "name", "")
				let brand = _.get(sellItem, "brand")
				return <div>
					<div>品牌: {_.get(brand, "name")} {_.get(brand, "cn_name")}</div>
					<div>名称: {name}</div>
				</div>
			},
		},
		{
			title: "用户",
			dataIndex: "seller",
			render: (u) => {
				let nickName = _.get(u, "nick_name")
				let phoneNumber = _.get(u, "mobile_phone_number")
				let username = _.get(u, "username")
				if (nickName) {
					return nickName
				} else if (phoneNumber) {
					return "手机用户:" + phoneNumber
				} else if (username) {
					return atob(username)
				} else {
					return "无名用户"
				}
			},
		},
		{
			title: "1.初步报价",
			align: "center",
			dataIndex: "initial_offer",
			render: (initialOffer) => {
				if (!!_.get(initialOffer, "price")) {
					const accepted = _.get(initialOffer, "accepted")
					return (
						<div style={completeStyle}>
							<MaterialIcon
								color="white"
								name={accepted ? "check_circle" : "hourglass_empty"}
								prefix={`¥${_.get(initialOffer, "price") / 100}`}
							/>
						</div>
					)
				} else if (_.get(initialOffer, "price") == 0) {
					return <div style={{...completeStyle, background: "#c73535"}}>拒绝回收</div>
				} else {
					return <div>-</div>
				}
			},
		},
		{
			title: "2.邮寄到店",
			dataIndex: "to_shipping",
			align: "center",
			render: (toShipping) => {
				let kuaidi_num = _.get(toShipping, "kuaidi_num")
				let lastStatus = _.get(toShipping, "tracking_result.lastResult.data.0.status")
				if (kuaidi_num || lastStatus) {
					return (
						<div style={completeStyle}>
							<Tooltip
								title={<div style={{}}>{`快递单号: ${kuaidi_num}`}</div>}
							>
								<div>{lastStatus || "已寄出"}</div>
							</Tooltip>
						</div>
					)
				} else {
					return "-"
				}
			},
		},
		{
			title: "3.最终报价",
			dataIndex: "final_offer",
			render: (finalOffer) => {
				if (!!_.get(finalOffer, "price")) {
					const accepted = _.get(finalOffer, "accepted")
					return (
						<div style={completeStyle}>
							<MaterialIcon
								color="white"
								name={accepted ? "check_circle" : "hourglass_empty"}
								prefix={`¥${_.get(finalOffer, "price") / 100}`}
							/>
						</div>
					)
				} else if (_.get(finalOffer, "price") == 0) {
					return <div style={{...completeStyle, background: "#c73535"}}>拒绝回收</div>
				} else {
					return <div>-</div>
				}
			},
		},
		{
			title: "4.放款",
			dataIndex: "payment",
			render: (payment) => {
				let paymentExists = !!_.get(payment, "object_id")
				if (paymentExists)
					return (
						<div style={completeStyle}>
							<MaterialIcon
								color="white"
								name={"check_circle"}
								prefix={`¥${_.get(payment, "amount", 0) / 100}`}
							/>
						</div>
					)
				else return "-"
			},
		},
	]

	return (
		<div>
			<Table
				rowClassName="sell-item-table-row"
				dataSource={sellItems}
				rowKey={(item) => item.object_id}
				onRow={(item, idx) => {
					return {
						onMouseUp: (e) => {
							history.push("/breal/sell-item-detail/" + item.object_id)
						},
					}
				}}
				columns={columns}
				pagination={{
					pageSize: pageSize,
					current: currentPage,
					total: total,
					showSizeChanger: true,
					pageSizeOptions: ["10", "50", "100"],
					onShowSizeChange: (current, size) => {
						setCurrentPage(current)
						setPageSize(size)
					},
					onChange: (page, size) => {
						setCurrentPage(page)
					},
				}}
			/>
		</div>
	)
}

export default SellItems
