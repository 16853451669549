import React, { useEffect, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import _ from "lodash"
import API from "../../api/api"
import { navbar } from "../../utils/helpers"
import {
	Typography,
	Card,
	Input,
	Spin,
	Tooltip,
	message,
	Button,
	Avatar,
	Modal,
} from "antd"
import "./CreateOrder.less"
import AspectImage from "../../components/AspectImage/AspectImage"

interface CreateOrderParams {
	user_id: string
	shop_item_id: string
	final_price: number
}

const CreateOrder = () => {
	const params = useParams()
	const history = useHistory()
	const [loading, setLoading] = useState(false)
	const shop_item_num_id: number = _.get(params, "id")
	const [shopItem, setShopItem] = useState()

	const [finalPrice, setFinalPrice]: [string, Function] = useState()

	const [isSearchingUsers, setIsSearchingUsers] = useState(false)
	const [searchedUsers, setSearchedUsers] = useState([])
	const [selectedUser, setSelectedUser] = useState()

	useEffect(() => {
		navbar.setTitle("创建订单")

		if (shop_item_num_id) {
			fetchShopItem(shop_item_num_id)
		}
	}, [shop_item_num_id])

	useEffect(() => {
		if (shopItem) {
			setFinalPrice(_.get(shopItem, "price"))
		}
	}, [shopItem])

	const fetchShopItem = async (id: number) => {
		setLoading(true)
		try {
			const res = await API.Shop.Item.fetch_item({
				num_id: id,
			})
			setShopItem(res)
		} catch (err) {}
		setLoading(false)
	}

	const searchUser = async (keyword) => {
		setSearchedUsers([])
		try {
			setIsSearchingUsers(true)
			const res = await API.Admin.UserManagement.search_users({
				keyword: keyword,
			})
			setSearchedUsers(res)
		} catch (err) {}
		setIsSearchingUsers(false)
	}

	const createOrder = async () => {
		if (!finalPrice) {
			return message.error("请输入成交价格")
		}

		if (!selectedUser) {
			return message.error("请选择用户")
		}

		try {
			let req_params: CreateOrderParams = {
				shop_item_id: _.get(shopItem, "objectId"),
				user_id: _.get(selectedUser, "objectId"),
				final_price: parseFloat(finalPrice),
			}
			const order = await API.Shop.Order.place_order(req_params)
			let order_id = _.get(order, "id")
			history.replace("/shop/order/" + order_id)
		} catch (err) {}
	}

	return (
		<Card className="create-order-card" loading={loading}>
			<div className="shop-item-info">
				<AspectImage
					heightRatio={(3 / 4) * 100 + "%"}
					src={_.get(shopItem, "cover_url")}
				/>
				<div className="text-info">
					<div>商品: #{_.get(shopItem, "id")}</div>
					<div>数据库ID: {_.get(shopItem, "objectId")}</div>
					<span className="brand-name">
						{_.get(shopItem, "brand.name")}
						{_.get(shopItem, "brand.cnName")}
					</span>
					<span className="item-name">{_.get(shopItem, "name")}</span>
					<div>定价: ¥{_.get(shopItem, "price")}</div>
				</div>
			</div>

			<Input
				className="final-price-box"
				size="large"
				placeholder="自定义成交价"
				prefix="成交价 ¥"
				value={finalPrice}
				allowClear={true}
				onChange={(e) => {
					let value = e.currentTarget.value
					let isValid = value.match(/^((\.\d+)|(\d+(\.|\.\d{1,2})?))$/)
					if (!value) {
						setFinalPrice("")
					} else if (isValid) {
						setFinalPrice(value)
					}
				}}
			/>

			{!selectedUser ? (
				<>
					<Input.Search
						size="large"
						className="user-search-box"
						loading={isSearchingUsers}
						enterButton
						placeholder="搜索用户"
						onSearch={searchUser}
					/>
					<div
						className={
							isSearchingUsers ? "flex-center-align" : "user-search-results"
						}
					>
						<Spin spinning={isSearchingUsers}></Spin>
						{searchedUsers.map((user) => {
							let nickName = _.get(user, "nickName")
							let user_object_id: string = _.get(user, "objectId") || ""
							let user_object_id_short = user_object_id.substring(
								user_object_id.length - 6,
								user_object_id.length
							)
							return (
								<Tooltip
									key={user_object_id}
									title={
										<div>
											<div>{nickName}</div>
											<div>{user_object_id}</div>
										</div>
									}
								>
									<Card
										onClick={() => setSelectedUser(user)}
										size="small"
										hoverable
										cover={
											<AspectImage
												heightRatio={(3 / 4) * 100 + "%"}
												src={_.get(user, "avatar")}
											/>
										}
									>
										<div>{nickName}</div>
										<div>{_.get(user, "mobilePhoneNumber")}</div>

										<Typography.Paragraph
											className="object-id"
											copyable={{ text: user_object_id }}
										>
											ID尾号: {user_object_id_short}
										</Typography.Paragraph>
									</Card>
								</Tooltip>
							)
						})}
					</div>
				</>
			) : (
				<div className="selected-user">
					<Avatar src={_.get(selectedUser, "avatar")} />{" "}
					<span className="nickName">{_.get(selectedUser, "nickName")}</span>
					<a
						onClick={() => {
							setSelectedUser(null)
						}}
						style={{ marginLeft: 8 }}
					>
						更改
					</a>
				</div>
			)}

			<div>
				<Button
					onClick={() => {
						history.goBack()
					}}
					size="large"
					style={{ marginRight: 8 }}
				>
					取消
				</Button>

				<Button
					size="large"
					onClick={() => {
						Modal.confirm({
							title: "创建订单",
							content: `即将为买家 ${_.get(
								selectedUser,
								"nickName"
							)} 创建成绩价为 ${finalPrice} 的订单`,
							okText: "确定创建",
							onOk: createOrder,
						})
					}}
					type="primary"
					disabled={!selectedUser || !finalPrice}
				>
					创建订单
				</Button>
			</div>
		</Card>
	)
}

export default CreateOrder
