import React, { useState, useEffect } from "react"
import "./Users.less"
import { Form, Table, Tooltip, Input, Select, Button } from "antd"
import _ from "lodash"
import API from "../../api/api"
import moment from "moment"
import { qiniuImageView } from "../../utils/helpers"
import IconFont from "../../components/IconFont/IconFont"
import { useHistory } from "react-router-dom"

const Users = () => {
	const history = useHistory()
	const [loading, setLoading] = useState(false)
	const [total, setTotal] = useState(0)
	const [users, setUsers] = useState()
	const [reqParams, setReqParams]: [
		{
			page: number
			limit?: number
			filters: { nickName?: string; objectId?: string }
		},
		Function
	] = useState({
		page: 0,
		limit: 20,
		filters: null,
	})

	const [form] = Form.useForm()

	const fetchUsers = async () => {
		setLoading(true)
		try {
			const res = await API.Breal.User.get_users(reqParams)
			setUsers(res.users)
			setTotal(res.total)
		} catch (err) {}
		setLoading(false)
	}

	useEffect(() => {
		console.log("effect")
		fetchUsers()
	}, [reqParams])

	const columns = [
		{
			title: "昵称",
			render: (u) => {
				return (
					<>
						<div className="user-basic-info">
							<Tooltip
								title={
									<img
										src={qiniuImageView(_.get(u, "avatar.url"), 320)}
										className="avatar-large"
									/>
								}
							>
								<img
									src={qiniuImageView(_.get(u, "avatar.url"), 32)}
									className="avatar"
								/>
							</Tooltip>
							<div className="nick-name-wrapper">
								<div className="nick-name">
									{_.get(u, "nick_name")}{" "}
									{_.get(u, "sex") ? (
										<IconFont
											name={_.get(u, "sex") == 1 ? "icon-nan" : "icon-nv"}
											color={_.get(u, "sex") == 1 ? "#9fd2d6" : "#edd2cc"}
										/>
									) : (
										""
									)}
								</div>
								<div className="from">
									{_.get(u, "province")} {_.get(u, "city")}
								</div>
							</div>
						</div>
					</>
				)
			},
		},
		{
			title: "ID",
			render: (user) => {
				return <div>{_.get(user, "object_id")}</div>
			},
		},
		{
			title: "注册日期",
			dataIndex: "createdAt",
			render: (createdAt) => moment(createdAt).format("YYYY.MM.DD HH:mm:ss"),
		},
	]

	return (
		<div>
			<Form
				form={form}
				initialValues={{
					searchType: "nickName",
					searchString: "",
				}}
				onFinish={(values) => {
					let key = values.searchType
					let val = values.searchString
					if (key && val) {
						let f = {}
						f[key] = val
						setReqParams({ ...reqParams, page: 0, filters: f })
					} else {
						setReqParams({ ...reqParams, page: 0, filters: null })
					}
				}}
			>
				<Input.Group compact>
					<Form.Item name="searchType">
						<Select>
							<Select.Option value="nickName">昵称</Select.Option>
							<Select.Option value="objectId">ID</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item name="searchString">
						<Input.Search
							style={{ width: 280 }}
							onSearch={() => {
								form.submit()
							}}
							placeholder="输入关键词"
						/>
					</Form.Item>
					<Button
						onClick={() => {
							form.resetFields()
							form.submit()
						}}
					>
						重置
					</Button>
				</Input.Group>
			</Form>
			<Table
				loading={loading}
				size="small"
				dataSource={users}
				columns={columns}
				pagination={{
					total: total,
					pageSize: reqParams.limit,
					current: reqParams.page + 1,
					onChange: (page, pageSize) => {
						setReqParams({ ...reqParams, page: page - 1 })
					},
					onShowSizeChange: (page, pageSize) => {
						setReqParams({ ...reqParams, limit: pageSize })
					},
				}}
				onRow={(r, i) => ({
					onClick: () => {
						history.push("/user/" + _.get(r, "object_id"))
					},
				})}
				rowKey={(r) => r.object_id}
			/>
		</div>
	)
}

export default Users
