import { Button, Empty, notification } from "antd"
import _ from "lodash"
import array_move from "lodash-move"
import React, { useEffect, useState, useRef } from "react"
import { isMobile } from "react-device-detect"
import { useDrop } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import {
	DndProvider,
	MouseTransition,
	TouchTransition,
} from "react-dnd-multi-backend"
import { usePreview } from "react-dnd-preview"
import { TouchBackend } from "react-dnd-touch-backend"
import { useDispatch } from "react-redux"
import API from "../../api/api"
import AspectImage from "../../components/AspectImage/AspectImage"
import FilterTools, {
	ProductFilters,
} from "../../components/FilterTools/FilterTools"
import ShopItemCard, {
	DraggableShopItemCard,
} from "../../components/ShopItemCard/ShopItemCard"
import ShopItemEditor from "../../components/ShopItemEditor/ShopItemEditor"
import shopSlice from "../../redux/slices/shop/shop"
import { navbar, qiniuImageView } from "../../utils/helpers"
import "./ShopShowcase.less"

const HTML5toTouch = {
	backends: [
		{
			backend: HTML5Backend,
			transition: MouseTransition,
		},
		{
			backend: (manager, globalContext, config) =>
				TouchBackend(manager, globalContext, { delayTouchStart: 350 }),
			preview: false,
			transition: TouchTransition,
		},
	],
}

const ShopShowcase = () => {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const [editorVisible, setEditorVisible] = useState(false)
	const [showcaseItems, setShowcaseItems] = useState([])
	const [isReordering, setIsReordering] = useState(false)
	const [filters, setFilters]: [ProductFilters, Function] = useState()

	const setEditorItem = (item) => {
		dispatch(shopSlice.actions.setEditorItem(item))
	}

	useEffect(() => {
		fetchData()
		return () => {
			setShowcaseItems(null)
			navbar.reset()
		}
	}, [])

	useEffect(() => {
		console.log(
			showcaseItems.map((item, i) => ({
				object_id: _.get(item, "showcase_id"),
				sort: i,
			}))
		)
	}, [showcaseItems])

	useEffect(() => {
		if (isReordering) {
			navbar.setRightItem(
				<>
					<Button
						onClick={() => {
							setIsReordering(false)
							fetchData()
						}}
						style={{ marginRight: "0.5rem" }}
					>
						取消
					</Button>
					<Button onClick={saveNewOrder} type="primary">
						保存排序
					</Button>
				</>
			)
			navbar.setTitle("长按0.3秒后拖动排序")
		} else {
			navbar.setRightItem(
				<Button
					onClick={() => {
						setIsReordering(true)
					}}
				>
					排序
				</Button>
			)
			navbar.setTitle("橱窗")
		}
	}, [isReordering, showcaseItems])

	useEffect(() => {
		console.log("fetch")
		fetchData()
	}, [filters])

	const filter = (filters: ProductFilters) => {
		console.log("filterParams", filters)
		setFilters(filters)
	}

	function reorder(sourceIndex: number, targetIndex: number) {
		setShowcaseItems(array_move(showcaseItems, sourceIndex, targetIndex))
	}

	const fetchData = async () => {
		setLoading(true)
		try {
			const res = await API.Shop.Item.get_shop_showcase_items({
				page: 0,
				limit: 1000,
				filters: filters,
			})
			setShowcaseItems(res.items)
		} catch (err) {}
		setLoading(false)
	}

	const saveNewOrder = async () => {
		setLoading(true)
		try {
			let newOrders = showcaseItems.map((item, i) => ({
				object_id: _.get(item, "showcase_id"),
				sort: i,
			}))
			console.log("newOrders", newOrders)
			const res = await API.Shop.Item.reorder_showcase_items(newOrders)
			setIsReordering(false)
			notification.success({ message: "排序成功", placement: "bottomRight" })
		} catch (err) {}
		setLoading(false)
	}

	const DropCell = ({ index, children }) => {
		const [{ isOver }, drop] = useDrop({
			accept: "ShopItemCard",
			drop: (item, monitor) => {
				const sourceIndex = monitor.getItem().index
				const targetIndex = index
				reorder(sourceIndex, targetIndex)
			},
			collect: (monitor) => ({
				isOver: !!monitor.isOver(),
			}),
		})

		return (
			<div
				ref={drop}
				style={{
					width: "100%",
					height: "100%",
					border: isOver ? "2px dashed #CCCCCC" : "0px solid white",
					transform: `scale(${isOver ? 0.98 : 1})`,
					boxSizing: "border-box",
					transition: "transform 0.15s ease-in-out",
					borderRadius: "8px",
				}}
			>
				{children}
			</div>
		)
	}

	const CustomPreview = () => {
		const { display, itemType, item, style } = usePreview()
		if (!display) {
			return null
		}
		return (
			<div
				className="drag-preview"
				style={{ ...style, width: item.width * 0.9, height: item.height * 0.9 }}
			>
				<div style={{ position: "relative", width: "100%" }}>
					<AspectImage
						heightRatio={(3 / 4) * 100 + "%"}
						src={
							_.get(item.shopItem, "cover_url")
								? qiniuImageView(_.get(item.shopItem, "cover_url"), 300)
								: null
						}
					/>
				</div>
				<div className="item-name">{item.name}</div>
			</div>
		)
	}

	return (
		<>
			<div className="tool-bar" hidden={isReordering}>
				<FilterTools onFilter={filter} />
			</div>

			{loading ? (
				<div className="item-grid">
					{new Array(24).fill("").map((empty, i) => (
						<ShopItemCard key={i} index={i} isSkeleton={true} />
					))}
				</div>
			) : showcaseItems.length > 0 ? (
				<>
					{isReordering ? (
						<DndProvider
							options={{ ...HTML5toTouch, options: { delayTouchStart: 2000 } }}
						>
							<div className="item-grid">
								{showcaseItems.map((item, i) => {
									return (
										<DropCell index={i} key={_.get(item, "objectId")}>
											<DraggableShopItemCard
												index={i}
												item={item}
												showActionBtns={!isReordering}
											/>
										</DropCell>
									)
								})}
							</div>
							{isMobile ? <CustomPreview /> : ""}
						</DndProvider>
					) : (
						<>
							<div className="item-grid">
								{showcaseItems.map((item, i) => (
									<ShopItemCard
										index={i}
										item={item}
										key={_.get(item, "objectId")}
									/>
								))}
							</div>
							<ShopItemEditor
								visible={editorVisible}
								onClose={() => {
									setEditorVisible(false)
									fetchData()
								}}
							/>
						</>
					)}
				</>
			) : (
				<Empty className="empty" />
			)}
		</>
	)
}

export default ShopShowcase
